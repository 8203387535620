import { Machine, MachineModel } from '../domain/models';

export const BASE_API_URL =
  process.env.API_URL || 'https://api.smartepi.com.br';

export const EndPoints = {
  Approval: (approvalId?: string): string =>
    approvalId ? `withdrawals/approval/${approvalId}` : `withdrawals/approval`,
  Approvals: 'withdrawals/approval',
  CostCenter: (costCenterId?: string): string =>
    costCenterId ? `cost-center/${costCenterId}` : `cost-center`,
  CostCenters: `cost-centers`,
  Customer: (customerId?: string): string =>
    customerId ? `customer/${customerId}` : `customer`,
  Customers: 'customers',
  Group: (groupId?: string): string => (groupId ? `group/${groupId}` : `group`),
  Groups: 'groups',
  License: (licenseId: string): string => `license/${licenseId}`,
  Licenses: `licenses`,
  Login: 'login',
  Machine: (machineId: string): string => `machine/${machineId}`,
  Machines: `machines`,
  MachineWarehouse: (warehouseId: string): string =>
    `warehouse/machine/${warehouseId}`,
  Product: (productId?: string): string =>
    productId ? `product/${productId}` : `product`,
  ProductFile: (productId: string): string => `product/${productId}/file`,
  Products: `products`,
  Reports: 'withdrawals',
  ReportsExport: 'withdrawals/export',
  Role: (roleId?: string): string => (roleId ? `role/${roleId}` : `role`),
  Roles: 'roles',
  Sector: (sectorId?: string): string =>
    sectorId ? `sector/${sectorId}` : `sector`,
  Sectors: `sectors`,
  Sheet: (sheetId?: string): string => (sheetId ? `sheet/${sheetId}` : `sheet`),
  SheetExport: (userId: string): string => `user/${userId}/sheet`,
  User: (userId?: string): string => (userId ? `user/${userId}` : `user`),
  Users: `users`,
  CustomerImage: `me/customer/image`,
  MeCustomer: `me/customer`,
  RefreshToken: 'refreshToken',
  Trigger: (triggerId?: string): string =>
    triggerId ? `trigger/${triggerId}` : `trigger`,
  Triggers: 'triggers',
  TriggerEvent: (triggerId: string): string => `trigger-event/${triggerId}`,
  TriggerEvents: `trigger-events`,
};

export const FilePaths = {
  Customer: (customerId: string): string => `${BASE_API_URL}/${customerId}`,
  ProductImage: (productId: string): string =>
    `${BASE_API_URL}/assets/product/${productId}`,
  ProductVideo: (productId: string): string =>
    `${BASE_API_URL}/assets/video/${productId}`,
  ProductManual: (productId: string): string =>
    `${BASE_API_URL}/assets/manual/${productId}`,
  Report: (reportPath: string): string =>
    `${BASE_API_URL}/assets/report/${reportPath}`,
  Sheet: (sheetPath: string): string =>
    `${BASE_API_URL}/assets/sheet/${sheetPath}`,
  Software: (softwarePath: string): string =>
    `${BASE_API_URL}/assets/software/${softwarePath}`,
};

export const RouterPaths = {
  Approval: '/approval',
  CostCenter: (costCenterId = ''): string => `/cost-centers/${costCenterId}`,
  CostCenters: '/cost-centers',
  Customer: (customerId = ''): string => `/customers/${customerId}`,
  Customers: '/customers',
  Dashboard: '/dashboard',
  Employee: (userId = ''): string => `/employees/${userId}`,
  Employees: '/employees',
  Group: (groupId = ''): string => `/groups/${groupId}`,
  Groups: '/groups',
  Licenses: '/licenses',
  Login: '/login',
  Machines: '/machines',
  Product: (productId = ''): string => `/products/${productId}`,
  Products: '/products',
  Reports: '/reports',
  Role: (roleId = ''): string => `/roles/${roleId}`,
  Roles: '/roles',
  Sector: (sectorId = ''): string => `/sectors/${sectorId}`,
  Sectors: '/sectors',
  Sheet: '/sheet',
  Trigger: (triggerId = ''): string => `/triggers/${triggerId}`,
  Triggers: '/triggers',
};

export const MachineModels: MachineModel[] = [
  {
    model: Machine.Model.VISION_V3_MACHINE_WITHOUT_ELEVATOR_,
    name: 'Vision V3 - Sem Elevador',
  },
  { model: Machine.Model.VISION_ESPLUS_MACHINE, name: 'Vision Esplus' },
];
