import { Input, Tab, Table, TableColumn } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { RestList } from '../../../../hooks/use-rest-list';
import { User } from '../../../../domain/models';

interface EmployeeTabProps {
  title: string;
  restList: RestList<User.Data>;
}

const Strings = {
  Buttons: {
    AddEmployee: 'Adicionar Funcionário',
  },
  Inputs: {
    Search: 'Buscar funcionário...',
  },
  Table: {
    ColumnTitles: {
      Employee: 'Funcionário',
      Identification: 'ID',
    },
  },
};

export function EmployeeTab({
  title,
  restList: { loading, list, nextBookmark, search },
}: EmployeeTabProps): JSX.Element {
  const tableScrollHandler = (
    event: React.UIEvent<HTMLTableSectionElement>
  ): void => {
    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight - 30
    ) {
      nextBookmark();
    }
  };

  return (
    <Tab title={title}>
      <nav>
        <Input
          containerType="outline"
          iconRight="magnify"
          placeholder={Strings.Inputs.Search}
          containerProps={{ className: '__search-bar' }}
          invert
          onBlur={(event) => search(event.target.value)}
          onKeyPress={(event) =>
            event.key === 'Enter' && search(event.currentTarget.value)
          }
        />
      </nav>
      <Table
        data={list}
        loading={loading}
        config={{ onScroll: tableScrollHandler }}
      >
        <TableColumn
          name={Strings.Table.ColumnTitles.Identification}
          key="identification"
          minwidth={window.innerWidth > 600 ? '100px' : '75px'}
        >
          {(item: User.Data): JSX.Element => <div>{item.identification}</div>}
        </TableColumn>
        <TableColumn
          name={Strings.Table.ColumnTitles.Employee}
          key="name"
          flex={1}
          minwidth="220px"
        >
          {(user: User.Data) => <div>{user.name}</div>}
        </TableColumn>
      </Table>
    </Tab>
  );
}
