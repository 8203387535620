import { Trigger } from '@keymax-dev/smartepi-sdk';

export type TriggerStatus = {
  name: string;
  status: Trigger.Status;
  color: string;
};

export const TriggerStatuss: TriggerStatus[] = [
  { name: 'Ativo', status: Trigger.Status.ACTIVE, color: '#00FF00' },
  { name: 'Inativo', status: Trigger.Status.INACTIVE, color: '#FF0000' },
];

export const findTriggerStatus = (status: Trigger.Status): TriggerStatus => {
  const triggerStatus = TriggerStatuss.find(
    (trigger) => trigger.status === status
  );

  if (triggerStatus) {
    return triggerStatus;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { name: 'Desconhecido', status: null as any, color: '#000000' };
};
