import { useState } from 'react';

export interface SelectableList<T> {
  getItems: () => T[];
  keys: Set<string>;
  clear: () => void;
  toggle: (item: T) => void;
  add: (item: T) => void;
  remove: (item: T) => void;
  length: number;
}

function useSelectableList<T>(key: keyof T): SelectableList<T> {
  const [map, setMap] = useState<{ [key: string]: T }>({});
  const [keys, setKeys] = useState(new Set<string>());
  const [length, setLength] = useState<number>(0);

  const add = (item: T): void => {
    const { [`${item[key]}`]: removed, ...newMap } = map;
    setMap(newMap);
    setKeys(new Set(Object.keys(newMap)));
  };

  const remove = (item: T): void => {
    const newMap = { ...map, [`${item[key]}`]: item };
    setMap(newMap);
    setKeys(new Set(Object.keys(newMap)));
  };

  const toggle = (item: T): void => {
    if (keys.has(`${item[key]}`)) {
      add(item);
    } else {
      remove(item);
    }
  };

  const getItems = (): T[] => {
    return Object.values(map);
  };

  const clear = (): void => {
    setMap({});
    setKeys(new Set());
    setLength(0);
  };

  return {
    getItems,
    toggle,
    add,
    remove,
    keys,
    clear,
    length,
  };
}

export default useSelectableList;
