import {
  Input,
  ScrollableContainer,
  Spinners,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import PageActions from '../../components/page-actions';
import PageTitle from '../../components/page-title';
import useRest from '../../hooks/use-rest';
import { License } from '../../domain/models';
import { mergeArrayById } from '../../utils';
import { EndPoints } from '../../utils/constants';
import DownloadSoftwareModal from './download-software';
import LicenseCard from './license-card';
import LicenseDetailsModal from './license-details';
import { LicensePageElement, LicensesMessageContainerElement } from './style';

const { useGet } = useRest();

const Strings = {
  Buttons: {
    DownloadSoftware: 'Download do Software',
  },
  Title: 'Licenças',
  Inputs: {
    Search: 'Buscar licença...',
  },
  Messages: {
    GetDataError: 'Não foi possível carregar a lista de licenças.',
    LoadingData: 'Carregando licenças...',
    EmptyData: 'Nenhuma licença para ser exibida.',
  },
};

export default function LicensePage(): JSX.Element {
  const [getData, get] = useGet({ loading: true });
  const [searchingValue, setSearchingValue] = useState<string>('');
  const [licensesList, setLicensesList] = useState<License.Data[]>([]);
  const errorToast = useToast(<span />, { color: 'danger' });
  const detailsModal = useModal(<LicenseDetailsModal />, {
    rootElement: '#license-page',
    containerProps: { style: { padding: 0 } },
  });
  const downloadModal = useModal(<DownloadSoftwareModal />);

  const licenseClickHandler = (event: unknown, license: License.Data): void => {
    detailsModal.injectProps({ license });
    detailsModal.onClose((reason) => {
      if (reason === 'deleted') {
        get(EndPoints.Licenses);
      }
      setLicensesList(() => [...licensesList]);
    });
    detailsModal.open();
  };
  const scrollHandler = (
    event: React.UIEvent<HTMLTableSectionElement>
  ): void => {
    if (
      getData.loading ||
      !getData.payload?.data.docs ||
      !getData.payload?.data.docs.length
    )
      return;
    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight - 50
    ) {
      get(EndPoints.Licenses, {
        bookmark: getData.payload?.data.bookmark,
      });
    }
  };

  const searchHandler = (value: string): void => {
    if (value !== searchingValue) {
      setLicensesList([]);
      if (value !== '') {
        get(EndPoints.Licenses, {
          search: 'name',
          searchValue: value,
        });
      } else {
        get(EndPoints.Licenses);
      }
      setSearchingValue(value);
    }
  };

  const pageActions = [
    {
      text: Strings.Buttons.DownloadSoftware,
      icon: 'packageVariant',
      onClick: () => downloadModal.open(),
    },
  ];

  useEffect(() => {
    if (getData.payload) {
      setLicensesList(mergeArrayById(licensesList, getData.payload.data.docs));
    } else if (getData.error) {
      console.error(getData.error);
      errorToast.setContent(Strings.Messages.GetDataError);
      setTimeout(() => errorToast.open());
    }
  }, [getData]);

  useEffect(() => {
    get(EndPoints.Licenses);
  }, []);

  return (
    <LicensePageElement id="license-page">
      <PageTitle>{Strings.Title}</PageTitle>
      {/* <PageActions actions={pageActions} /> */}
      <div className="ui-grid-horizontal __search-container">
        <Input
          containerType="outline"
          iconRight="magnify"
          placeholder={Strings.Inputs.Search}
          onBlur={(event) => searchHandler(event.target.value)}
          onKeyPress={(event) =>
            event.key === 'Enter'
              ? searchHandler((event.target as HTMLInputElement).value)
              : undefined
          }
        />
      </div>
      {licensesList.length > 0 && (
        <div className="__grid-card-container">
          <ScrollableContainer flexDirection="row" className="__grid-card">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                width: '100%',
                padding: '15px 5%',
              }}
              onScroll={scrollHandler}
            >
              {licensesList.map((license) => (
                <LicenseCard
                  key={license._id}
                  license={license}
                  onClick={(event) => licenseClickHandler(event, license)}
                />
              ))}
            </div>
          </ScrollableContainer>
        </div>
      )}
      {getData.loading && (
        <LicensesMessageContainerElement>
          <Spinners.circles width="200px" height="200px" />
          {Strings.Messages.LoadingData}
        </LicensesMessageContainerElement>
      )}
      {licensesList.length === 0 && !getData.loading && (
        <LicensesMessageContainerElement>
          {Strings.Messages.EmptyData}
        </LicensesMessageContainerElement>
      )}
    </LicensePageElement>
  );
}
