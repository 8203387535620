import {
  Animations,
  Button,
  ModalController,
  Spinners,
  useForm,
  useToast,
  Validators,
} from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../components/page-title';
import { Group, ErrorResponse } from '../../../domain/models';
import useRest from '../../../hooks/use-rest';
import { EndPoints } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import { AddGroupModalElement } from './style';

enum Content {
  Form,
  Loading,
}

const { usePost } = useRest();

const Strings = {
  Buttons: {
    Add: 'Adicionar',
  },
  Inputs: {
    GroupName: 'Nome do Grupo',
  },
  Messages: {
    RegisterLoading: 'Cadastrando grupo...',
    RegisterSuccess: 'Grupo adicionado com successo!',
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    InvalidForm: 'Os dados fornecidos são inválidos!',
  },
  Title: 'Adicionar Grupo',
};

interface AddGroupModalProps {
  controller?: ModalController;
}

export default function AddGroupModal({
  controller,
}: AddGroupModalProps): JSX.Element {
  const [data, post] = usePost({ loading: false });
  const errorToast = useToast(<span />, { color: 'danger' });
  const successToast = useToast(
    <span>{Strings.Messages.RegisterSuccess}</span>,
    { color: 'success' }
  );

  const [form, getErrors, getValues] = useForm(
    [
      {
        key: 'name',
        validators: [new Validators.Required()],
      },
    ],
    'addGroup'
  );

  const contentAnimation = useAnimation();
  const [content, _setContent] = useState<Content>(Content.Form);
  const setContent = (value: Content): void => {
    contentAnimation
      .start(Animations.FadeOut)
      .then(() => Promise.resolve(_setContent(value)))
      .then(() => contentAnimation.start(Animations.FadeIn));
  };

  const requestSuccessHandler = (data: Group.Data): void => {
    setTimeout(() => successToast.open());
    if (controller) controller.close(data);
  };

  const requestErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  const confirmHandler = (): void => {
    if (!getErrors()) {
      post(EndPoints.Group(), { ...getValues() });
    } else {
      errorToast.setContent(Strings.Messages.InvalidForm);
      errorToast.open();
    }
  };

  useEffect(() => {
    if (data.loading) {
      setContent(Content.Loading);
    } else {
      setContent(Content.Form);
      if (data.payload) requestSuccessHandler(data.payload.data);
      else if (data.error) requestErrorHandler(data.error);
    }
  }, [data]);

  return (
    <AddGroupModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      {content === Content.Form && (
        <motion.div className="__form-container">
          <div className="__form-container">
            <form.name placeholder={Strings.Inputs.GroupName} />
          </div>
          <Button
            text={Strings.Buttons.Add}
            color="success"
            onClick={confirmHandler}
          />
        </motion.div>
      )}
      {content === Content.Loading && (
        <motion.div
          className="__form-container"
          animate={contentAnimation}
          initial={{ opacity: 0 }}
        >
          <Spinners.circles width="300px" height="300px" />
          <span>{Strings.Messages.RegisterLoading}</span>
        </motion.div>
      )}
    </AddGroupModalElement>
  );
}
