import {
  Checkbox,
  ImageAvatar,
  Table,
  TableColumn,
  useModal,
} from '@keymax-dev/smartepi-ui';
import axios from 'axios';
import React from 'react';
import ListPageSearchBar from '../../components/list-page-search-bar';
import PageActions from '../../components/page-actions';
import PageTitle from '../../components/page-title';
import { Product } from '../../domain/models';
import useListPage from '../../hooks/use-list-page';
import {
  BASE_API_URL,
  EndPoints,
  FilePaths,
  RouterPaths,
} from '../../utils/constants';
import AddProductModal from './add-product';
import { ProductDataCellElement, ProductPageElement } from './style';
import { findToken, createConfig } from '../../hooks/use-rest';

const Strings = {
  Title: 'Produtos',
  Inputs: {
    Search: 'Buscar produto...',
  },
  Messages: {
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar os produtos selecionados?',
  },
  Buttons: {
    AddProduct: 'Adicionar Produto',
    ExportCSV: 'Exportar CSV',
  },
  Table: {
    ColumnTitles: {
      Selection: 'Seleção',
      Product: 'Produto',
    },
    ProductCell: {
      RegisteredSizes: (n: number) =>
        `${n} tamanho${n > 1 ? 's' : ''} cadastrado${n > 1 ? 's' : ''}`,
    },
  },
};

export default function ProductPage(): JSX.Element {
  const [isDownloadingCSV, setIsDownloadingCSV] = React.useState(false);

  // Hooks
  const listPage = useListPage<Product.Data>(
    EndPoints.Products,
    RouterPaths.Product
  );
  const addProductModal = useModal(<AddProductModal />);

  // Handlers
  const addProductClickHandler = (): void => {
    addProductModal.onClose(listPage.addItemModalCloseHandler);
    addProductModal.open();
  };

  // Page Elements
  const actions = [
    {
      text: Strings.Buttons.AddProduct,
      icon: 'plus',
      onClick: addProductClickHandler,
    },
    {
      text: Strings.Buttons.ExportCSV,
      icon: 'download',
      onClick: downloadCSV,
      disabled: isDownloadingCSV,
      style: isDownloadingCSV
        ? {
            cursor: 'not-allowed',
            animationName: 'spin',
            animationDuration: '1000ms',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
          }
        : undefined,
    },
  ];
  const tableConfig = {
    rowEvents: listPage.rowEvents,
    rowProps: listPage.rowProps,
    onScroll: listPage.tableScrollHandler,
  };

  function downloadCSV(): void {
    const url = `${BASE_API_URL}/${EndPoints.Products}/export-csv`;
    setIsDownloadingCSV(true);
    findToken()
      .then((token) =>
        axios.get(url, createConfig(token)).then((res) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([res.data]));
          link.setAttribute('download', 'produtos.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      )
      .finally(() => setIsDownloadingCSV(false));
  }

  return (
    <ProductPageElement id="product-page">
      <PageTitle>{Strings.Title}</PageTitle>
      <PageActions actions={actions} />
      <ListPageSearchBar
        listPage={listPage}
        strings={{
          placeholder: Strings.Inputs.Search,
          deleteTitle: Strings.Messages.Caution,
          deleteMessage: Strings.Messages.DeleteConfirmation,
        }}
      />
      <Table
        data={listPage.restList.list}
        config={tableConfig}
        loading={listPage.restList.loading}
      >
        {listPage.selectable && (
          <TableColumn
            name={Strings.Table.ColumnTitles.Selection}
            key="selected"
            flex={0}
            minwidth="120px"
          >
            {(item: Product.Data): JSX.Element => (
              <div style={{ position: 'absolute' }}>
                <Checkbox
                  value={listPage.selectableList.keys.has(`${item._id}`)}
                />
              </div>
            )}
          </TableColumn>
        )}
        <TableColumn
          name={Strings.Table.ColumnTitles.Product}
          key="product"
          flex={1}
          minwidth="320px"
        >
          {(item: Product.Data): JSX.Element => (
            <ProductDataCellElement
              active={listPage.activeDetails.item?._id === item._id}
              className="ui-grid-horizontal"
            >
              <ImageAvatar
                src={
                  item.pathImage
                    ? FilePaths.ProductImage(item.pathImage)
                    : undefined
                }
                size="60px"
                style={{ margin: '0 2.5vw' }}
              />
              <div className="ui-grid-vertical">
                <h2>{item.name}</h2>
                <span>
                  {Strings.Table.ProductCell.RegisteredSizes(
                    item.sizes?.length || 0
                  )}
                </span>
              </div>
            </ProductDataCellElement>
          )}
        </TableColumn>
      </Table>
    </ProductPageElement>
  );
}
