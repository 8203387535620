import { User } from '../../domain/models';
import { useToast } from '@keymax-dev/smartepi-ui';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import LoadingPage from '../../pages/loading';
import { PageNotFound } from '../../pages/not-found';
import { ReduxStore } from '../../redux';
import AuthActions from '../../redux/actions/auth-actions';
import ViewActions from '../../redux/actions/view-actions';
import { Auth } from '../../domain/models';
import { parseToken, verifyPermissions } from '../../utils';
import { RouterPaths } from '../../utils/constants';

const Strings = {
  Messages: {
    Disconnect: 'Sua autorização foi negada. Por favor, faça login novamente.',
  },
};

const samePath = (defined = '', location = ''): boolean => {
  const createPathArray = (path: string): string[] =>
    path.split('/').filter((s) => !s.endsWith('?'));

  const A = createPathArray(defined);
  const B = createPathArray(location);

  // TODO: Fix logic
  if (A.length > B.length) return false;
  for (let i = 0; i < A.length; i++) {
    if (A[i] !== B[i]) return false;
  }
  return true;
};

interface RoutePermission {
  permissions: User.Permission[];
}

export default function PrivateRoute(
  props: RouteProps & RoutePermission
): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector<ReduxStore, Partial<Auth> | undefined>(
    (state) => state.auth
  );
  const disconnectToast = useToast(<span>{Strings.Messages.Disconnect}</span>, {
    color: 'danger',
  });

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (
      !auth?.id &&
      samePath(props.path as string, history.location.pathname)
    ) {
      try {
        dispatch(AuthActions.setAuth(parseToken(accessToken as string)));
      } catch {
        console.error('Parse Token Failed');
        dispatch(AuthActions.clearAuth());
        dispatch(ViewActions.sidebar.disable());
        history.replace(RouterPaths.Login);
        setTimeout(() => disconnectToast.open());
      }
    } else {
      dispatch(ViewActions.sidebar.enable());
    }
  }, [auth?.id]);

  if (auth) {
    if (
      props.path &&
      verifyPermissions(auth?.permission || [], props.permissions)
    ) {
      return <Route {...props} />;
    } else {
      return <PageNotFound />;
    }
  } else {
    return <LoadingPage />;
  }
}
