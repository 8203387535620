import {
  Animations,
  Button,
  ModalController,
  Spinners,
  useForm,
  useToast,
  Validators,
} from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../../../components/page-title';
import useRest from '../../../../../hooks/use-rest';
import { Machine, ErrorResponse } from '../../../../../domain/models';
import { EndPoints } from '../../../../../utils/constants';
import { getErrorMessage } from '../../../../../utils/error-messages';
import { EditMachineCustomerModalElement } from './style';

enum Content {
  Form,
  Loading,
}

const { usePut } = useRest();

const Strings = {
  Buttons: {
    Save: 'Salvar',
  },
  Inputs: {
    MachineName: 'Nome da Máquina',
    Observation: 'Observações',
  },
  Messages: {
    EditSuccess: 'Máquina editada com sucesso!',
    InvalidForm: 'Preencha os campos corretamente!',
    EditingMachine: 'Editando máquina...',
  },
  Title: 'Editar Máquina',
};

interface EditMachineCustomerModalProps {
  controller?: ModalController;
  machine?: Machine.Data;
}

export default function EditMachineCustomerModal({
  controller,
  machine,
}: EditMachineCustomerModalProps): JSX.Element {
  const [data, put] = usePut({ loading: false });
  const errorToast = useToast(<span />, { color: 'danger' });
  const successToast = useToast(<span>{Strings.Messages.EditSuccess}</span>, {
    color: 'success',
  });

  const [form, getErrors, getValues] = useForm(
    [
      {
        key: 'name',
        initialValue: machine?.name,
        validators: [new Validators.Required(), new Validators.MinLength(4)],
      },
      {
        key: 'observation',
        initialValue: machine?.observation,
        validators: [],
      },
    ],
    'editMachine'
  );

  const contentAnimation = useAnimation();
  const [content, _setContent] = useState<Content>(Content.Form);
  const setContent = (value: Content): void => {
    contentAnimation
      .start(Animations.FadeOut)
      .then(() => Promise.resolve(_setContent(value)))
      .then(() => contentAnimation.start(Animations.FadeIn));
  };

  const requestSuccessHandler = (data: Machine.Data): void => {
    setTimeout(() => successToast.open());
    if (controller) controller.close(data);
  };

  const requestErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  const confirmHandler = (): void => {
    if (!getErrors()) {
      put(EndPoints.Machine(machine?._id || ''), {
        ...machine,
        ...getValues(),
      });
    } else {
      errorToast.setContent(Strings.Messages.InvalidForm);
      setTimeout(() => errorToast.open());
    }
  };

  useEffect(() => {
    if (data.loading) {
      setContent(Content.Loading);
    } else {
      setContent(Content.Form);
      if (data.payload) requestSuccessHandler(data.payload.data);
      else if (data.error) requestErrorHandler(data.error);
    }
  }, [data]);

  return (
    <EditMachineCustomerModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      {content === Content.Form && (
        <motion.div className="__form-container">
          <div className="__form-container">
            <form.name placeholder={Strings.Inputs.MachineName} />
            <form.observation placeholder={Strings.Inputs.Observation} />
          </div>
          <Button
            text={Strings.Buttons.Save}
            color="success"
            onClick={confirmHandler}
          />
        </motion.div>
      )}
      {content === Content.Loading && (
        <motion.div
          className="__form-container"
          animate={contentAnimation}
          initial={{ opacity: 0 }}
        >
          <Spinners.circles width="300px" height="300px" />
          <span>{Strings.Messages.EditingMachine}</span>
        </motion.div>
      )}
    </EditMachineCustomerModalElement>
  );
}
