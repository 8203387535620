import { Input, Tabs, useModal, useToast } from '@keymax-dev/smartepi-ui';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  WithActiveDetails,
  WithActiveDetailsContentProps,
} from '../../../components/details-section';
import PageActions from '../../../components/page-actions';
import { Trigger } from '../../../domain/models';
import { useRestItemDelete } from '../../../hooks/use-rest-item-delete';

import { EndPoints, RouterPaths } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import { TriggerDetailsElement } from './style';
import ChangeTriggerReceiversModal from './change-receivers';
import { DetailsTab, EventsTab } from './tabs';

const Strings = {
  Buttons: {
    ChangeTriggerReceivers: 'Alterar Recebedores',
    DeleteTrigger: 'Excluir Alerta',
    ExportEvents: 'Exportar Eventos',
  },
  Inputs: {
    TriggerName: 'Nome do Alerta',
  },
  Labels: {
    Name: 'Nome',
    Type: 'Tipo',
    Status: 'Status',
  },
  Messages: {
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo excluir o alerta?',
  },
};

const TriggerDetails = (
  props: WithActiveDetailsContentProps<Trigger.Data>
): JSX.Element => {
  // Hooks
  const {
    currentState: [trigger, setTrigger, updateTriggerValue],
    loading: updateLoading,
    requestError,
  } = props.restUpdate;

  const errorToast = useToast(<span />, { color: 'danger' });
  const warnToast = useToast(<span />, { color: 'primary' });

  const [triggerId, setTriggerId] = React.useState(
    useParams<{ id: string }>().id
  );

  const changeTriggerReceiversModal = useModal(
    <ChangeTriggerReceiversModal
      triggerId={triggerId}
      restUpdate={props.restUpdate}
    />
  );

  const { deleteClickHandler } = useRestItemDelete({
    endpoint: EndPoints.Triggers,
    item: trigger,
    routerPath: RouterPaths.Triggers,
    title: Strings.Messages.Caution,
    message: Strings.Messages.DeleteConfirmation,
  });

  const openChangeTriggerReceiversModal = (triggerId: string): void => {
    changeTriggerReceiversModal.injectProps({
      triggerId,
      restUpdate: props.restUpdate,
    });
    changeTriggerReceiversModal.open();
  };

  // Page Elements
  const pageActions = [
    {
      text: Strings.Buttons.ChangeTriggerReceivers,
      icon: 'accountTie',
      onClick: () => openChangeTriggerReceiversModal(triggerId),
    },
    {
      text: Strings.Buttons.DeleteTrigger,
      icon: 'trash',
      onClick: deleteClickHandler,
    },
    // {
    //   text: Strings.Buttons.ExportEvents,
    //   icon: 'fileDownload',
    //   onClick: () => {
    //     warnToast.setContent('Não implementado');
    //     setTimeout(() => warnToast.open());
    //   },
    // },
  ];

  // Effects
  useEffect(() => {
    if (requestError) {
      errorToast.setContent(getErrorMessage(requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [requestError]);

  useEffect(() => {
    setTriggerId(trigger._id);
  }, [trigger._id]);

  return (
    <TriggerDetailsElement>
      <header className="__title-header">
        <Input
          disabled={updateLoading}
          iconRight="pencil"
          invert
          onBlur={(event) =>
            updateTriggerValue({ name: event.currentTarget.value })
          }
          onChange={(event) =>
            setTrigger({ ...trigger, name: event.target.value })
          }
          onKeyDown={(event) =>
            event.key === 'Enter' &&
            updateTriggerValue({ name: event.currentTarget.value })
          }
          value={trigger.name}
          placeholder={Strings.Inputs.TriggerName}
        />
        <PageActions actions={pageActions} invert />
      </header>

      <Tabs>
        <EventsTab title="Eventos" trigger={trigger} />
        <DetailsTab restUpdate={props.restUpdate} title="Detalhes" />
      </Tabs>
    </TriggerDetailsElement>
  );
};

export default (): JSX.Element =>
  WithActiveDetails(TriggerDetails, {
    id: useParams<{ id: string }>().id,
    endpoint: EndPoints.Trigger,
    cleanRouterPath: RouterPaths.Trigger(),
  });
