import {
  Checkbox,
  Table,
  TableColumn,
  useModal,
} from '@keymax-dev/smartepi-ui';
import axios from 'axios';
import React from 'react';
import ListPageSearchBar from '../../components/list-page-search-bar';
import PageActions from '../../components/page-actions';
import PageTitle from '../../components/page-title';
import useListPage from '../../hooks/use-list-page';
import { BASE_API_URL, EndPoints, RouterPaths } from '../../utils/constants';
import AddCostCenterModal from './add-cost-center';
import { CostCentersPageElement, CostCentersTableRowElement } from './style';
import { CostCenter } from '../../domain/models';
import { createConfig, findToken } from '../../hooks/use-rest';

const Strings = {
  Buttons: {
    AddCenter: 'Adicionar Centro',
    ExportCSV: 'Exportar CSV',
  },
  Inputs: {
    Search: 'Buscar centro de custo...',
  },
  Title: 'Centros de Custo',
  Table: {
    ColumnTitles: {
      Selection: 'Seleção',
      CostCenter: 'Centro de Custo',
    },
  },
  Messages: {
    Caution: 'Atenção!',
    DeleteConfirmation:
      'Você deseja mesmo deletar os centros de custo selecionados?',
  },
};

export default function CostCenterPage(): JSX.Element {
  const [isDownloadingCSV, setIsDownloadingCSV] = React.useState(false);

  // Hooks
  const listPage = useListPage<CostCenter.Data>(
    EndPoints.CostCenters,
    RouterPaths.CostCenter
  );
  const addCostCenterModal = useModal(<AddCostCenterModal />);

  // Handlers
  const addCenterCostHandler = (): void => {
    addCostCenterModal.onClose(listPage.addItemModalCloseHandler);
    addCostCenterModal.open();
  };

  // Component Elements
  const pageActions = [
    {
      text: Strings.Buttons.AddCenter,
      icon: 'plus',
      onClick: addCenterCostHandler,
    },
    {
      text: isDownloadingCSV ? '' : Strings.Buttons.ExportCSV,
      icon: isDownloadingCSV ? 'refresh' : 'download',
      onClick: downloadCSV,
      disabled: isDownloadingCSV,
      style: isDownloadingCSV
        ? {
            cursor: 'not-allowed',
            animationName: 'spin',
            animationDuration: '1000ms',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
          }
        : undefined,
    },
  ];

  const tableConfig = {
    rowEvents: listPage.rowEvents,
    rowProps: listPage.rowProps,
    onScroll: listPage.tableScrollHandler,
  };

  function downloadCSV(): void {
    const url = `${BASE_API_URL}/${EndPoints.CostCenters}/export-csv`;
    setIsDownloadingCSV(true);
    findToken()
      .then((token) =>
        axios.get(url, createConfig(token)).then((res) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([res.data]));
          link.setAttribute('download', 'centros-de-custo.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      )
      .finally(() => setIsDownloadingCSV(false));
  }

  return (
    <CostCentersPageElement>
      <PageTitle>{Strings.Title}</PageTitle>
      <PageActions actions={pageActions} />
      <ListPageSearchBar
        listPage={listPage}
        strings={{
          placeholder: Strings.Inputs.Search,
          deleteTitle: Strings.Messages.Caution,
          deleteMessage: Strings.Messages.DeleteConfirmation,
        }}
      />
      <Table
        data={listPage.restList.list}
        config={tableConfig}
        loading={listPage.restList.loading}
      >
        {listPage.selectable && (
          <TableColumn
            name={Strings.Table.ColumnTitles.Selection}
            key="selected"
            flex={0}
            minwidth="120px"
          >
            {(item: CostCenter.Data): JSX.Element => (
              <div style={{ position: 'absolute' }}>
                <Checkbox
                  size="25px"
                  value={listPage.selectableList.keys.has(`${item._id}`)}
                />
              </div>
            )}
          </TableColumn>
        )}
        <TableColumn
          name={Strings.Table.ColumnTitles.CostCenter}
          key="cost-center"
          flex={1}
          minwidth="320px"
        >
          {(item: CostCenter.Data): JSX.Element => (
            <CostCentersTableRowElement
              active={listPage.activeDetails.item?._id === item._id}
            >
              {item.name}
            </CostCentersTableRowElement>
          )}
        </TableColumn>
      </Table>
    </CostCentersPageElement>
  );
}
