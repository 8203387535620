import { DatabaseDocument } from '../../domain/models';
import React from 'react';
import useRestUpdate, { RestUpdateProps } from '../../hooks/use-rest-update';
import { WithActiveDetailsContentProps } from './with-active-details';

export interface UpdatableContentProps<T extends DatabaseDocument.Data>
  extends RestUpdateProps<T> {
  Content: React.FC<WithActiveDetailsContentProps<T>>;
}

export const UpdatableContent = <T extends DatabaseDocument.Data>({
  Content,
  endpoint,
  state,
}: UpdatableContentProps<T>): JSX.Element => {
  const restUpdate = useRestUpdate<T>({ endpoint, state });

  return <Content restUpdate={restUpdate} />;
};
