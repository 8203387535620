import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';
import { Withdrawal } from '../../../domain/models';

export const ApprovalDetailsModalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  position: relative;

  width: 50vw;
  height: 80vh;
  max-height: 600px;
  max-width: 600px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .ui-grid-vertical {
    margin: 10px 0 0 0;
    width: 100%;

    &.__grid-border {
      /* border-right: 1px solid ${() =>
        getGlobalTheme().colors.primary.principal}32; */

      &:last-child {
        border-color: transparent;
      }
    }
  }

  .ui-grid-horizontal {
    flex-wrap: wrap;
    align-items: flex-end;
  }

  h2 {
    font-size: ${() => getGlobalTheme().font.h2.fontSize};
    font-weight: bold;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label {
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.5);
    font-weight: 200;
    color: ${() => getGlobalTheme().colors.primary.principal};
    text-transform: uppercase;
  }

  .__grid-container {
    width: 100%;
    height: fit-content;
    justify-content: flex-start;
  }

  .__grid-divider-bottom {
    padding: 0 0 10px 0;
    border-bottom: 1px solid
      ${() => getGlobalTheme().colors.primary.principal}32;
  }

  .__grid-child {
    flex: 0 0 auto;
  }

  .ui-textarea-container-downline {
    width: calc(100% - 5vw);
    textarea {
      min-height: 40px;
    }
  }
`;

interface ApprovalDetailsFooterElementProps {
  status: Withdrawal.ApprovalStatus;
}

export const ApprovalDetailsFooterElement = styled.footer<ApprovalDetailsFooterElementProps>`
  padding: 15px;
  margin-top: 15px;
  min-height: fit-content;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom-left-radius: ${() => getGlobalTheme().borderRadius};
  border-bottom-right-radius: ${() => getGlobalTheme().borderRadius};

  ${({ status }) => {
    if (status === Withdrawal.ApprovalStatus.APPROVED) {
      return `
                color: ${getGlobalTheme().colors.success.contrast};
                background-color: ${getGlobalTheme().colors.success.principal};
            `;
    } else if (status === Withdrawal.ApprovalStatus.NOT_APPROVED) {
      return `
                color: ${getGlobalTheme().colors.danger.contrast};
                background-color: ${getGlobalTheme().colors.danger.principal};
            `;
    }
  }}

  label {
    color: unset;
  }
`;
