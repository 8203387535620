import {
  Input,
  ScrollableContainer,
  Select,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  WithActiveDetails,
  WithActiveDetailsContentProps,
} from '../../../components/details-section';
import ExportFileModal from '../../../components/export-file-modal';
import PageActions from '../../../components/page-actions';
import {
  CostCenter,
  CustomerAccessType,
  CustomerAccessTypes,
  findCustomerAccessType,
  Group,
  Role,
  Sector,
  User,
} from '../../../domain/models';
import { useRestItemDelete } from '../../../hooks/use-rest-item-delete';
import useRestSearchSelect from '../../../hooks/use-rest-search-select';
import { parseLocaleDate } from '../../../utils';
import { EndPoints, FilePaths, RouterPaths } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import EmployeeChangePasswordModal from './change-password';
import { EmployeeDetailsElement } from './style';

const Strings = {
  Buttons: {
    DeleteEmployee: 'Excluir Funcionário',
    ChangePassword: 'Atualizar Senha',
    ExportSheet: 'Exportar Ficha de EPI',
  },
  Inputs: {
    EmployeeName: 'Nome do Funcionário',
  },
  Labels: {
    Email: 'Email',
    Sector: 'Setor',
    CostCenter1: 'Centro de Custo 1',
    CostCenter2: 'Centro de Custo 2',
    Group: 'Grupo',
    AdmissionDate: 'Data de Admissão',
    Identification: 'Identificação',
    AccessType: 'Tipo de Acesso',
    Role: 'Função',
    ClearValue: '❌ Remover',
  },
  Messages: {
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar o funcionário?',
  },
};

const EmployeeDetails = (
  props: WithActiveDetailsContentProps<User.Data>
): JSX.Element => {
  // Hooks
  const {
    currentState: [user, setUser, updateUserValue],
    loading: updateLoading,
    requestError,
  } = props.restUpdate;

  const errorToast = useToast(<span />, { color: 'danger' });

  const groupSelect = useRestSearchSelect<Group.Data>({
    endpoint: EndPoints.Groups,
  });
  const roleSelect = useRestSearchSelect<Role.Data>({
    endpoint: EndPoints.Roles,
  });
  const sectorSelect = useRestSearchSelect<Sector.Data>({
    endpoint: EndPoints.Sectors,
  });
  const costCenterSelect = useRestSearchSelect<CostCenter.Data>({
    endpoint: EndPoints.CostCenters,
  });

  const changePasswordModal = useModal(<EmployeeChangePasswordModal />);
  const exportFileModal = useModal(<ExportFileModal />);

  const { deleteClickHandler } = useRestItemDelete({
    endpoint: EndPoints.Users,
    item: user,
    routerPath: RouterPaths.Employees,
    title: Strings.Messages.Caution,
    message: Strings.Messages.DeleteConfirmation,
  });

  const dateChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const dateTime = parseLocaleDate(event.currentTarget.value).getTime();
    updateUserValue({ admissionDate: dateTime });
  };

  const costCenterSelectHandler = (
    costCenter: CostCenter.Data | undefined,
    index: 0 | 1
  ): void => {
    const currentCostCenter: (CostCenter.Data | undefined)[] = user.costCenter;

    currentCostCenter[index] = costCenter;

    updateUserValue({
      costCenterId: currentCostCenter.map((item) => item?._id || null),
    });
    setUser({
      ...user,
      costCenter: currentCostCenter as CostCenter.Data[],
    });
  };

  const generateSheetHandler = (): void => {
    if (!user._id) throw new Error('Invalid User Id');
    exportFileModal.injectProps({
      endPoint: EndPoints.SheetExport(user._id),
      filePath: FilePaths.Sheet,
    });
    exportFileModal.open();
  };

  // Page Elements
  const pageActions = [
    {
      text: Strings.Buttons.ChangePassword,
      icon: 'keyChange',
      onClick: () => changePasswordModal.open(),
    },
    {
      text: Strings.Buttons.DeleteEmployee,
      icon: 'trash',
      onClick: deleteClickHandler,
    },
    {
      text: Strings.Buttons.ExportSheet,
      icon: 'fileDownload',
      onClick: generateSheetHandler,
    },
  ];

  // Effects
  useEffect(() => {
    if (user && user._id) {
      changePasswordModal.injectProps({ user: user });
    }
  }, [user]);

  useEffect(() => {
    if (requestError) {
      errorToast.setContent(getErrorMessage(requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [requestError]);

  return (
    <EmployeeDetailsElement>
      <header className="__title-header">
        <Input
          disabled={updateLoading}
          iconRight="pencil"
          invert
          onBlur={(event) =>
            updateUserValue({ name: event.currentTarget.value })
          }
          onChange={(event) => setUser({ ...user, name: event.target.value })}
          onKeyDown={(event) =>
            event.key === 'Enter' &&
            updateUserValue({ name: event.currentTarget.value })
          }
          value={user.name}
          placeholder={Strings.Inputs.EmployeeName}
        />
        <PageActions actions={pageActions} invert />
      </header>

      <div className="__employee-body">
        <ScrollableContainer flexDirection="column" className="__scrollable">
          <Input
            placeholder={Strings.Labels.Identification}
            disabled={updateLoading}
            iconRight="pencil"
            onBlur={(event) =>
              updateUserValue({
                identification: event.currentTarget.value,
              })
            }
            onChange={(event) =>
              setUser({
                ...user,
                identification: event.target.value,
              })
            }
            onKeyDown={(event) =>
              event.key === 'Enter' &&
              updateUserValue({
                identification: event.currentTarget.value,
              })
            }
            value={user.identification || ''}
          />

          <Input
            placeholder={Strings.Labels.AdmissionDate}
            disabled={updateLoading}
            onChange={dateChangeHandler}
            value={(user.admissionDate
              ? new Date(user.admissionDate)
              : new Date()
            ).toLocaleDateString('pt-br')}
            enableDatepicker
          />
          <Input
            placeholder={Strings.Labels.Email}
            disabled={updateLoading}
            iconRight="email"
            onBlur={(event) =>
              updateUserValue({
                email: event.currentTarget.value,
              })
            }
            onChange={(event) =>
              setUser({ ...user, email: event.target.value })
            }
            onKeyDown={(event) =>
              event.key === 'Enter' &&
              updateUserValue({
                email: event.currentTarget.value,
              })
            }
            value={user.email || ''}
          />

          <Select<CustomerAccessType>
            data={CustomerAccessTypes}
            dataKey="name"
            onSelect={(event, value): void =>
              updateUserValue({ permission: value?.permissions })
            }
            placeholder={Strings.Labels.AccessType}
            value={findCustomerAccessType(user.permission)}
          />

          <Select<Role.Data>
            placeholder={Strings.Labels.Role}
            data={roleSelect.searchedList}
            dataKey="name"
            loading={roleSelect.loading}
            value={user.role}
            onSearch={roleSelect.searchHandler}
            onSelect={(event, value) => {
              updateUserValue({ roleId: value?._id || null });
              setUser({ ...user, role: value });
            }}
            enableClear
          />

          <Select<Group.Data>
            placeholder={Strings.Labels.Group}
            data={groupSelect.searchedList}
            dataKey="name"
            loading={groupSelect.loading}
            value={user.group}
            onSearch={groupSelect.searchHandler}
            onSelect={(event, value) => {
              updateUserValue({ groupId: value?._id || null });
              setUser({ ...user, group: value });
            }}
            enableClear
          />

          <Select<Sector.Data>
            placeholder={Strings.Labels.Sector}
            data={sectorSelect.searchedList}
            dataKey="name"
            loading={sectorSelect.loading}
            value={user.sector}
            onSearch={sectorSelect.searchHandler}
            onSelect={(event, value) => {
              updateUserValue({ sectorId: value?._id || null });
              setUser({ ...user, sector: value });
            }}
            enableClear
          />

          <Select<CostCenter.Data>
            placeholder={Strings.Labels.CostCenter1}
            data={costCenterSelect.searchedList}
            dataKey="name"
            loading={costCenterSelect.loading}
            value={user.costCenter ? user.costCenter[0] : undefined}
            onSearch={costCenterSelect.searchHandler}
            onSelect={(event, value) => costCenterSelectHandler(value, 0)}
            enableClear
          />

          <Select<CostCenter.Data>
            placeholder={Strings.Labels.CostCenter2}
            data={costCenterSelect.searchedList}
            dataKey="name"
            loading={costCenterSelect.loading}
            value={user.costCenter ? user.costCenter[1] : undefined}
            onSearch={costCenterSelect.searchHandler}
            onSelect={(event, value) => costCenterSelectHandler(value, 1)}
            enableClear
          />
        </ScrollableContainer>
      </div>
    </EmployeeDetailsElement>
  );
};

export default (): JSX.Element =>
  WithActiveDetails(EmployeeDetails, {
    id: useParams<{ id: string }>().id,
    endpoint: EndPoints.User,
    cleanRouterPath: RouterPaths.Employee(),
  });
