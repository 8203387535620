import {
  Input,
  Table,
  TableColumn,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  WithActiveDetails,
  WithActiveDetailsContentProps,
} from '../../../components/details-section';
import PageActions from '../../../components/page-actions';
import { Sector, User } from '../../../domain/models';
import { useRestItemDelete } from '../../../hooks/use-rest-item-delete';
import useRestList from '../../../hooks/use-rest-list';
import { EndPoints, RouterPaths } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import AddEmployeeSectorModal from './add-employee';
import { SectorDetailsElement } from './style';

const Strings = {
  Buttons: {
    DeleteSector: 'Excluir Setor',
    AddEmployee: 'Adicionar Funcionário',
  },
  Inputs: {
    SectorName: 'Nome do Setor',
    Search: 'Buscar funcionário...',
  },
  Table: {
    ColumnTitles: {
      Identification: 'ID',
      Employee: 'Funcionário',
    },
    Messages: {
      EmptyEmployees: 'Não há funcionários no setor.',
    },
  },
  Messages: {
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar o setor?',
  },
};

const SectorDetails = (
  props: WithActiveDetailsContentProps<Sector.Data>
): JSX.Element => {
  // Hooks
  const {
    currentState: [sector, setSector, updateSectorValue],
    loading: updateLoading,
    requestError,
  } = props.restUpdate;

  const restList = useRestList<User.Data>(EndPoints.Users, {
    requestFilters: [{ filter: 'sector', filterValue: sector._id }],
  });

  const errorToast = useToast(<span />, { color: 'danger' });
  const addEmployeeModal = useModal(
    <AddEmployeeSectorModal sectorId={sector._id || ''} />
  );

  // Handlers
  const tableScrollHandler = (
    event: React.UIEvent<HTMLTableSectionElement>
  ): void => {
    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight - 30
    ) {
      restList.nextBookmark();
    }
  };
  const addEmployeeClickHandler = (): void => {
    addEmployeeModal.injectProps({ sectorId: sector._id });
    addEmployeeModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const newUser = reason as User.Data;
        restList.updateItems([newUser]);
      }
    });
    addEmployeeModal.open();
  };
  const { deleteClickHandler } = useRestItemDelete({
    endpoint: EndPoints.Sectors,
    item: sector,
    routerPath: RouterPaths.Sectors,
    title: Strings.Messages.Caution,
    message: Strings.Messages.DeleteConfirmation,
  });

  // Page Elements
  const pageActions = [
    {
      text: Strings.Buttons.DeleteSector,
      icon: 'trash',
      onClick: deleteClickHandler,
    },
    {
      text: Strings.Buttons.AddEmployee,
      icon: 'accountPlus',
      onClick: addEmployeeClickHandler,
    },
  ];

  // Effects
  useEffect(() => {
    if (requestError) {
      errorToast.setContent(getErrorMessage(requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [requestError]);

  return (
    <SectorDetailsElement>
      <header className="__title-header">
        <Input
          disabled={updateLoading}
          iconRight="pencil"
          invert
          onBlur={(event) =>
            updateSectorValue({ name: event.currentTarget.value })
          }
          onChange={(event) =>
            setSector({
              ...sector,
              name: event.currentTarget.value,
            })
          }
          onKeyDown={(event) =>
            event.key === 'Enter' &&
            updateSectorValue({ name: event.currentTarget.value })
          }
          value={sector.name}
          placeholder={Strings.Inputs.SectorName}
        />
        <PageActions actions={pageActions} invert />
      </header>
      <nav>
        <Input
          containerType="outline"
          iconRight="magnify"
          placeholder={Strings.Inputs.Search}
          containerProps={{ className: '__search-bar' }}
          invert
          onBlur={(event) => restList.search(event.currentTarget.value)}
          onKeyPress={(event) =>
            event.key === 'Enter' && restList.search(event.currentTarget.value)
          }
          disabled={restList.loading}
        />
      </nav>
      <Table
        data={restList.list}
        loading={restList.loading}
        config={{
          emptyMessage: Strings.Table.Messages.EmptyEmployees,
          onScroll: tableScrollHandler,
        }}
      >
        <TableColumn
          name={Strings.Table.ColumnTitles.Identification}
          key="identification"
          minwidth={window.innerWidth > 600 ? '100px' : '75px'}
        >
          {(item: User.Data): JSX.Element => <div>{item.identification}</div>}
        </TableColumn>
        <TableColumn
          name={Strings.Table.ColumnTitles.Employee}
          key="name"
          flex={1}
          minwidth="220px"
        >
          {(item: User.Data): JSX.Element => <div>{item.name}</div>}
        </TableColumn>
      </Table>
    </SectorDetailsElement>
  );
};

export default (): JSX.Element =>
  WithActiveDetails(SectorDetails, {
    id: useParams<{ id: string }>().id,
    endpoint: EndPoints.Sector,
    cleanRouterPath: RouterPaths.Sector(),
  });
