import { Input, Tabs, useModal, useToast } from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  WithActiveDetails,
  WithActiveDetailsContentProps,
} from '../../../components/details-section';
import PageActions from '../../../components/page-actions';
import { Group, User } from '../../../domain/models';
import { useRestItemDelete } from '../../../hooks/use-rest-item-delete';
import useRestList from '../../../hooks/use-rest-list';
import { EndPoints, RouterPaths } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import AddEmployeeGroupModal from './add-employee/index';
import AddProductGroupModal from './add-product';
import { EmployeeTab } from './employee-tab';
import { ProductTab } from './product-tab';
import { GroupDetailsElement } from './style';

const Strings = {
  Buttons: {
    DeleteGroup: 'Excluir Grupo',
    AddEmployee: 'Adicionar Funcionário',
    AddProduct: 'Adicionar Produto',
  },
  Input: {
    GroupName: 'Nome do Grupo',
  },
  Messages: {
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar o grupo?',
  },
  Tabs: {
    Employees: 'Funcionários',
    Products: 'Produtos',
  },
};

const GroupDetails = (
  props: WithActiveDetailsContentProps<Group.Data>
): JSX.Element => {
  // Hooks
  const {
    currentState: [group, setGroup, updateGroupValue],
    loading: updateLoading,
    requestError,
  } = props.restUpdate;
  const restUserList = useRestList<User.Data>(EndPoints.Users, {
    requestFilters: [{ filter: 'group', filterValue: group._id }],
  });
  const [tabIndex, setTabIndex] = useState<number>(0);
  const errorToast = useToast(<span />, { color: 'danger' });
  const addEmployeeModal = useModal(
    <AddEmployeeGroupModal groupId={group._id} />
  );
  const addProductModal = useModal(
    <AddProductGroupModal
      groupId={group._id}
      currentGroupProducts={group.products}
    />
  );

  // Handlers
  const addEmployeeClickHandler = (): void => {
    addEmployeeModal.injectProps({ groupId: group._id });
    addEmployeeModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const newUser = reason as User.Data;
        restUserList.updateItems([newUser]);
        setTabIndex(1);
      }
    });
    addEmployeeModal.open();
  };
  const addProductClickHandler = (): void => {
    addProductModal.injectProps({
      groupId: group._id,
      currentGroupProducts: group.products,
    });
    addProductModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const newGroup = reason as Group.Data;
        setGroup(newGroup);
        setTabIndex(0);
      }
    });
    addProductModal.open();
  };
  const { deleteClickHandler } = useRestItemDelete({
    endpoint: EndPoints.Groups,
    item: group,
    routerPath: RouterPaths.Groups,
    title: Strings.Messages.Caution,
    message: Strings.Messages.DeleteConfirmation,
  });

  // Page Elements
  const pageActions = [
    {
      text: Strings.Buttons.AddProduct,
      icon: 'shapePlus',
      onClick: addProductClickHandler,
    },
    {
      text: Strings.Buttons.AddEmployee,
      icon: 'accountPlus',
      onClick: addEmployeeClickHandler,
    },
    {
      text: Strings.Buttons.DeleteGroup,
      icon: 'trash',
      onClick: deleteClickHandler,
    },
  ];

  // Effects
  useEffect(() => {
    if (requestError) {
      errorToast.setContent(getErrorMessage(requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [requestError]);
  useEffect(() => {
    if (restUserList.requestError) {
      errorToast.setContent(getErrorMessage(restUserList.requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [restUserList.requestError]);

  return (
    <GroupDetailsElement>
      <header className="__title-header">
        <Input
          disabled={updateLoading}
          iconRight="pencil"
          invert
          onBlur={(event) =>
            updateGroupValue({ name: event.currentTarget.value })
          }
          onChange={(event) => setGroup({ ...group, name: event.target.value })}
          onKeyDown={(event) =>
            event.key === 'Enter' &&
            updateGroupValue({ name: event.currentTarget.value })
          }
          value={group.name}
          placeholder={Strings.Input.GroupName}
        />
        <PageActions actions={pageActions} invert />
      </header>
      <Tabs index={tabIndex} onTabChange={(index) => setTabIndex(index)}>
        <ProductTab
          title={Strings.Tabs.Products}
          productRestUpdate={{
            loading: updateLoading,
            requestError,
            currentState: [group, setGroup, updateGroupValue],
          }}
        />
        <EmployeeTab title={Strings.Tabs.Employees} restList={restUserList} />
      </Tabs>
    </GroupDetailsElement>
  );
};

export default (): JSX.Element =>
  WithActiveDetails(GroupDetails, {
    id: useParams<{ id: string }>().id,
    endpoint: EndPoints.Group,
    cleanRouterPath: RouterPaths.Group(),
  });
