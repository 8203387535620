import {
  Badge,
  Icon,
  ImageAvatar,
  Input,
  ModalController,
  Table,
  TableColumn,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import useRest from '../../../hooks/use-rest';
import {
  Machine,
  MachineWarehouse,
  ErrorResponse,
} from '../../../domain/models';
import { EndPoints, FilePaths } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import {
  MachineDetailsModalElement,
  WarehouseContainerElement,
  WarehouseTableChannelCell,
  WarehouseTableProductCell,
} from './style';

const Strings = {
  Inputs: {
    Name: 'Nome',
    Observation: 'Observação',
  },
  Title: 'Detalhes da Máquina',
  Table: {
    ColumnTitles: {
      Product: 'Produto',
      Channel: 'Esteira',
      Pic: 'Imagem',
    },
    ProductCell: {
      Quantity: 'Quantidade',
    },
    Messages: {
      Loading: 'Carregando estoque...',
      Empty: 'Nenhum item em estoque.',
    },
  },
  Messages: {
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    GetDataError: 'Não foi possível carregar o estoque.',
    Blocked: 'Bloqueada',
  },
};

interface MachineDetailsModalProps {
  controller?: ModalController;
  machine?: Machine.Data;
}

const { useGet, usePut } = useRest();

export default function MachineDetailsModal({
  machine,
}: MachineDetailsModalProps): JSX.Element {
  const [getData, get] = useGet();
  const [putData, put] = usePut({ loading: false });
  const [currentMachine, setCurrentMachine] = useState<Machine.Data>();
  const errorToast = useToast(<span />, { color: 'danger' });

  const detailsChangeHandler = (
    event: any,
    target: 'name' | 'observation'
  ): void => {
    if (currentMachine && machine) {
      if (machine[target] !== event.target.value) {
        put(EndPoints.Machine(currentMachine._id || ''), {
          [target]: event.target.value,
        });
      }
    }
  };
  const detailsUpdateSuccessHandler = (data: any): void => {
    if (machine) {
      machine.name = data.name;
      machine.observation = data.observation;
    }
  };
  const detailsUpdateErrorHandler = (error: ErrorResponse): void => {
    setCurrentMachine(machine as Machine.Data);
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  const warehouseTableConfig = {
    loadingMessage: Strings.Table.Messages.Loading,
    emptyMessage: Strings.Table.Messages.Empty,
  };

  useEffect(() => {
    if (!putData.loading) {
      if (putData.payload) detailsUpdateSuccessHandler(putData.payload.data);
      else if (putData.error) detailsUpdateErrorHandler(putData.error);
    }
  }, [putData]);

  useEffect(() => {
    if (getData.error) {
      console.error(getData.error);
      errorToast.setContent(Strings.Messages.GetDataError);
      setTimeout(() => errorToast.open());
    }
  }, [getData]);

  useEffect(() => {
    if (machine) {
      setCurrentMachine(machine);
      get(EndPoints.MachineWarehouse(machine.warehouseId as string));
    }
  }, [machine]);

  return (
    <MachineDetailsModalElement>
      <header>
        <Icon name="desktopTower" height="10vh" width="10vh" invert />
        <span>{Strings.Inputs.Name}</span>
        <Input
          disabled={putData.loading}
          iconRight="pencil"
          invert
          onBlur={(event) => detailsChangeHandler(event, 'name')}
          onChange={(event) =>
            setCurrentMachine({
              ...(currentMachine as Machine.Data),
              name: event.target.value,
            })
          }
          onKeyDown={(event) =>
            event.key === 'Enter' && detailsChangeHandler(event, 'name')
          }
          value={currentMachine?.name}
          placeholder={Strings.Inputs.Name}
        />
        <span>{Strings.Inputs.Observation}</span>
        <Input
          disabled={putData.loading}
          iconRight="pencil"
          invert
          onBlur={(event) => detailsChangeHandler(event, 'observation')}
          onChange={(event) =>
            setCurrentMachine({
              ...(currentMachine as Machine.Data),
              observation: event.target.value,
            })
          }
          onKeyDown={(event) =>
            event.key === 'Enter' && detailsChangeHandler(event, 'observation')
          }
          value={currentMachine?.observation}
          placeholder={Strings.Inputs.Observation}
        />
      </header>
      <WarehouseContainerElement>
        <Table
          data={
            getData.payload?.data.channels
              ? Object.values(getData.payload?.data.channels)
              : []
          }
          loading={getData.loading}
          config={warehouseTableConfig}
        >
          <TableColumn
            key="channel"
            name={Strings.Table.ColumnTitles.Channel}
            minwidth={window.innerWidth > 600 ? '100px' : '70px'}
          >
            {({ channel, block }: MachineWarehouse.Data) => (
              <WarehouseTableChannelCell>
                {channel}
                {block && (
                  <Badge
                    className="__blocked-badge"
                    text={Strings.Messages.Blocked}
                    color="danger"
                  />
                )}
              </WarehouseTableChannelCell>
            )}
          </TableColumn>
          <TableColumn
            key="product"
            name={Strings.Table.ColumnTitles.Product}
            flex={1}
          >
            {({ product, quantity }: MachineWarehouse.Data) => (
              <WarehouseTableProductCell>
                <div className="ui-grid-horizontal">
                  <ImageAvatar
                    src={
                      product.pathImage
                        ? FilePaths.ProductImage(product.pathImage)
                        : undefined
                    }
                  />
                  <div className="ui-grid-vertical">
                    <h2>{product.name}</h2>
                    <span>
                      {Strings.Table.ProductCell.Quantity}: {quantity}
                    </span>
                  </div>
                </div>
              </WarehouseTableProductCell>
            )}
          </TableColumn>
        </Table>
      </WarehouseContainerElement>
    </MachineDetailsModalElement>
  );
}
