import { Icon } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { ReportFilterElement } from './style';

type ReportFilterType = string | number | string[] | boolean;

export interface ReportFilterProps<T extends ReportFilterType> {
  type?: string;
  value?: T;
  icon: string;
  text: [string, string?];
  active?: boolean;
  enableDelete?: boolean;
  onSelect?: (value?: T) => void;
  onDelete?: (value?: T) => void;
}

export default function ReportCard<T extends ReportFilterType>({
  value,
  icon,
  text,
  active,
  onSelect,
  enableDelete,
  onDelete,
}: ReportFilterProps<T>): JSX.Element {
  const clickHandler = (): void => {
    if (onSelect) onSelect(value);
  };

  const deleteHandler = (): void => {
    if (onDelete) onDelete(value);
  };

  return (
    <ReportFilterElement active={!!active} onClick={clickHandler}>
      {text[1] && (
        <div className="__text-container">
          <span>{text[1]}</span>
        </div>
      )}
      <Icon width="40px" height="50px" name={icon} invert={active} />
      <div className="__text-container">
        <span>{text[0]}</span>
      </div>
      {enableDelete && (
        <div className="__btn-remove" onClick={deleteHandler}>
          <Icon name="trash" width="20px" height="20px" invert={!active} />
        </div>
      )}
    </ReportFilterElement>
  );
}
