import { User, Addon } from '.';

export type Auth = {
  exp: number;
  iat: number;
  id: string;
  permission: User.Permission[];
  addons: Addon.Addon[];
  type: string;
  username: string;
  customer: {
    id: string;
    fancyName: string;
    logo?: string;
  };
};

export const AccessType = {
  Fired: [],
  Employee: [User.Permission.WITHDRAW],
  Supplier: [User.Permission.WITHDRAW, User.Permission.SUPPLY],
  Supervisor: [
    User.Permission.WITHDRAW,
    User.Permission.APPROVE,
    User.Permission.WEB_SUPPLY,
  ],
  CustomerAdminDefault: [
    User.Permission.WITHDRAW,
    User.Permission.RELEASE_LICENSE,
    User.Permission.WEB_ADMIN,
  ],
  CustomerAdminGod: [
    User.Permission.WITHDRAW,
    User.Permission.SUPPLY,
    User.Permission.APPROVE,
    User.Permission.RELEASE_LICENSE,
    User.Permission.WEB_SUPPLY,
    User.Permission.WEB_ADMIN,
  ],
  Admin: [
    User.Permission.WITHDRAW,
    User.Permission.SUPPLY,
    User.Permission.APPROVE,
    User.Permission.RELEASE_LICENSE,
    User.Permission.ADMIN,
  ],
};

export type CustomerAccessType = {
  name: string;
  permissions: User.Permission[];
};

export const CustomerAccessTypes: CustomerAccessType[] = [
  { name: 'Demitido', permissions: AccessType.Fired },
  { name: 'Funcionário', permissions: AccessType.Employee },
  { name: 'Abastecedor', permissions: AccessType.Supplier },
  { name: 'Supervisor', permissions: AccessType.Supervisor },
  {
    name: 'Administrador Padrão',
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Administrador Superior',
    permissions: AccessType.CustomerAdminGod,
  },
];

export const findCustomerAccessType = (
  permissions: User.Permission[]
): CustomerAccessType => {
  for (let i = 0; i < CustomerAccessTypes.length; i++) {
    if (CustomerAccessTypes[i].permissions.length !== permissions.length)
      continue;
    if (
      permissions.every(
        (element, index) =>
          element === CustomerAccessTypes[i].permissions[index]
      )
    )
      return CustomerAccessTypes[i];
  }
  return { name: 'Desconhecido', permissions };
};
