import useRest from './use-rest';
import { useEffect, useState } from 'react';
import { cloneObject } from '../utils';
import { DatabaseDocument, ErrorResponse } from '../domain/models';

enum ErrorCodes {
  Put,
}

interface RequestError {
  code: ErrorCodes;
  error: ErrorResponse;
}

export interface RestUpdate<T extends DatabaseDocument.Data> {
  loading: boolean;
  requestError: RequestError | undefined;
  currentState: [T, React.Dispatch<T>, (value: Partial<T>) => void];
}

export interface RestUpdateProps<T extends DatabaseDocument.Data> {
  endpoint: (id: string) => string;
  state: [T, React.Dispatch<T>];
}

const { usePut } = useRest();

export default function useRestUpdate<T extends DatabaseDocument.Data>({
  endpoint,
  state: [current, setCurrent],
}: RestUpdateProps<T>): RestUpdate<T> {
  const [putData, put] = usePut({ loading: false });
  const [previous, setPrevious] = useState<T>(cloneObject(current));
  const [requestError, setRequestError] = useState<RequestError | undefined>();

  const updateSuccessHandler = (data: T): void => {
    if (current?._id !== data._id) {
      setPrevious(cloneObject(current));
    } else {
      setCurrent(data);
      setPrevious(cloneObject(data));
      setRequestError(undefined);
    }
  };

  const updateErrorHandler = (error: ErrorResponse): void => {
    setCurrent(cloneObject(previous));
    setRequestError({ code: ErrorCodes.Put, error });
  };

  const updateValue = (value: Partial<T>): void => {
    if (!current?._id) throw new Error('Invalid current updating object');

    setCurrent({ ...current, ...value });
    put(endpoint(current._id), value);
  };

  useEffect(() => {
    if (putData.payload) {
      updateSuccessHandler(putData.payload.data);
    } else if (putData.error) {
      updateErrorHandler(putData.error);
    }
  }, [putData]);

  useEffect(() => {
    if (previous?._id !== current?._id && !putData.loading) {
      setPrevious(cloneObject(current));
    }
  }, [current]);

  return {
    loading: putData.loading,
    currentState: [current, setCurrent, updateValue],
    requestError,
  };
}
