import { Trigger } from '@keymax-dev/smartepi-sdk';

export type TriggerReceiverType = {
  name: string;
  type: Trigger.ReceiverType;
};

export const TriggerReceiverTypes: TriggerReceiverType[] = [
  { name: 'Email', type: Trigger.ReceiverType.EMAIL },
  // { name: 'SMS', type: Trigger.ReceiverType.SMS },
  // { name: 'Whatsapp', type: Trigger.ReceiverType.WHATSAPP },
];

export const findTriggerReceiverType = (
  type: Trigger.ReceiverType
): TriggerReceiverType => {
  const triggerReceiverType = TriggerReceiverTypes.find(
    (trigger) => trigger.type === type
  );

  if (triggerReceiverType) {
    return triggerReceiverType;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { name: 'Desconhecido', type: null as any };
};
