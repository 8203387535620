import { ScrollableContainer, Tab, useToast } from '@keymax-dev/smartepi-ui';
import { Trigger, TriggerEvent } from '@keymax-dev/smartepi-sdk';
import React, { useEffect } from 'react';
import { getErrorMessage } from '../../../../utils/error-messages';
import { EndPoints } from '../../../../utils/constants';
import useRest from '../../../../hooks/use-rest';
import { AxiosError } from 'axios';

export function EventsTab({
  ...props
}: Omit<React.ComponentProps<typeof Tab>, 'children'> & {
  trigger: Trigger.Data;
}): JSX.Element {
  const [loaging] = React.useState(false);
  const [error] = React.useState<AxiosError>();
  const [events, setEvents] = React.useState<
    TriggerEvent.Data<typeof props.trigger.type>[]
  >();

  const { useGet } = useRest();
  const [getData, get] = useGet({ loading: loaging, error: error });

  const errorToast = useToast(<span />, { color: 'danger' });

  useEffect(() => {
    setEvents(undefined);
    get(`${EndPoints.TriggerEvents}?triggerId=${props.trigger._id}&`);
  }, [props.trigger._id]);

  useEffect(() => {
    if (getData.payload) {
      setEvents(
        getData.payload.data.docs as TriggerEvent.Data<
          typeof props.trigger.type
        >[]
      );
    } else if (getData.error) {
      errorToast.setContent(getErrorMessage(getData.error));
      setTimeout(() => errorToast.open());
    }
  }, [getData]);

  return (
    <Tab {...props}>
      <ScrollableContainer flexDirection="column" className="__scrollable">
        <div className="flex flex-col w-full h-full divide-y">
          {events?.map((event) => {
            if (props.trigger.type === Trigger.Type.PRODUCT_EXPIRED) {
              const data = event.data as TriggerEvent.ProductExpiredData;

              return (
                <div
                  key={event._id}
                  className="flex w-full p-4 gap-2 shadow-md"
                >
                  <div className="flex flex-col w-full">
                    <span>
                      Horário:{' '}
                      {new Date(event.createdAt).toLocaleString('pt-BR', {
                        timeZone: 'America/Sao_Paulo',
                      })}
                    </span>
                    <span>Produto: {data.productName}</span>
                    <span>
                      Funcionário: {data.userIdentification} - {data.userName}
                    </span>
                    <span>
                      Última retirada:{' '}
                      {new Date(data.lastWithdrawalDeliveryTime).toLocaleString(
                        'pt-BR',
                        {
                          timeZone: 'America/Sao_Paulo',
                        }
                      )}
                    </span>
                  </div>
                </div>
              );
            }

            if (props.trigger.type === Trigger.Type.WITHDRAWAL_ERROR) {
              const data = event.data as TriggerEvent.WithdrawalErrorData;

              return (
                <div
                  key={event._id}
                  className="flex w-full p-4 gap-2 shadow-md"
                >
                  <div className="flex flex-col w-full">
                    <span>
                      Horário:{' '}
                      {new Date(event.createdAt).toLocaleString('pt-BR', {
                        timeZone: 'America/Sao_Paulo',
                      })}
                    </span>
                    <span>
                      Funcionário: {data.userIdentification} - {data.userName}
                    </span>
                    <span>
                      Horário da dispensa:{' '}
                      {new Date(data.withdrawalDeliveryTime).toLocaleString(
                        'pt-BR',
                        {
                          timeZone: 'America/Sao_Paulo',
                        }
                      )}
                    </span>
                    <span>
                      Produto: {data.productName} - {data.productSizeName}
                    </span>
                    <span>Máquina: {data.machineName}</span>
                    <span>Canal: {data.machineChannel}</span>
                    <span>Motivo: {data.error}</span>
                  </div>
                </div>
              );
            }

            if (props.trigger.type === Trigger.Type.MACHINE_WAREHOUSE_BLOCK) {
              const data = event.data as TriggerEvent.MachineWarehouseBlockData;

              return (
                <div
                  key={event._id}
                  className="flex w-full p-4 gap-2 shadow-md"
                >
                  <div className="flex flex-col w-full">
                    <span>
                      Horário:{' '}
                      {new Date(event.createdAt).toLocaleString('pt-BR', {
                        timeZone: 'America/Sao_Paulo',
                      })}
                    </span>
                    <span>Máquina: {data.machineName}</span>
                    <span>Canal: {data.channel}</span>
                    <span>
                      Produto: {data.productName} - {data.productSizeName}
                    </span>
                  </div>
                </div>
              );
            }

            if (props.trigger.type === Trigger.Type.MACHINE_WAREHOUSE_EMPTY) {
              const data = event.data as TriggerEvent.MachineWarehouseEmptyData;

              return (
                <div
                  key={event._id}
                  className="flex w-full p-4 gap-2 shadow-md"
                >
                  <div className="flex flex-col w-full">
                    <span>
                      Horário:{' '}
                      {new Date(event.createdAt).toLocaleString('pt-BR', {
                        timeZone: 'America/Sao_Paulo',
                      })}
                    </span>
                    <span>Máquina: {data.machineName}</span>
                    <span>Canal: {data.channel}</span>
                    <span>
                      Produto: {data.productName} - {data.productSizeName}
                    </span>
                  </div>
                </div>
              );
            }

            if (props.trigger.type === Trigger.Type.MACHINE_OFFLINE) {
              const data = event.data as TriggerEvent.MachineOfflineData;

              return (
                <div
                  key={event._id}
                  className="flex w-full p-4 gap-2 shadow-md"
                >
                  <div className="flex flex-col w-full">
                    <span>
                      Horário:{' '}
                      {new Date(event.createdAt).toLocaleString('pt-BR', {
                        timeZone: 'America/Sao_Paulo',
                      })}
                    </span>
                    <span>Máquina: {data.machineName}</span>
                    <span>
                      Última vez online:{' '}
                      {new Date(data.lastConnection).toLocaleString('pt-BR', {
                        timeZone: 'America/Sao_Paulo',
                      })}
                    </span>
                  </div>
                </div>
              );
            }

            return <div key={event._id}>Not implemented</div>;
          })}
        </div>
      </ScrollableContainer>
    </Tab>
  );
}
