import { CardBase, Icon } from '@keymax-dev/smartepi-ui';
import { HTMLMotionProps } from 'framer-motion';
import React from 'react';
import { License } from '../../domain/models';

const Strings = {
  Status: {
    Online: 'Online',
    Offline: 'Offline',
    Maintenance: 'Manutenção',
  },
};

interface LicenseCardProps extends HTMLMotionProps<'div'> {
  license: License.Data;
}

const CARD_SIZE = '280px';

export default function LicenseCard({
  license,
  ...props
}: LicenseCardProps): JSX.Element {
  return (
    <CardBase
      width={CARD_SIZE}
      height={'260px'}
      {...props}
      className={`__card ${props.className || ''}`}
    >
      <Icon name="desktop" height="100px" width="100px" invert />
      <h2>{license.name}</h2>
      <span className="__description">{license.host}</span>
      <footer>
        {/* {license.licenseStatus === LicenseStatus.ACTIVE &&
                    <Badge text={Strings.Status.Online} color="success" />
                }
                {license.licenseStatus === LicenseStatus.INACTIVE &&
                    <Badge text={Strings.Status.Offline} color="primary" />
                } */}
      </footer>
    </CardBase>
  );
}
