import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const UploadFileModalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-width: 40vw;
  padding: 15px;

  span {
    color: ${() => getGlobalTheme().colors.primary.principal}64;
  }

  h2 {
    font-size: ${() => getGlobalTheme().font.h2.fontSize};
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    margin: 30px 0;
    min-width: 100%;
  }

  h1 {
    font-size: ${() => getGlobalTheme().font.h1.fontSize};
    font-weight: ${() => getGlobalTheme().font.h1.fontWeight};
  }

  footer {
    margin-top: 5px;
    min-width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  .__form-container {
    width: 100%;
    flex: 1;
    width: 50vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    video,
    object {
      height: 30vh;
      max-width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;
