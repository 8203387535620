import { Badge, getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const DeletedBadge = styled(Badge)`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
`;

export const DeletedCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${() => getGlobalTheme().colors.primary.principal}30;
  pointer-events: none;
`;

export const LoadingCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
