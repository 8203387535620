import {
  Button,
  Icons,
  Input,
  ModalController,
  ScrollableContainer,
  Select,
  useToast,
  Validators,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../../components/page-title';
import { Trigger } from '../../../../domain/models';

import { EmployeeChangePasswordModalElement } from './style';
import {
  TriggerReceiverTypes,
  TriggerReceiverType,
  findTriggerReceiverType,
} from '../../../../utils/triggers';
import { RestUpdate } from '../../../../hooks/use-rest-update';
import useRest from '../../../../hooks/use-rest';
import { AxiosError } from 'axios';
import { EndPoints } from '../../../../utils/constants';
import { getErrorMessage } from '../../../../utils/error-messages';

const Strings = {
  Buttons: {
    Add: 'Confirmar',
  },
  Inputs: {
    Type: 'Tipo',
    Value: 'Valor',
  },
  Messages: {
    RegisterSuccess: 'Recebedores atualizados com successo!',
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
  },
  Title: 'Atualizar Recebedores',
};

interface ChangeTriggerReceiversProps {
  triggerId: string;
  controller?: ModalController;
  restUpdate: RestUpdate<Trigger.Data>;
}

export default function ChangeTriggerReceiversModal({
  controller,
  restUpdate,
  triggerId,
}: ChangeTriggerReceiversProps): JSX.Element {
  const [loading] = React.useState(false);
  const [error] = React.useState<AxiosError>();
  const [trigger, setTrigger, updateTriggerValue] = restUpdate.currentState;
  const [receivers, setReceivers] = useState<Trigger.Receiver[]>(
    trigger.receivers || []
  );

  const { useGet } = useRest();
  const [getData, get] = useGet({ loading: loading, error: error });

  const errorToast = useToast(<span />, { color: 'danger' });

  useEffect(() => {
    setReceivers([]);
    get(`${EndPoints.Trigger(trigger._id)}`);
  }, [triggerId]);

  useEffect(() => {
    if (getData.payload) {
      setReceivers(getData.payload.data.receivers as Trigger.Data['receivers']);
    } else if (getData.error) {
      errorToast.setContent(getErrorMessage(getData.error));
      setTimeout(() => errorToast.open());
    }
  }, [getData]);

  function validateReceivers(): [boolean, string[]] {
    const errors: string[] = [];

    receivers.forEach((receiver, index) => {
      const emailValidator = new Validators.Email();

      if (!receiver.type) errors.push(`Recebedor ${index + 1} sem tipo.`);
      if (!receiver.value) errors.push(`Recebedor ${index + 1} sem valor.`);
      if (receiver.type === 'email' && !emailValidator.validate(receiver.value))
        errors.push(`Recebedor ${index + 1} com email inválido.`);
    });

    if (errors.length) {
      return [false, errors];
    }

    return [true, []];
  }

  const confirmHandler = (): void => {
    const [pass, errors] = validateReceivers();

    if (pass) {
      updateTriggerValue({ receivers: receivers });
      setTrigger({ ...trigger, receivers: receivers });

      //reload page
      // window.location.reload();

      if (controller) {
        controller.close();
      }
    } else {
      errorToast.setContent(errors[0]);
      errorToast.open();
    }
  };

  useEffect(() => {
    if (restUpdate.requestError) {
      errorToast.setContent(Strings.Messages.UnknownError);
      errorToast.open();
    }
  }, [restUpdate.requestError]);

  return (
    <EmployeeChangePasswordModalElement>
      <PageTitle>{Strings.Title}</PageTitle>
      <div
        style={{
          height: '70vh',
          width: '100%',
          position: 'relative',
        }}
      >
        <ScrollableContainer flexDirection="column">
          <div className="flex flex-col w-full gap-4">
            {receivers.map((receiver, index) => (
              <div
                key={index}
                className="flex flex-col w-full items-center p-4"
              >
                <div className="flex flex-row justify-center items-center gap-4">
                  <h1>Recebedor {index + 1}</h1>
                  <button
                    className="flex justify-center items-center gap-2"
                    style={{ fill: 'red' }}
                    onClick={(): void =>
                      setReceivers([
                        ...receivers.slice(0, index),
                        ...receivers.slice(index + 1),
                      ])
                    }
                  >
                    {Icons['trash']}
                  </button>
                </div>
                <Select<TriggerReceiverType>
                  data={TriggerReceiverTypes}
                  dataKey="name"
                  onSelect={(event, value): void =>
                    setReceivers([
                      ...receivers.slice(0, index),
                      { ...receiver, type: value?.type },
                      ...receivers.slice(index + 1),
                    ])
                  }
                  placeholder={Strings.Inputs.Type}
                  value={findTriggerReceiverType(receiver.type)}
                />
                <Input
                  disabled={restUpdate.loading}
                  onChange={(event) =>
                    setReceivers([
                      ...receivers.slice(0, index),
                      { ...receiver, value: event.target.value },
                      ...receivers.slice(index + 1),
                    ])
                  }
                  value={receiver.value}
                  placeholder={Strings.Inputs.Value}
                />
              </div>
            ))}
          </div>
          {receivers.length < 5 && (
            <button
              className="flex justify-center items-center bg-black rounded-full"
              onClick={(): void =>
                setReceivers([
                  ...receivers,
                  { type: TriggerReceiverTypes[0].type, value: '' },
                ])
              }
              style={{
                fill: 'white',
                width: 40,
                height: 40,
                maxWidth: 40,
                maxHeight: 40,
                margin: '12px',
              }}
            >
              {Icons['plus']}
            </button>
          )}
          <Button
            text={Strings.Buttons.Add}
            disabled={restUpdate.loading}
            color="success"
            onClick={confirmHandler}
          />
        </ScrollableContainer>
      </div>
    </EmployeeChangePasswordModalElement>
  );
}
