import {
  ScrollableContainer,
  Select,
  Tab,
  useToast,
} from '@keymax-dev/smartepi-ui';
import { Trigger } from '@keymax-dev/smartepi-sdk';
import React, { useEffect } from 'react';
import { WithActiveDetailsContentProps } from '../../../../components';
import { getErrorMessage } from '../../../../utils/error-messages';
import {
  findTriggerStatus,
  findTriggerType,
  TriggerStatus,
  TriggerStatuss,
  TriggerType,
  TriggerTypes,
} from '../../../../utils/triggers';

const Strings = {
  Labels: {
    Type: 'Tipo',
    Status: 'Status',
  },
};

export function DetailsTab({
  restUpdate,
  ...props
}: Omit<React.ComponentProps<typeof Tab>, 'children'> &
  WithActiveDetailsContentProps<Trigger.Data>): JSX.Element {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentState: [trigger, setTrigger, updateTriggerValue],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loading: updateLoading,
    requestError,
  } = restUpdate;

  const errorToast = useToast(<span />, { color: 'danger' });

  useEffect(() => {
    if (requestError) {
      errorToast.setContent(getErrorMessage(requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [requestError]);

  return (
    <Tab {...props}>
      <ScrollableContainer flexDirection="column" className="__scrollable">
        {/* <Select<TriggerType>
          data={TriggerTypes}
          dataKey="name"
          onSelect={(event, value): void =>
            updateTriggerValue({ type: value?.type })
          }
          placeholder={Strings.Labels.Type}
          value={findTriggerType(trigger.type)}
        /> */}

        <Select<TriggerStatus>
          data={TriggerStatuss}
          dataKey="name"
          onSelect={(event, value): void =>
            updateTriggerValue({ status: value?.status })
          }
          placeholder={Strings.Labels.Status}
          value={findTriggerStatus(trigger.status)}
        />
      </ScrollableContainer>
    </Tab>
  );
}
