import styled from 'styled-components';
import { motion } from 'framer-motion';
import { getGlobalTheme } from '@keymax-dev/smartepi-ui';

export const ProductPageElement = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  .__grid-card-container {
    flex: 1;
    width: 100%;
    position: relative;
    .__grid-card {
      justify-content: space-around;
      align-items: flex-start;
      flex-wrap: wrap;

      h2 {
        -webkit-line-clamp: 2;
      }

      .__description {
        font-size: calc(${() => getGlobalTheme().font.h2.fontSize} / 2);
        -webkit-line-clamp: 3;
      }

      .__card {
        margin: 20px;

        &:active {
          transform: scale(0.95);
        }
      }
    }
  }

  .ui-modal-btn-close {
    path {
      fill: ${() => getGlobalTheme().colors.primary.contrast};
    }
  }
`;

export const ProductsMessageContainerElement = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

interface ProductDataCellElementProps {
  active: boolean;
}

export const ProductDataCellElement = styled.div<ProductDataCellElementProps>`
  h2 {
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.9);
    font-weight: ${({ active }) =>
      active ? '700' : getGlobalTheme().font.h2.fontWeight};
    margin: 0;
  }

  span {
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.6);
    color: ${() => getGlobalTheme().colors.primary.principal}${({ active }) => (active ? '' : '64')};
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
  }

  @media screen and (max-width: 600px) {
    h2 {
      font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.7);
    }
  }
`;
