import { Button, ImageAvatar, useModal } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth, AccessType, PagePath, User, Addon } from '../../domain/models';
import { ReduxStore } from '../../redux';
import AuthActions from '../../redux/actions/auth-actions';
import ViewActions from '../../redux/actions/view-actions';
import { verifyAddons, verifyPermissions } from '../../utils';
import { FilePaths, RouterPaths } from '../../utils/constants';
import SettingsModal from '../settings-modal';
import { SidebarContentElement, SidebarLinkElement } from './style';

const USER_ACCESS: PagePath[] = [
  // { name: 'Dashboard', path: RouterPaths.Dashboard, permissions: [] },
  {
    name: 'Produtos',
    path: RouterPaths.Products,
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Licenças',
    path: RouterPaths.Licenses,
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Máquinas',
    path: RouterPaths.Machines,
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Funcionários',
    path: RouterPaths.Employees,
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Grupos',
    path: RouterPaths.Groups,
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Setores',
    path: RouterPaths.Sectors,
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Funções',
    path: RouterPaths.Roles,
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Centros de Custo',
    path: RouterPaths.CostCenters,
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Clientes',
    path: RouterPaths.Customers,
    permissions: AccessType.Admin,
  },
  {
    name: 'Relatórios',
    path: RouterPaths.Reports,
    permissions: AccessType.CustomerAdminDefault,
  },
  {
    name: 'Ficha de EPI',
    path: RouterPaths.Sheet,
    permissions: AccessType.CustomerAdminDefault,
  },
  // {
  //   name: 'Aprovações',
  //   path: RouterPaths.Approval,
  //   permissions: AccessType.Supervisor,
  // },
  {
    name: 'Alertas',
    path: RouterPaths.Triggers,
    permissions: AccessType.CustomerAdminDefault,
    addon: Addon.Addon.TRIGGER,
  },
];

export default function SidebarContent(): JSX.Element {
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector<ReduxStore, Partial<Auth> | undefined>(
    (state) => state.auth
  );
  const history = useHistory();

  const settingsModal = useModal(
    <SettingsModal
      avatarSrc={
        auth?.customer?.logo
          ? FilePaths.Customer(auth.customer.logo)
          : undefined
      }
    />
  );

  const sidebarLinkClickHandler = (): void => {
    dispatch(ViewActions.sidebar.close());
  };

  const logoutButtonHandler = (): void => {
    dispatch(AuthActions.clearAuth());
    history.replace('/');
  };

  const settingsButtonHandler = (): void => {
    settingsModal.open();
  };

  return (
    <SidebarContentElement>
      <div className="__avatar-container">
        <ImageAvatar
          size="17vh"
          src={
            auth?.customer?.logo
              ? FilePaths.Customer(auth.customer.logo)
              : undefined
          }
        />
        <h3>{auth?.customer?.fancyName}</h3>
        <h2>{auth?.username}</h2>
      </div>
      <nav className="__nav-container">
        {USER_ACCESS.filter((element) => {
          console.log(auth);
          return (
            verifyPermissions(auth?.permission || [], element.permissions) &&
            verifyAddons(auth?.addons || [], element.addon)
          );
        }).map((element, index) => (
          <SidebarLinkElement
            key={index}
            to={element.path}
            className={
              location.pathname.includes(element.path) ? '__current-path' : ''
            }
            onClick={sidebarLinkClickHandler}
          >
            {element.name}
          </SidebarLinkElement>
        ))}
      </nav>
      <footer className="__footer-container">
        {auth?.permission?.includes(User.Permission.WEB_ADMIN) && (
          <Button
            buttonType="icon"
            text="Configurar"
            icon="cog"
            invert
            onClick={settingsButtonHandler}
          />
        )}
        <Button
          buttonType="icon"
          text="Sair"
          icon="exit"
          invert
          onClick={logoutButtonHandler}
        />
      </footer>
    </SidebarContentElement>
  );
}
