/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Animations,
  Button,
  Select,
  Spinners,
  useForm,
  useToast,
  Validators,
} from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../components/page-title';
import useRest from '../../../hooks/use-rest';
import { Trigger, ErrorResponse } from '../../../domain/models';
import { EndPoints } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import { AddTriggerModalElement } from './style';
import {
  findTriggerType,
  TriggerType,
  TriggerTypes,
} from '../../../utils/triggers';

const Strings = {
  Buttons: {
    Add: 'Adicionar',
  },
  Messages: {
    RegisterLoading: 'Adicionando alerta...',
    RegisterSuccess: 'Alerta adicionado com successo!',
    InvalidForm: 'Os dados fornecidos são inválidos!',
  },
  Title: 'Adicionar Alerta',
};

enum Content {
  Form,
  Loading,
}

const { usePost } = useRest();

interface AddTriggerModalProps {
  controller?: any;
}

type FormPrototype = {
  name: string;
  type: Trigger.Type;
};

export default function AddTriggerModal({
  controller,
}: AddTriggerModalProps): JSX.Element {
  const [data, post] = usePost({ loading: false });
  const errorToast = useToast(<span />, { color: 'danger' });
  const successToast = useToast(
    <span>{Strings.Messages.RegisterSuccess}</span>,
    { color: 'success' }
  );

  const [form, getErrors, getValues] = useForm<FormPrototype>(
    [
      {
        key: 'name',
        validators: [new Validators.MinLength(4)],
      },
      {
        key: 'type',
        validators: [new Validators.Required()],
      },
    ],
    'addTrigger'
  );

  const [type, setType] = useState<Trigger.Type>(Trigger.Type.PRODUCT_EXPIRED);

  const contentAnimation = useAnimation();
  const [content, _setContent] = useState<Content>(Content.Form);
  const setContent = (value: Content): void => {
    contentAnimation
      .start(Animations.FadeOut)
      .then(() => Promise.resolve(_setContent(value)))
      .then(() => contentAnimation.start(Animations.FadeIn));
  };

  const requestSuccessHandler = (data: Trigger.Data): void => {
    setTimeout(() => successToast.open());
    if (controller) controller.close(data);
  };

  const requestErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  const confirmHandler = (): void => {
    if (!getErrors()) {
      const values = getValues();
      post(EndPoints.Trigger(), {
        ...values,
        type,
        receivers: [],
      });
    } else {
      errorToast.setContent(Strings.Messages.InvalidForm);
      setTimeout(() => errorToast.open());
    }
  };

  useEffect(() => {
    if (data.loading) {
      setContent(Content.Loading);
    } else {
      setContent(Content.Form);
      if (data.payload) requestSuccessHandler(data.payload.data);
      else if (data.error) requestErrorHandler(data.error);
    }
  }, [data]);

  return (
    <AddTriggerModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      {content === Content.Form && (
        <motion.div className="__form-container">
          <div className="__form-container">
            <form.name placeholder={'Nome do Alerta'} />
            <Select<TriggerType>
              data={TriggerTypes}
              dataKey="name"
              onSelect={(event, value): void => setType(value.type)}
              placeholder="Tipo"
              value={findTriggerType(type)}
            />
          </div>
          <Button
            text={Strings.Buttons.Add}
            color="success"
            onClick={confirmHandler}
          />
        </motion.div>
      )}
      {content === Content.Loading && (
        <motion.div
          className="__form-container"
          animate={contentAnimation}
          initial={{ opacity: 0 }}
        >
          <Spinners.circles width="300px" height="300px" />
          <span>{Strings.Messages.RegisterLoading}</span>
        </motion.div>
      )}
    </AddTriggerModalElement>
  );
}
