import {
  TableRowEvents,
  TableRowProps,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfirmModal from '../components/confirm-modal';
import { ActiveDetails, DatabaseDocument } from '../domain/models';
import { ReduxStore } from '../redux';
import ActiveDetailsActions from '../redux/actions/active-details-actions';
import { getErrorMessage } from '../utils/error-messages';
import useRestList, { RestList } from './use-rest-list';
import useSelectableList, { SelectableList } from './use-selectable-list';

export interface ListPage<T extends DatabaseDocument.Data> {
  activeDetails: ActiveDetails<T>;
  addItemModalCloseHandler: (reason: unknown) => void;
  deleteSelectedHandler: (title: string, message: string) => void;
  restList: RestList<T>;
  rowEvents: TableRowEvents;
  rowProps: TableRowProps;
  selectable: boolean;
  selectableList: SelectableList<T>;
  setSelectable: React.Dispatch<React.SetStateAction<boolean>>;
  tableScrollHandler: (event: React.UIEvent<HTMLTableSectionElement>) => void;
}

function useListPage<T extends DatabaseDocument.Data>(
  endpoint: string,
  detailsPath: (id?: string) => string
): ListPage<T> {
  const [selectable, setSelectable] = useState<boolean>(false);
  const activeDetails = useSelector<ReduxStore<T>, ActiveDetails<T>>(
    (state) => state.activeDetails
  );
  const confirmModal = useModal(<ConfirmModal />);
  const dispatch = useDispatch();
  const errorToast = useToast(<span />, { color: 'danger' });
  const history = useHistory();
  const restList = useRestList<T>(endpoint);
  const selectableList = useSelectableList<T>('_id');

  const deleteSelectedHandler = (title: string, message: string): void => {
    confirmModal.injectProps({ title, message });
    confirmModal.onClose((reason) => {
      if (reason === 'confirmed') {
        restList.deleteItems(selectableList.getItems());
      }
    });
    confirmModal.open();
  };

  const rowClickHandler = (event: React.SyntheticEvent, tableItem: T): void => {
    if (!selectable) {
      history.push(detailsPath(tableItem._id));
      dispatch(ActiveDetailsActions.setActiveDetails(tableItem, dispatch));
    } else {
      selectableList.toggle(tableItem);
    }
  };

  const tableScrollHandler = (
    event: React.UIEvent<HTMLTableSectionElement>
  ): void => {
    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight - 30
    ) {
      restList.nextBookmark();
    }
  };

  const addItemModalCloseHandler = (reason: unknown): void => {
    if (typeof reason === 'object') {
      const newItem = reason as T;
      restList.updateItems([newItem]);
      dispatch(ActiveDetailsActions.setActiveDetails(newItem, dispatch));
      history.push(detailsPath(newItem._id));
    }
  };

  const rowEvents: TableRowEvents = {
    onClick: rowClickHandler,
  };
  const rowProps: TableRowProps = {
    style: {
      cursor: 'pointer',
    },
  };

  useEffect(() => {
    if (activeDetails.item && restList.list.length > 0) {
      restList.updateItems([activeDetails.item]);
    }
  }, [activeDetails.item]);

  useEffect(() => {
    if (!selectable) {
      selectableList.clear();
    }
  }, [selectable]);

  useEffect(() => {
    if (restList.requestError) {
      errorToast.setContent(getErrorMessage(restList.requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [restList.requestError]);

  useEffect(() => {
    if (!history.location.state) return;
    const { toRemove } = history.location.state as {
      toRemove?: T;
    };
    if (toRemove) {
      restList.updateItems([toRemove]);
    }
  }, [history.location.state]);

  return {
    activeDetails,
    addItemModalCloseHandler,
    deleteSelectedHandler,
    restList,
    rowEvents,
    rowProps,
    selectable,
    selectableList,
    setSelectable,
    tableScrollHandler,
  };
}

export default useListPage;
