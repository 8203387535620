import {
  Input,
  Table,
  TableColumn,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  WithActiveDetails,
  WithActiveDetailsContentProps,
} from '../../../components/details-section';
import PageActions from '../../../components/page-actions';
import { CostCenter, User } from '../../../domain/models';
import { useRestItemDelete } from '../../../hooks/use-rest-item-delete';
import useRestList from '../../../hooks/use-rest-list';
import { EndPoints, RouterPaths } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import AddEmployeeCostCenterModal from './add-employee';
import { CostCenterDetailsElement } from './style';

const Strings = {
  Buttons: {
    DeleteCenter: 'Excluir Centro',
    AddEmployee: 'Adicionar Funcionário',
  },
  Inputs: {
    Employee: 'Nome do Centro de Custo',
    Search: 'Buscar funcionário...',
  },
  Table: {
    ColumnTitles: {
      Identification: 'ID',
      Employee: 'Funcionário',
    },
    Messages: {
      EmptyEmployees: 'Não há funcionários no centro de custo.',
    },
  },
  Messages: {
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar o centro de custo?',
  },
};

function CostCenterDetails(
  props: WithActiveDetailsContentProps<CostCenter.Data>
): JSX.Element {
  // Hooks
  const {
    currentState: [costCenter, setCostCenter, updateCostCenterValue],
    loading: updateLoading,
    requestError,
  } = props.restUpdate;

  const restList = useRestList<User.Data>(EndPoints.Users, {
    requestFilters: [{ filter: 'costCenter', filterValue: costCenter._id }],
  });

  const { deleteClickHandler } = useRestItemDelete({
    endpoint: EndPoints.CostCenters,
    item: costCenter,
    routerPath: RouterPaths.CostCenters,
    title: Strings.Messages.Caution,
    message: Strings.Messages.DeleteConfirmation,
  });

  const errorToast = useToast(<span />, { color: 'danger' });

  const addEmployeeModal = useModal(
    <AddEmployeeCostCenterModal costCenterId={costCenter._id} />
  );

  // Handlers
  const tableScrollHandler = (
    event: React.UIEvent<HTMLTableSectionElement>
  ): void => {
    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight - 30
    ) {
      restList.nextBookmark();
    }
  };
  const addEmployeeClickHandler = (): void => {
    addEmployeeModal.injectProps({ costCenterId: costCenter._id });
    addEmployeeModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const newUser = reason as User.Data;
        restList.updateItems([newUser]);
      }
    });
    addEmployeeModal.open();
  };

  // Page Elements
  const pageActions = [
    {
      text: Strings.Buttons.DeleteCenter,
      icon: 'trash',
      onClick: deleteClickHandler,
    },
    {
      text: Strings.Buttons.AddEmployee,
      icon: 'accountPlus',
      onClick: addEmployeeClickHandler,
    },
  ];

  useEffect(() => {
    if (requestError) {
      errorToast.setContent(getErrorMessage(requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [requestError]);

  return (
    <CostCenterDetailsElement>
      <header className="__title-header">
        <Input
          disabled={updateLoading}
          iconRight="pencil"
          invert
          onBlur={(event) =>
            updateCostCenterValue({
              name: event.currentTarget.value,
            })
          }
          onChange={(event): void =>
            setCostCenter({
              ...(costCenter as CostCenter.Data),
              name: event.currentTarget.value,
            })
          }
          onKeyDown={(event): false | void =>
            event.key === 'Enter' &&
            updateCostCenterValue({
              name: event.currentTarget.value,
            })
          }
          value={costCenter.name}
        />
        <PageActions actions={pageActions} invert />
      </header>
      <nav>
        <Input
          containerType="outline"
          iconRight="magnify"
          placeholder={Strings.Inputs.Search}
          containerProps={{ className: '__search-bar' }}
          invert
          onBlur={(event) => restList.search(event.currentTarget.value)}
          onKeyPress={(event) =>
            event.key === 'Enter' && restList.search(event.currentTarget.value)
          }
          disabled={restList.loading}
        />
      </nav>
      <Table
        data={restList.list}
        loading={restList.loading}
        config={{
          emptyMessage: Strings.Table.Messages.EmptyEmployees,
          onScroll: tableScrollHandler,
        }}
      >
        <TableColumn
          name={Strings.Table.ColumnTitles.Identification}
          key="identification"
          minwidth={window.innerWidth > 600 ? '100px' : '75px'}
        >
          {(item: User.Data): JSX.Element => <div>{item.identification}</div>}
        </TableColumn>
        <TableColumn
          name={Strings.Table.ColumnTitles.Employee}
          key="name"
          flex={1}
          minwidth="220px"
        >
          {(item: User.Data): JSX.Element => <div>{item.name}</div>}
        </TableColumn>
      </Table>
    </CostCenterDetailsElement>
  );
}

export default (): JSX.Element =>
  WithActiveDetails(CostCenterDetails, {
    id: useParams<{ id: string }>().id,
    endpoint: EndPoints.CostCenter,
    cleanRouterPath: RouterPaths.CostCenter(),
  });
