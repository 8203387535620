import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SectorsPageElement = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  span {
    flex: 1;
    margin: 0 15px;
  }
`;

interface SectorsTableRowProps {
  active: boolean;
}

export const SectorsTableRowElement = styled.div<SectorsTableRowProps>`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: ${({ active }) => (active ? '700' : '400')};
`;
