import {
  Button,
  ImageAvatar,
  Input,
  Tab,
  Table,
  TableColumn,
  useModal,
} from '@keymax-dev/smartepi-ui';
import React from 'react';
import ConfirmModal from '../../../../components/confirm-modal';
import { RestUpdate } from '../../../../hooks/use-rest-update';
import { FilePaths } from './../../../../utils/constants';
import { GroupProductCellElement } from './style';
import { Group } from '../../../../domain/models';

interface ProductTabProps {
  title: string;
  productRestUpdate: RestUpdate<Group.Data>;
}

const Strings = {
  Overflow: {
    Buttons: {
      Edit: 'Editar',
      Delete: 'Remover',
    },
  },
  Inputs: {
    Search: 'Buscar produtos...',
  },
  Table: {
    ColumnTitles: {
      Product: 'Produto',
      Pic: 'Imagem',
    },
    ProductColumn: {
      ForEach: 'a cada',
      Period: 'dia(s)',
    },
  },
  Messages: {
    Caution: 'Atenção!',
    DeleteMessage: 'Você deseja mesmo remover o produto do grupo?',
    PutFailed: 'Não foi possível atualizar a lista de produtos.',
  },
};

export function ProductTab({
  title,
  productRestUpdate: {
    loading,
    currentState: [group, setGroup, updateGroup],
  },
}: ProductTabProps): JSX.Element {
  const deleteModal = useModal(
    <ConfirmModal
      title={Strings.Messages.Caution}
      message={Strings.Messages.DeleteMessage}
    />
  );

  const deleteClickHandler = (productId: string): void => {
    deleteModal.open();
    deleteModal.onClose((reason) => {
      if (reason === 'confirmed') {
        const newGroupProducts = group.products
          .filter((item) => item.product._id !== productId)
          .map((item) => ({
            period: item.period,
            quantity: item.quantity,
            productId: item.product._id,
          }));
        updateGroup({ products: newGroupProducts as any });
      }
    });
  };

  const inputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: 'quantity' | 'period'
  ): void => {
    group.products[index][key] = event.currentTarget.valueAsNumber;
    setGroup({ ...group, products: [...group.products] });
  };

  const inputBlurHandler = (): void => {
    const newGroupProducts = group.products.map((item) => ({
      period: item.period,
      quantity: item.quantity,
      productId: item.product._id,
    }));
    updateGroup({ products: newGroupProducts as any });
    setGroup({ ...group }); // Fix name disappear bug
  };

  return (
    <Tab title={title}>
      {(group.products.length || 0) > 0 && <nav style={{ padding: '10px' }} />}
      <Table data={group.products as any}>
        <TableColumn
          key="pic"
          name={Strings.Table.ColumnTitles.Pic}
          minwidth="120px"
        >
          {(groupProduct: Group.ProductsData) => (
            <div className="ui-grid-vertical">
              <ImageAvatar
                size="60px"
                src={
                  groupProduct.product?.pathImage &&
                  FilePaths.ProductImage(groupProduct.product.pathImage)
                }
              />
              <Button
                buttonType="icon"
                icon="trash"
                iconSize="20px"
                style={{ margin: 0, padding: 0 }}
                onClick={() => deleteClickHandler(groupProduct.product._id)}
              />
            </div>
          )}
        </TableColumn>
        <TableColumn
          key="product"
          name={Strings.Table.ColumnTitles.Product}
          flex={1}
          minwidth="200px"
        >
          {(groupProduct: Group.ProductsData, index: number) => (
            <GroupProductCellElement>
              <h2>{groupProduct.product?.name}</h2>
              <div className="ui-grid-horizontal">
                <Input
                  value={groupProduct.quantity}
                  type="number"
                  min={1}
                  onChange={(event) =>
                    inputChangeHandler(event, index, 'quantity')
                  }
                  onBlur={inputBlurHandler}
                  disabled={loading}
                />
                <span>{Strings.Table.ProductColumn.ForEach}</span>
                <Input
                  value={groupProduct.period}
                  type="number"
                  min={1}
                  onChange={(event) =>
                    inputChangeHandler(event, index, 'period')
                  }
                  onBlur={inputBlurHandler}
                  disabled={loading}
                />
                <span>{Strings.Table.ProductColumn.Period}</span>
              </div>
            </GroupProductCellElement>
          )}
        </TableColumn>
      </Table>
    </Tab>
  );
}
