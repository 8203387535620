import { Animations, Button, Input, useToast } from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import { RestUpdate } from '../../../hooks/use-rest-update';
import { cloneObject } from '../../../utils';
import { ProductSizesTabElement } from './style';
import { Product, Size } from '../../../domain/models';

interface ProductDataTabProps {
  title: string;
  productRestUpdate: RestUpdate<Product.Data>;
}

const Strings = {
  Inputs: {
    Size: 'Tamanho',
    Code: 'Código',
  },
  Messages: {
    InvalidFields: 'Preencha o tamanho!',
    PutFailed: 'Não foi possível atualizar os tamanhos.',
  },
  Table: {
    Size: 'Tamanho',
    Code: 'Código',
    Actions: 'Ações',
  },
};

type Size = Pick<Size.Data, 'size' | 'code'> & { editing?: boolean };

export default function ProductSizesTab({
  title,
  productRestUpdate: {
    currentState: [product, , updateProductValue],
  },
}: ProductDataTabProps): JSX.Element {
  const [sizes, setSizes] = useState<Size[]>(
    cloneObject(product.sizes) as Size[]
  );
  const [isEditing, setIsEditing] = useState<boolean>();
  const errorToast = useToast(<span />, { color: 'danger' });

  const addProductSizeClickHandler = (): void => {
    setIsEditing(true);
    setSizes([...sizes, { size: '', code: '', editing: true }]);
  };

  const editRowClickHandler = (size: Size): void => {
    size.editing = true;
    setSizes([...sizes]);
    setIsEditing(true);
  };

  const inputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    size: Size,
    key: 'code' | 'size'
  ): void => {
    size[key] = event.currentTarget.value;
    setSizes([...sizes]);
  };

  const deleteClickHandler = (size: Size): void => {
    delete size.editing;
    setIsEditing(false);
    const filteredSizes = sizes.filter((element) => element !== size);
    setSizes(filteredSizes);
    updateProductValue({ sizes: filteredSizes });
  };

  const confirmClickHandler = (size: Size): void => {
    if (size.size) {
      delete size.editing;
      setSizes([...sizes]);
      setIsEditing(false);
      updateProductValue({ sizes: sizes });
    } else {
      errorToast.setContent(Strings.Messages.InvalidFields);
      setTimeout(() => errorToast.open());
    }
  };

  useEffect(() => {
    setSizes(cloneObject(product.sizes) as Size[]);
  }, [product]);

  return (
    <ProductSizesTabElement title={title}>
      <div className="__product-size-row __header">
        <div className="__product-size-input">{Strings.Table.Size}</div>
        <div className="__product-size-input">{Strings.Table.Code}</div>
        <div className="__product-size-actions">{Strings.Table.Actions}</div>
      </div>
      {sizes.map((size, index) => {
        if (!size.editing) {
          return (
            <div className="__product-size-row" key={index}>
              <div className="__product-size-input">{size.size}</div>
              <div className="__product-size-input">{size.code || '-'}</div>
              <div className="__product-size-actions">
                {!isEditing && (
                  <Button
                    icon="pencil"
                    buttonType="icon"
                    iconSize="20px"
                    onClick={() => editRowClickHandler(size)}
                    initial={Animations.ListItemInitial}
                    animate={Animations.ListItemIn(0)}
                  />
                )}
                {!isEditing && sizes.length > 1 && (
                  <Button
                    icon="trash"
                    buttonType="icon"
                    iconSize="20px"
                    onClick={() => deleteClickHandler(size)}
                    initial={Animations.ListItemInitial}
                    animate={Animations.ListItemIn(0)}
                  />
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div className="__product-size-row" key={index}>
              <Input
                value={size.size}
                onChange={(event) => inputChangeHandler(event, size, 'size')}
                placeholder={Strings.Inputs.Size}
              />
              <Input
                value={size.code}
                onChange={(event) => inputChangeHandler(event, size, 'code')}
                placeholder={Strings.Inputs.Code}
              />
              <div className="__product-size-actions">
                <Button
                  icon="check"
                  buttonType="icon"
                  iconSize="20px"
                  onClick={() => confirmClickHandler(size)}
                />
                {sizes.length > 1 && (
                  <Button
                    icon="trash"
                    buttonType="icon"
                    iconSize="20px"
                    onClick={() => deleteClickHandler(size)}
                  />
                )}
              </div>
            </div>
          );
        }
      })}
      {!isEditing && (
        <Button
          className="__btn-enable-add"
          buttonType="icon"
          icon="plus"
          iconSize="25px;"
          onClick={addProductSizeClickHandler}
          initial={Animations.ListItemInitial}
          animate={Animations.ListItemIn(0)}
        />
      )}
    </ProductSizesTabElement>
  );
}
