import { useEffect, useState } from 'react';
import { DatabaseDocument } from '../domain/models';
import { ErrorResponse, RequestFilter } from '../domain/models';
import useRest from './use-rest';

enum ErrorCodes {
  Get,
}

interface RequestError {
  code: ErrorCodes;
  error: ErrorResponse;
}

interface RestSearchSelectConfigurations {
  requestFilters?: RequestFilter[];
}

interface RestSelectProps<T extends DatabaseDocument.Data> {
  endpoint: string;
  object?: T;
  config?: RestSearchSelectConfigurations;
}

interface RestSelect<T extends DatabaseDocument.Data> {
  loading: boolean;
  state: [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>];
  searchedList: T[];
  searchHandler: (value: string) => void;
  requestError: RequestError | undefined;
}

const { useGet } = useRest();

export default function useRestSearchSelect<T extends DatabaseDocument.Data>({
  object,
  endpoint,
  config: { requestFilters = [] } = {},
}: RestSelectProps<T>): RestSelect<T> {
  const [getData, get] = useGet({ loading: true });
  const [value, setValue] = useState<T | undefined>(object);
  const [requestError, setRequestError] = useState<RequestError | undefined>();

  const searchHandler = (
    value: string,
    key: keyof T = 'name' as keyof T
  ): void => {
    get(endpoint, {
      search: key as string,
      searchValue: value,
      filter: requestFilters,
    });
  };

  const parseSearchedList = (): T[] => {
    if (!getData.payload?.data.docs) return [];
    return getData.payload?.data.docs;
  };

  useEffect(() => {
    if (getData.payload) {
      setRequestError(undefined);
    } else if (getData.error) {
      setRequestError({ code: ErrorCodes.Get, error: getData.error });
    }
  }, [getData]);

  useEffect(() => {
    setValue(object);
  }, [object]);

  return {
    loading: getData.loading,
    state: [value, setValue],
    searchHandler,
    requestError,
    searchedList: parseSearchedList(),
  };
}
