import { DispatchAction } from '..';
import { CLOSE_MIN_WIDTH } from '../../components/sidebar';

export type ViewActionTypes =
  | 'OPEN_SIDEBAR'
  | 'CLOSE_SIDEBAR'
  | 'ENABLE_SIDEBAR'
  | 'DISABLE_SIDEBAR'
  | 'ENABLE_MAIN_VIEW'
  | 'DISABLE_MAIN_VIEW'
  | 'ENABLE_SECTION_VIEW'
  | 'DISABLE_SECTION_VIEW';

const openSidebar = (): DispatchAction => {
  return {
    type: 'OPEN_SIDEBAR',
    payload: { view: { sidebar: { opened: true } } },
  };
};

const closeSidebar = (): DispatchAction => {
  return {
    type: 'CLOSE_SIDEBAR',
    payload: {
      view: { sidebar: { opened: window.innerWidth > CLOSE_MIN_WIDTH } },
    },
  };
};

const enableSidebar = (): DispatchAction => {
  return {
    type: 'ENABLE_SIDEBAR',
    payload: { view: { sidebar: { enabled: true } } },
  };
};

const disableSidebar = (): DispatchAction => {
  return {
    type: 'DISABLE_SIDEBAR',
    payload: { view: { sidebar: { enabled: false } } },
  };
};

const enableMainView = (): DispatchAction => {
  return {
    type: 'ENABLE_MAIN_VIEW',
    payload: { view: { main: true } },
  };
};

const disableMainView = (): DispatchAction => {
  return {
    type: 'DISABLE_MAIN_VIEW',
    payload: { view: { main: false } },
  };
};

const enableSectionView = (): DispatchAction => {
  return {
    type: 'ENABLE_SECTION_VIEW',
    payload: { view: { section: true } },
  };
};

const disableSectionView = (): DispatchAction => {
  return {
    type: 'DISABLE_SECTION_VIEW',
    payload: { view: { section: false } },
  };
};

const ViewActions = {
  sidebar: {
    open: openSidebar,
    close: closeSidebar,
    enable: enableSidebar,
    disable: disableSidebar,
  },
  main: {
    enable: enableMainView,
    disable: disableMainView,
  },
  section: {
    enable: enableSectionView,
    disable: disableSectionView,
  },
};

export default ViewActions;
