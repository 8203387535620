import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CustomerPageElement = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  span {
    flex: 1;
    margin: 0 15px;
  }

  .__name-subdata {
    opacity: 0.3;
    font-size: 0.9rem;
    margin-top: 10px;
  }

  .__content-container {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

interface GroupsTableRowProps {
  active: boolean;
}
export const CustomerDataCellElement = styled.div``;

export const IdCellElement = styled.div``;
