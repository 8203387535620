import {
  Animations,
  Button,
  ModalController,
  Select,
  Spinners,
  useToast,
} from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../../components/page-title';
import useRest from '../../../../hooks/use-rest';
import { User, ErrorResponse } from '../../../../domain/models';
import { EndPoints } from '../../../../utils/constants';
import { getErrorMessage } from '../../../../utils/error-messages';
import { AddEmployeeSectorModalElement } from './style';

enum Content {
  Form,
  Loading,
}

interface AddEmployeeSectorModalProps {
  controller?: ModalController;
  sectorId: string;
}

const { usePut, useGet } = useRest();

const Strings = {
  Buttons: {
    Add: 'Adicionar',
  },
  Inputs: {
    Employee: 'Funcionário',
  },
  Messages: {
    RegisterLoading: 'Adicionando funcionário...',
    RegisterSuccess: 'Funcionário adicionado com sucesso!',
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    InvalidForm: 'Os dados fornecidos são inválidos!',
    UserNotSelected: 'Selecione um funcionário para adicionar',
  },
  Title: 'Adicionar Funcionário à Função',
};

export default function AddEmployeeSectorModal({
  controller,
  sectorId,
}: AddEmployeeSectorModalProps): JSX.Element {
  const [putData, put] = usePut({ loading: false });
  const [getData, get] = useGet({ loading: false });
  const errorToast = useToast(<span />, { color: 'danger' });
  const successToast = useToast(
    <span>{Strings.Messages.RegisterSuccess}</span>,
    { color: 'success' }
  );
  const [selected, setSelected] = useState<User.Data>();

  const contentAnimation = useAnimation();
  const [content, _setContent] = useState<Content>(Content.Form);
  const setContent = (value: Content): void => {
    contentAnimation
      .start(Animations.FadeOut)
      .then(() => Promise.resolve(_setContent(value)))
      .then(() => contentAnimation.start(Animations.FadeIn));
  };

  const requestSuccessHandler = (data: User.Data): void => {
    setTimeout(() => successToast.open());
    if (controller) controller.close(data);
  };

  const requestErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  const confirmHandler = (): void => {
    if (selected) {
      put(EndPoints.User(selected._id), { sectorId });
    } else {
      errorToast.setContent(Strings.Messages.UserNotSelected);
      setTimeout(() => errorToast.open());
    }
  };

  useEffect(() => {
    if (putData.loading) {
      setContent(Content.Loading);
    } else {
      setContent(Content.Form);
      if (putData.payload) requestSuccessHandler(putData.payload.data);
      else if (putData.error) requestErrorHandler(putData.error);
    }
  }, [putData]);

  return (
    <AddEmployeeSectorModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      {content === Content.Form && (
        <motion.div className="__form-container">
          <div className="__form-container">
            <Select<User.Data>
              data={getData.payload?.data.docs || []}
              dataKey="name"
              loading={getData.loading}
              onSearch={(value): void =>
                get(EndPoints.Users, {
                  search: 'name',
                  searchValue: value,
                  filter: 'sector',
                  filterValue: sectorId,
                  contain: false,
                })
              }
              onSelect={(event, value): void => setSelected(value)}
              placeholder={Strings.Inputs.Employee}
              value={selected}
            />
          </div>
          <Button
            text={Strings.Buttons.Add}
            color="success"
            onClick={confirmHandler}
          />
        </motion.div>
      )}
      {content === Content.Loading && (
        <motion.div
          className="__form-container"
          animate={contentAnimation}
          initial={{ opacity: 0 }}
        >
          <Spinners.circles width="300px" height="300px" />
          <span>{Strings.Messages.RegisterLoading}</span>
        </motion.div>
      )}
    </AddEmployeeSectorModalElement>
  );
}
