import { User, Auth, Addon } from '../domain/models';

export const b64DecodeUnicode = (str: string): string => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

export const parseToken = (token: string): Auth => {
  const { payload, ...rest } = JSON.parse(
    b64DecodeUnicode(token.split('.')[1])
  );

  return {
    ...rest,
    ...JSON.parse(payload),
  };
};

export const verifyPermissions = (
  available: User.Permission[],
  needed: User.Permission[]
): boolean => {
  for (let i = 0; i < needed.length; i++) {
    if (available.indexOf(needed[i]) === -1) return false;
  }
  return true;
};

export const verifyAddons = (
  available: Addon.Addon[],
  needed?: Addon.Addon
): boolean => {
  if (!needed) return true;
  if (available.length === 0) return false;
  if (available.includes(needed)) return true;

  return false;
};

export const cloneObject = <T>(object: T): T => {
  return JSON.parse(JSON.stringify(object));
};

export const parseLocaleDate = (localDate: string): Date => {
  return new Date(localDate.split('/').reverse().join('/'));
};

type IdArray = {
  _id: string;
  _deleted?: boolean;
  [key: string]: any;
}[];

export const mergeArrayById = <T extends IdArray>(
  currentArray: T,
  newArray: T
): T => {
  const mappedArrays = [...currentArray, ...newArray].reduce((prev, cur) => {
    if (cur._deleted) {
      delete prev[cur._id];
      return prev;
    } else {
      return {
        ...prev,
        [cur._id]: cur,
      };
    }
  }, {} as any);

  return Object.values(mappedArrays) as T;
};

export const compareSet = <T>(
  setA: Set<T> = new Set(),
  setB: Set<T> = new Set()
): boolean => {
  if (setA.size !== setB.size) return false;
  for (const element of Array.from(setA)) if (!setB.has(element)) return false;
  return true;
};

export const formatCNPJ = (cnpj: string): string => {
  const formatted = cnpj.replace(/\D/g, '');

  if (formatted.length !== 14) return '-';

  return (
    formatted.substr(0, 2) +
    '.' +
    formatted.substr(2, 3) +
    '.' +
    formatted.substr(5, 3) +
    '/' +
    formatted.substr(8, 4) +
    '-' +
    formatted.substr(12, 2)
  );
};

export * from './user-routes';
