import { Button } from '@keymax-dev/smartepi-ui';
import { ButtonProps } from '@keymax-dev/smartepi-ui/build/components/Button';
import React from 'react';
import { PageActionsElement } from './style';

interface PageActionsProps extends React.ComponentPropsWithRef<'div'> {
  actions: ButtonProps[];
  invert?: boolean;
}

export default function PageActions({
  actions,
  invert,
  ...props
}: PageActionsProps): JSX.Element {
  return (
    <PageActionsElement {...props}>
      {actions.map((action, index) => (
        <Button
          key={index}
          invert={invert}
          buttonType="icon"
          {...action}
          className={`__page-action-button ${action.className}`}
        />
      ))}
    </PageActionsElement>
  );
}
