import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const PageTitleElement = styled(motion.h1)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${() => getGlobalTheme().font.h1.fontSize};
  font-weight: ${() => getGlobalTheme().font.h1.fontWeight};
  margin: 15px 15px 5px 15px;
  text-align: center;
  color: ${() => getGlobalTheme().colors.primary.principal};

  @media screen and (max-width: 600px) {
    font-size: calc(${() => getGlobalTheme().font.h1.fontSize} * 0.8);
    margin: 10px 10px 2px 10px;
  }
`;
