import {
  Button,
  ModalController,
  Select,
  Spinners,
  useForm,
  useToast,
  Validators,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../../../components/page-title';
import useRest from '../../../../../hooks/use-rest';
import { MachineModel, ErrorResponse } from '../../../../../domain/models';
import { MachineModels } from '../../../../../utils/constants';
import { getErrorMessage } from '../../../../../utils/error-messages';
import { AddMachineCustomerModalElement } from './style';

const Strings = {
  Buttons: {
    Add: 'Adicionar',
  },
  Inputs: {
    Name: 'Nome da Máquina',
    Observation: 'Observações',
    MachineModel: 'Modelo da Máquina',
  },
  Messages: {
    RegisterLoading: 'Cadastrando máquina...',
    RegisterSuccess: 'Máquina cadastrada com sucesso!',
    InvalidForm: 'Os dados fornecidos são inválidos!',
    InvalidMachineModel: 'Selecione um modelo de máquina',
  },
  Title: 'Adicionar Máquina',
};

enum Content {
  Form,
  Loading,
}

const { usePost } = useRest();

interface AddCustomerModalProps {
  controller?: ModalController;
  customerId: string;
}

export default function AddMachineCustomerModal({
  controller,
  customerId,
}: AddCustomerModalProps): JSX.Element {
  const [data, post] = usePost({ loading: false });
  const errorToast = useToast(<span />, { color: 'danger' });
  const successToast = useToast(
    <span>{Strings.Messages.RegisterSuccess}</span>,
    { color: 'success' }
  );

  const [form, getErrors, getValues] = useForm(
    [
      {
        key: 'name',
        validators: [new Validators.Required(), new Validators.MinLength(4)],
      },
      {
        key: 'observation',
        validators: [],
      },
    ],
    'updateCustomer'
  );
  const [machineModel, setMachineModel] = useState<MachineModel>();

  const [content, setContent] = useState<Content>(Content.Form);

  const requestSuccessHandler = (data: MachineModel): void => {
    setTimeout(() => successToast.open());
    if (controller) controller.close(data);
  };

  const requestErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  const confirmHandler = (): void => {
    if (!getErrors()) {
      if (!machineModel) {
        errorToast.setContent(Strings.Messages.InvalidMachineModel);
        setTimeout(() => errorToast.open());
        return;
      }
      post('machine', {
        ...getValues(),
        customerId,
        model: machineModel.model,
      });
    } else {
      errorToast.setContent(Strings.Messages.InvalidForm);
      setTimeout(() => errorToast.open());
    }
  };

  useEffect(() => {
    if (data.loading) {
      setContent(Content.Loading);
    } else {
      setContent(Content.Form);
      if (data.payload) requestSuccessHandler(data.payload.data);
      else if (data.error) requestErrorHandler(data.error);
    }
  }, [data]);

  return (
    <AddMachineCustomerModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      {content === Content.Form && (
        <div className="__form-container">
          <div className="__form-container">
            <form.name placeholder={Strings.Inputs.Name} />
            <form.observation placeholder={Strings.Inputs.Observation} />
            <Select<MachineModel>
              data={MachineModels}
              dataKey="name"
              onSelect={(event, value): void => setMachineModel(value)}
              placeholder={Strings.Inputs.MachineModel}
              value={machineModel}
            />
          </div>
          <Button text="Adicionar" color="success" onClick={confirmHandler} />
        </div>
      )}
      {content === Content.Loading && (
        <div className="__form-container">
          <Spinners.circles width="300px" height="300px" />
          <span>{Strings.Messages.RegisterLoading}</span>
        </div>
      )}
    </AddMachineCustomerModalElement>
  );
}
