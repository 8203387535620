import { useReducer } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { BASE_API_URL } from '../utils/constants';
import {
  ApiDataRequest as DataRequest,
  ErrorResponse,
  RequestFilter,
  SuccessResponse,
} from '../domain/models';

type ActionType = 'REQUEST' | 'SUCCESS' | 'ERROR';
type Action = DataRequest & {
  type: ActionType;
};
export type RequestParamType =
  | undefined
  | string
  | number
  | boolean
  | RequestFilter[];

export type GetHook = [
  DataRequest,
  (endpoint: string, params?: { [param: string]: RequestParamType }) => void
];

export type PutHook = [DataRequest, (endpoint: string, payload: any) => void];

export type PostHook = [DataRequest, (endpoint: string, payload: any) => void];

export type DeleteHook = [
  DataRequest,
  (endpoint: string, payload: any) => void
];

export type RestHook = {
  useGet: (initial?: DataRequest) => GetHook;
  usePut: (initial?: DataRequest) => PutHook;
  usePost: (
    initial?: DataRequest,
    extras?: Partial<AxiosRequestConfig>
  ) => PostHook;
  useDelete: (initial?: DataRequest) => DeleteHook;
};

const reducer = (state: DataRequest, action: Action): DataRequest => {
  switch (action.type) {
    case 'REQUEST':
      return { loading: true };
    case 'SUCCESS':
      return { loading: false, payload: action.payload };
    case 'ERROR':
      return { loading: false, error: action.error };
    default:
      return state;
  }
};

const findToken = (): Promise<string | null> => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) return Promise.resolve(null);
  return Promise.resolve(accessToken);
};

const createConfig = (
  accessToken: string | null,
  extras?: Partial<AxiosRequestConfig>
): AxiosRequestConfig => {
  return {
    ...extras,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...extras?.headers,
    },
  };
};

const buildParams = (params?: {
  [param: string]: RequestParamType;
}): string => {
  const pArray = Object.entries(params || {});
  if (pArray && pArray.length > 0) {
    let pString = '?';
    pArray.forEach(([param, paramValue]) => {
      if (Array.isArray(paramValue)) {
        // if is RequestFilter[]
        Object.values(paramValue).forEach(
          ({ filter, filterValue, contain }) => {
            pString += `${param}=${filter}&${param}Value=${filterValue}&`;
            if (contain !== undefined) {
              pString += `contain=${contain}&`;
            }
          }
        );
      } else {
        // if is string, number, boolean
        pString += `${param}=${paramValue}&`;
      }
    });
    return pString;
  } else {
    return '';
  }
};

const errorHandler = (
  error: ErrorResponse,
  dispatch: React.Dispatch<Action>
): void => {
  return dispatch({ type: 'ERROR', loading: false, error: error });
};

const successHandler = (
  response: SuccessResponse,
  dispatch: React.Dispatch<Action>
): void => {
  return dispatch({ type: 'SUCCESS', loading: false, payload: response });
};

const useRest = (): RestHook => {
  const useGet = (initial?: DataRequest): GetHook => {
    const [payload, dispatch] = useReducer(
      reducer,
      initial || { loading: true }
    );

    const get = (
      endpoint: string,
      params?: { [param: string]: RequestParamType }
    ): void => {
      const url = `${BASE_API_URL}/${endpoint}/${buildParams(params)}`;

      dispatch({ type: 'REQUEST', loading: true });
      findToken()
        .then((token) => axios.get(url, createConfig(token)))
        .then((res: SuccessResponse) => successHandler(res, dispatch))
        .catch((err: ErrorResponse) => errorHandler(err, dispatch));
    };

    return [payload, get];
  };

  const usePost = (
    initial?: DataRequest,
    extras?: Partial<AxiosRequestConfig>
  ): PostHook => {
    const [data, dispatch] = useReducer(reducer, initial || { loading: true });

    const post = (endpoint: string, payload: any): void => {
      dispatch({ type: 'REQUEST', loading: true });

      findToken()
        .then((token) =>
          axios.post(
            `${BASE_API_URL}/${endpoint}`,
            payload,
            createConfig(token, extras)
          )
        )
        .then((res: SuccessResponse) => successHandler(res, dispatch))
        .catch((err: ErrorResponse) => errorHandler(err, dispatch));
    };

    return [data, post];
  };

  const usePut = (initial?: DataRequest): PutHook => {
    const [data, dispatch] = useReducer(reducer, initial || { loading: true });

    const put = (endpoint: string, payload: any): void => {
      dispatch({ type: 'REQUEST', loading: true });

      findToken()
        .then((token) =>
          axios.put(`${BASE_API_URL}/${endpoint}`, payload, createConfig(token))
        )
        .then((res: SuccessResponse) => successHandler(res, dispatch))
        .catch((err: ErrorResponse) => errorHandler(err, dispatch));
    };

    return [data, put];
  };

  const useDelete = (initial?: DataRequest): PutHook => {
    const [data, dispatch] = useReducer(reducer, initial || { loading: false });

    const del = (endpoint: string, payload: any): void => {
      dispatch({ type: 'REQUEST', loading: true });

      findToken()
        .then((token) =>
          axios.delete(`${BASE_API_URL}/${endpoint}`, {
            ...createConfig(token),
            data: payload,
          })
        )
        .then((res: SuccessResponse) => successHandler(res, dispatch))
        .catch((err: ErrorResponse) => errorHandler(err, dispatch));
    };

    return [data, del];
  };

  return { useGet, usePost, usePut, useDelete };
};

export default useRest;

export { findToken, createConfig };
