import { ScrollableContainer, Tab, useToast } from '@keymax-dev/smartepi-ui';
import { Addon, Customer } from '@keymax-dev/smartepi-sdk';
import React, { useEffect } from 'react';
import { WithActiveDetailsContentProps } from '../../../../components';
import { getErrorMessage } from '../../../../utils/error-messages';

export default function SettingsTab({
  restUpdate,
  ...props
}: Omit<React.ComponentProps<typeof Tab>, 'children'> &
  WithActiveDetailsContentProps<
    Customer.Data & { addons: Addon.Addon[] }
  >): JSX.Element {
  const {
    currentState: [customer, setCustomer, updateCustomerValue],
    loading: updateLoading,
    requestError,
  } = restUpdate;

  const errorToast = useToast(<span />, { color: 'danger' });

  useEffect(() => {
    if (requestError) {
      errorToast.setContent(getErrorMessage(requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [requestError]);

  return (
    <Tab {...props}>
      <ScrollableContainer flexDirection="column">
        <div className="flex flex-col p-8 w-full gap-4">
          {/* General */}

          <h2 className="text-gray-900 font-bold">Complementos</h2>
          <label className="inline-flex items-center cursor-pointer">
            <input
              className="sr-only peer"
              type="checkbox"
              checked={Object.values(customer?.addons).includes(
                Addon.Addon.TRIGGER
              )}
              disabled={updateLoading}
              onChange={(event) => {
                const value = event.currentTarget.checked;
                if (value && !customer.addons.includes(Addon.Addon.TRIGGER)) {
                  customer.addons.push(Addon.Addon.TRIGGER);
                } else {
                  customer.addons = customer.addons.filter(
                    (addon) => addon !== Addon.Addon.TRIGGER
                  );
                }

                updateCustomerValue({ addons: customer.addons });
                setCustomer(customer);
              }}
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ring-2 ring-gray-200 peer-checked:ring-gray-700 peer-checked:bg-gray-900"></div>
            <span className="ms-3 text-sm font-medium text-gray-900">
              Alertas
            </span>
          </label>
        </div>
      </ScrollableContainer>
    </Tab>
  );
}
