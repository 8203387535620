import {
  Button,
  ImageAvatar,
  ModalController,
  ScrollableContainer,
  Spinners,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import useRest from '../../../hooks/use-rest';
import { EndPoints, FilePaths } from '../../../utils/constants';
import NotApproveReasonModal from './not-approve-reason';
import {
  ApprovalDetailsFooterElement,
  ApprovalDetailsModalElement,
} from './style';

import { Withdrawal } from '../../../domain/models';
interface ApprovalDetailsModalProps {
  controller?: ModalController;
  request?: Withdrawal.Data;
  userId: string;
}

const { usePut } = useRest();

const Strings = {
  Labels: {
    Code: 'Código',
    Size: 'Tamanho',
    Product: 'Produto',
    Employee: 'Funcionário',
    Role: 'Função',
    Sector: 'Setor',
    Reason: 'Motivo',
    Quantity: 'Quantidade',
    Identification: 'Identificação',
  },
  Buttons: {
    Accept: 'Aceitar',
    NotApprove: 'Reprovar',
  },
  Inputs: {},
  Messages: {
    PutLoading: 'Enviando...',
    ApprovedSuccess: 'A aprovação foi realizada com sucesso',
    NotApprovedSuccess: 'A reprovação foi realizada com sucesso',
    Approved: 'Pedido Aprovado',
    NotApproved: 'Pedido Reprovado',
  },
  Title: 'Pedido de Aprovação',
};

export default function ApprovalDetailsModal({
  controller,
  request,
  userId,
}: ApprovalDetailsModalProps): JSX.Element {
  // Hooks
  const [putData, put] = usePut({ loading: false });
  const successToast = useToast(<span />, { color: 'success' });
  const notApproveReasonModal = useModal(<NotApproveReasonModal />);

  // Handlers
  const confirmClickHandler = (): void => {
    const params = {
      status: Withdrawal.ApprovalStatus.APPROVED,
      userId,
    };
    put(EndPoints.Approval(request?._id), params);
  };

  const notApproveClickHandler = (): void => {
    notApproveReasonModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const params = {
          status: Withdrawal.ApprovalStatus.NOT_APPROVED,
          userId,
          reason: (reason as any).reason as any,
        };
        put(EndPoints.Approval(request?._id), params);
      }
    });
    notApproveReasonModal.open();
  };

  // Effects
  useEffect(() => {
    if (putData.payload) {
      if (
        putData.payload.data[0].approval.status ===
        Withdrawal.ApprovalStatus.APPROVED
      ) {
        successToast.setContent(Strings.Messages.ApprovedSuccess);
      } else {
        successToast.setContent(Strings.Messages.NotApprovedSuccess);
      }
      setTimeout(() => successToast.open());
      if (controller) controller.close(putData.payload.data[0]);
    }
  }, [putData]);

  return (
    <ApprovalDetailsModalElement>
      {putData.loading && (
        <motion.div className="ui-grid-vertical">
          <Spinners.circles width="200px" height="200px" />
          <span>{Strings.Messages.PutLoading}</span>
        </motion.div>
      )}

      {!putData.loading && (
        <ScrollableContainer
          flexDirection="column"
          style={{ marginTop: '40px' }}
        >
          <div className="ui-grid-vertical __grid-container">
            <div className="ui-grid-vertical __grid-child">
              <ImageAvatar
                size="10vh"
                src={
                  request?.product?.pathImage
                    ? FilePaths.ProductImage(request.product.pathImage)
                    : undefined
                }
              />
            </div>
            <div className="ui-grid-vertical __grid-divider-bottom __grid-child">
              <h2>{request?.product?.name || '-'}</h2>
              <label>{Strings.Labels.Product}</label>
              <div className="ui-grid-horizontal">
                <div className="ui-grid-vertical">
                  <span>{request?.product?.size?.code || '-'}</span>
                  <label>{Strings.Labels.Code}</label>
                </div>
                <div className="ui-grid-vertical">
                  <span>{request?.product?.size?.size || '-'}</span>
                  <label>{Strings.Labels.Size}</label>
                </div>
                <div className="ui-grid-vertical">
                  <span>{request?.quantity || '-'}</span>
                  <label>{Strings.Labels.Quantity}</label>
                </div>
              </div>
            </div>

            <div className="ui-grid-vertical __grid-divider-bottom __grid-child">
              <h2>{request?.userReceive?.user?.name || '-'}</h2>
              <label>{Strings.Labels.Employee}</label>

              <div className="ui-grid-horizontal">
                <div className="ui-grid-vertical">
                  <span>
                    {request?.userReceive?.user?.identification || '-'}
                  </span>
                  <label>{Strings.Labels.Identification}</label>
                </div>
                <div className="ui-grid-vertical">
                  <span>{request?.userReceive?.role?.name || '-'}</span>
                  <label>{Strings.Labels.Role}</label>
                </div>
                <div className="ui-grid-vertical">
                  <span>{request?.userReceive?.sector?.name || '-'}</span>
                  <label>{Strings.Labels.Sector}</label>
                </div>
              </div>
            </div>

            <div className="ui-grid-vertical __grid-child">
              <h2>{Strings.Labels.Reason}</h2>
              <span>{request?.approval?.reason || '-'}</span>
            </div>

            <ApprovalDetailsFooterElement
              status={request?.approval?.status || 0}
            >
              {request?.approval?.status ===
                Withdrawal.ApprovalStatus.PENDING && (
                <div className="ui-grid-horizontal">
                  <div className="ui-grid-vertical">
                    <Button
                      text={Strings.Buttons.NotApprove}
                      color="danger"
                      icon="close"
                      onClick={notApproveClickHandler}
                    />
                  </div>
                  <div className="ui-grid-vertical">
                    <Button
                      text={Strings.Buttons.Accept}
                      color="success"
                      icon="check"
                      onClick={confirmClickHandler}
                    />
                  </div>
                </div>
              )}
              {request?.approval?.status ===
                Withdrawal.ApprovalStatus.APPROVED && (
                <div className="ui-grid-vertical">
                  <h2>{Strings.Messages.Approved}</h2>
                </div>
              )}
              {request?.approval?.status ===
                Withdrawal.ApprovalStatus.NOT_APPROVED && (
                <div className="ui-grid-vertical">
                  <h2 className="ui-grid-horizontal">
                    {Strings.Messages.NotApproved}
                  </h2>
                  <span>{request.outInfo.reason || '-'}</span>
                  <label>{Strings.Labels.Reason}</label>
                </div>
              )}
            </ApprovalDetailsFooterElement>
          </div>
        </ScrollableContainer>
      )}
    </ApprovalDetailsModalElement>
  );
}
