import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

interface ReportFilterElementProps {
  active: boolean;
}

export const ReportFilterElement = styled(motion.div)<ReportFilterElementProps>`
  width: 100px;
  height: 100px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  color: ${({ active }) =>
    !active
      ? getGlobalTheme().colors.primary.principal
      : getGlobalTheme().colors.primary.contrast};

  border: 1px solid;
  border-color: ${({ active }) =>
    !active ? getGlobalTheme().colors.primary.principal : 'transparent'};
  border-radius: ${() => getGlobalTheme().borderRadius};

  background-color: ${({ active }) =>
    active ? getGlobalTheme().colors.primary.principal : 'transparent'};

  span {
    max-width: 100%;

    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: center;
    overflow: hidden;

    text-transform: uppercase;
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} / 2);
  }

  .__text-container {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
  }

  .__btn-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 30px;
    right: -5px;
    top: -5px;
    border-radius: 50%;
    border: 1px solid;
    border-color: ${({ active }) =>
      active ? getGlobalTheme().colors.primary.principal : 'transparent'};
    background-color: ${({ active }) =>
      !active
        ? getGlobalTheme().colors.primary.principal
        : getGlobalTheme().colors.primary.contrast};

    transition: all ${() => getGlobalTheme().transitions.fast};

    &:hover {
      box-shadow: ${() => getGlobalTheme().boxShadow.normal};
      transform: scale(1.05);
    }

    &:active {
      box-shadow: ${() => getGlobalTheme().boxShadow.active};
      transform: scale(0.98);
    }
  }

  &:hover {
    cursor: pointer;
  }
`;
