import { Customer } from '@keymax-dev/smartepi-sdk/build/models';
import {
  Button,
  ImageAvatar,
  Input,
  ModalController,
  Spinners,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useRef, useState } from 'react';
import useRest from '../../hooks/use-rest';
import { formatCNPJ } from '../../utils';
import { EndPoints, FilePaths } from '../../utils/constants';
import { getErrorMessage } from '../../utils/error-messages';
import PageTitle from '../page-title';
import { AvatarContainer, CustomerBody, SettingsModalElement } from './style';

const Strings = {
  Heading: {
    Title: 'Configurações',
    Company: 'Empresa',
    CNPJ: 'CNPJ',
    Licenses: 'Total de Licenças Disponíveis',
  },
  Buttons: {
    Cancel: 'Cancelar',
    Confirm: 'Salvar',
  },
  Messages: {
    InvalidFile: 'O arquivo selecionado é inválido',
    UploadingFile: 'Enviando arquivo...',
    UploadFailed: 'Houve um erro para enviar o arquivo.',
    PreviewFailed: 'Não foi possível exibir a pré visualização do arquivo.',
    EmptyFile: 'Nenhum arquivo encontrado.',
  },
};

interface ConfirmModalProps {
  controller?: ModalController;
  avatarSrc?: string;
}

const { usePost, useGet, usePut } = useRest();

export default function SettingsModal({
  avatarSrc,
}: ConfirmModalProps): JSX.Element {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileURL, setSelectedFileURL] = useState<string | undefined>(
    avatarSrc
  );
  const [customerData, setCustomerData] = useState<Customer.Data>();
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const errorToast = useToast(<span />, { color: 'danger' });

  const [postData, post] = usePost(
    { loading: false },
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
  const [getData, get] = useGet();
  const [putData, put] = usePut({ loading: false });

  useEffect(() => {
    if (postData.payload) {
      if (isEditing) {
        put(EndPoints.MeCustomer, {
          fancyName: customerData?.fancyName,
        });
      } else {
        get(EndPoints.RefreshToken);
      }
    }
    if (postData.error) {
      errorToast.setContent(getErrorMessage(postData.error));
      setTimeout(() => errorToast.open());
    }
  }, [postData]);

  useEffect(() => {
    if (getData.payload) {
      if (getData.payload.data._id) {
        setCustomerData(getData.payload.data);
        setSelectedFileURL(FilePaths.Customer(getData.payload.data.logo));
      } else if (getData.payload.data.accessToken) {
        localStorage.setItem('accessToken', getData.payload.data.accessToken);
        window.location.reload();
      }
    }
  }, [getData]);

  useEffect(() => {
    if (putData.payload) {
      get(EndPoints.RefreshToken);
    }
    if (putData.error) {
      errorToast.setContent(getErrorMessage(putData.error));
      setTimeout(() => errorToast.open());
    }
  }, [putData]);

  useEffect(() => {
    get(EndPoints.MeCustomer);
  }, []);

  const fileSelectHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setSelectedFileURL(URL.createObjectURL(event.target.files[0]));
    } else {
      setSelectedFile(undefined);
      setSelectedFileURL(undefined);
      errorToast.setContent(Strings.Messages.InvalidFile);
      setTimeout(() => errorToast.open());
    }
  };

  const confirmHandler = (): void => {
    if (selectedFileURL !== avatarSrc && selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      post(EndPoints.CustomerImage, formData);
    } else {
      put(EndPoints.MeCustomer, {
        fancyName: customerData?.fancyName,
      });
    }
  };

  return (
    <SettingsModalElement>
      <PageTitle>{Strings.Heading.Title}</PageTitle>
      <AvatarContainer>
        <ImageAvatar src={selectedFileURL} size="17vh" className="__avatar" />
        {!getData.loading && !postData.loading && !putData.loading && (
          <Button
            className="__avatar-edit"
            buttonType="icon"
            icon="pencil"
            iconSize="20px"
            onClick={() => inputRef.current?.click()}
            invert
          />
        )}
        <input
          ref={inputRef}
          type="file"
          onChange={fileSelectHandler}
          accept="image/png, image/jpeg"
          style={{ display: 'none' }}
        />
      </AvatarContainer>

      {!postData.loading && !putData.loading && customerData && (
        <CustomerBody>
          <label>{Strings.Heading.Company}</label>
          <Input
            iconRight="pencil"
            onChange={(event) => {
              setCustomerData({
                ...customerData,
                fancyName: event.target.value,
              });
              setIsEditing(true);
            }}
            value={customerData.fancyName}
          />
          <label>{Strings.Heading.CNPJ}</label>
          <h2>{formatCNPJ(customerData.cnpj)}</h2>
          <label>{Strings.Heading.Licenses}</label>
          <h2>{customerData.licenses || '-'}</h2>
        </CustomerBody>
      )}

      {(getData.loading ||
        postData.loading ||
        putData.loading ||
        !customerData) && <Spinners.circles width="200px" height="200px" />}

      {!getData.loading &&
        !postData.loading &&
        !putData.loading &&
        (selectedFile || isEditing) && (
          <footer>
            <Button
              text={Strings.Buttons.Confirm}
              color="success"
              onClick={confirmHandler}
            />
          </footer>
        )}
    </SettingsModalElement>
  );
}
