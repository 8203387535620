import {
  Input,
  Tab,
  Table,
  TableColumn,
  TableRowProps,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useRestList from '../../../../hooks/use-rest-list';
import { EndPoints, RouterPaths } from '../../../../utils/constants';
import LicenseInfoCell from './license-info-cell';
import LicenseStatusCell from './license-status-cell';
import { License } from '../../../../domain/models';

type LicenseTabProps = Omit<React.ComponentProps<typeof Tab>, 'children'>;

const Strings = {
  Inputs: {
    Search: 'Buscar Licença',
  },
  Table: {
    ColumnTitles: {
      Status: 'Estado',
      Info: 'Informações',
    },
  },
};

export default function LicenseTab(props: LicenseTabProps): JSX.Element {
  // Hooks
  const { id } = useParams<{ id: string }>();
  const restList = useRestList<License.Data>(EndPoints.Licenses, {
    requestFilters: [{ filter: 'customer', filterValue: id }],
  });
  const history = useHistory();

  // Handlers
  const tableScrollHandler = (
    event: React.UIEvent<HTMLTableSectionElement>
  ): void => {
    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight - 30
    ) {
      restList.nextBookmark();
    }
  };

  // Component Elements
  const rowProps: TableRowProps = {
    style: {
      cursor: 'pointer',
    },
  };

  // Effects
  useEffect(() => {
    if (restList.requestError) {
      history.replace(RouterPaths.Customer());
    }
  }, [restList.requestError]);

  return (
    <Tab {...props}>
      <nav>
        <Input
          containerType="outline"
          iconRight="magnify"
          placeholder={Strings.Inputs.Search}
          containerProps={{ className: '__search-bar' }}
          invert
          onBlur={(event) => restList.search(event.target.value)}
          onKeyPress={(event) =>
            event.key === 'Enter'
              ? restList.search((event.target as HTMLInputElement).value)
              : undefined
          }
        />
      </nav>
      <Table
        data={restList.list}
        config={{ rowProps, onScroll: tableScrollHandler }}
        loading={restList.loading}
      >
        <TableColumn name="Status" minwidth="150px">
          {(item: License.Data) => (
            <LicenseStatusCell status={item.docStatus} />
          )}
        </TableColumn>
        <TableColumn name="Informações" flex={1}>
          {(item: License.Data) => (
            <LicenseInfoCell host={item.host || ''} name={item.name} />
          )}
        </TableColumn>
      </Table>
    </Tab>
  );
}
