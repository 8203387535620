import {
  Animations,
  Button,
  ModalController,
  Select,
  Spinners,
  useForm,
  useToast,
  Validators,
} from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../../components/page-title';
import useRest from '../../../../hooks/use-rest';
import { Group, Product, ErrorResponse } from '../../../../domain/models';
import { EndPoints } from '../../../../utils/constants';
import { getErrorMessage } from '../../../../utils/error-messages';
import { AddProductGroupModalElement } from './style';

enum Content {
  Form,
  Loading,
}

type AddProductForm = {
  period: number;
  quantity: number;
};

interface AddProductGroupModal {
  controller?: ModalController;
  groupId: string;
  currentGroupProducts: Partial<Group.ProductsData>[];
}

const { usePut, useGet } = useRest();

const Strings = {
  Buttons: {
    Add: 'Adicionar',
  },
  Inputs: {
    Product: 'Selecionar produto...',
    Period: 'Período em dias',
    Quantity: 'Quantidade por período',
  },
  Messages: {
    RegisterLoading: 'Adicionando produto...',
    RegisterSuccess: 'Produto adicionado com sucesso!',
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    InvalidForm: 'Os dados fornecidos são inválidos!',
    UserNotSelected: 'Selecione um produto para adicionar',
  },
  Title: 'Adicionar Produto ao Grupo',
};

export default function AddProductGroupModal({
  controller,
  groupId,
  currentGroupProducts,
}: AddProductGroupModal): JSX.Element {
  const [putData, put] = usePut({ loading: false });
  const [getData, get] = useGet({ loading: false });
  const errorToast = useToast(<span />, { color: 'danger' });
  const successToast = useToast(
    <span>{Strings.Messages.RegisterSuccess}</span>,
    { color: 'success' }
  );
  const [selected, setSelected] = useState<Product.Data>();
  const [form, getErrors, getValues] = useForm<AddProductForm>(
    [
      {
        key: 'period',
        validators: [new Validators.Required()],
      },
      {
        key: 'quantity',
        validators: [new Validators.Required()],
      },
    ],
    'addProductGroup'
  );

  const contentAnimation = useAnimation();
  const [content, _setContent] = useState<Content>(Content.Form);
  const setContent = (value: Content): void => {
    contentAnimation
      .start(Animations.FadeOut)
      .then(() => Promise.resolve(_setContent(value)))
      .then(() => contentAnimation.start(Animations.FadeIn));
  };

  const requestSuccessHandler = (data: Group.Data): void => {
    setTimeout(() => successToast.open());
    if (controller) controller.close(data);
  };

  const requestErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  const confirmHandler = (): void => {
    if (selected) {
      if (!getErrors()) {
        const newGroupProduct = {
          period: Number(getValues().period),
          quantity: Number(getValues().quantity),
          productId: selected._id,
        };
        const mappedGroupProducts = currentGroupProducts.map((ele) => ({
          period: ele.period,
          quantity: ele.quantity,
          productId: ele.product?._id,
        }));
        put(EndPoints.Group(groupId), {
          products: [...mappedGroupProducts, newGroupProduct],
        });
      } else {
        errorToast.setContent(Strings.Messages.InvalidForm);
        setTimeout(() => errorToast.open());
      }
    } else {
      errorToast.setContent(Strings.Messages.UserNotSelected);
      setTimeout(() => errorToast.open());
    }
  };

  useEffect(() => {
    if (putData.loading) {
      setContent(Content.Loading);
    } else {
      setContent(Content.Form);
      if (putData.payload) requestSuccessHandler(putData.payload.data);
      else if (putData.error) requestErrorHandler(putData.error);
    }
  }, [putData]);

  return (
    <AddProductGroupModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      {content === Content.Form && (
        <motion.div className="__form-container">
          <div className="__form-container">
            <form.period placeholder={Strings.Inputs.Period} type="number" />
            <form.quantity
              placeholder={Strings.Inputs.Quantity}
              type="number"
            />
            <Select<Product.Data>
              data={getData.payload?.data.docs || []}
              dataKey="name"
              loading={getData.loading}
              onSearch={(searchValue) =>
                get(EndPoints.Products, {
                  search: 'name',
                  searchValue,
                  filter: 'group',
                  filterValue: groupId,
                  contain: false,
                })
              }
              onSelect={(event, value) => setSelected(value)}
              placeholder={Strings.Inputs.Product}
              value={selected}
            />
          </div>
          <Button
            text={Strings.Buttons.Add}
            color="success"
            onClick={confirmHandler}
          />
        </motion.div>
      )}
      {content === Content.Loading && (
        <motion.div
          className="__form-container"
          animate={contentAnimation}
          initial={{ opacity: 0 }}
        >
          <Spinners.circles width="300px" height="300px" />
          <span>{Strings.Messages.RegisterLoading}</span>
        </motion.div>
      )}
    </AddProductGroupModalElement>
  );
}
