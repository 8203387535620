import React, { useEffect, useState } from 'react';

const Strings = {
  Messages: {
    Loading:
      'Requisitando arquivo. A requisição pode demorar alguns minutos...',
    TimeElapsed: 'Tempo decorrido',
  },
};

const addZeros = (value: number): string => {
  return `00${value}`.slice(-2);
};

const formatTime = (value: number): string => {
  return `${addZeros(Math.floor(value / 60))}:${addZeros(value % 60)}`;
};

const TimeCounter = (): JSX.Element => {
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [time]);

  return (
    <span>
      {Strings.Messages.Loading}
      <br />
      {Strings.Messages.TimeElapsed}: {formatTime(time)}
    </span>
  );
};

export default TimeCounter;
