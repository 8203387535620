import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const LoginPageElement = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: ${() => getGlobalTheme().colors.primary.principal};
    font-weight: ${() => getGlobalTheme().font.h1.fontWeight};
    font-size: ${() => getGlobalTheme().font.h1.fontSize};
    margin: 5vh 0;
    text-align: center;
  }

  .__login-form {
    flex: 1;
    margin: 5vh 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 30px);
    max-width: 600px;

    button {
      margin: 15px 0;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5vh 0;
  }
`;
