import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const NotFoundElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .__divider {
    width: 50%;
    border-bottom: 1px solid ${() => getGlobalTheme().colors.primary.principal};
    margin: 30px;
  }

  h1 {
    font-weight: ${() => getGlobalTheme().font.h1.fontWeight};
    font-size: ${() => getGlobalTheme().font.h1.fontSize};
    max-width: 50%;
    text-align: center;
  }
`;
