import React from 'react';
import { LicenseInfoCellElement } from './style';

interface LicenseInfoCellProps extends React.ComponentProps<'div'> {
  host: string;
  name: string;
}

export default function LicenseInfoCell({
  host,
  name,
}: LicenseInfoCellProps): JSX.Element {
  return (
    <LicenseInfoCellElement>
      <h2>{name}</h2>
      <span>{host}</span>
    </LicenseInfoCellElement>
  );
}
