import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';
import { CLOSE_MIN_WIDTH } from './components/sidebar';
import { View } from './domain/models';

interface ViewProps {
  view: View;
}

export const BodyElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: visible;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* Global Modification */
  .ui-tabs-tab {
    border-color: transparent !important;
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .ui-tabs-tab-selected {
    color: rgba(255, 255, 255, 1) !important;
    font-weight: bold !important;
  }

  .ui-tabs-tab-selector {
    background-color: transparent !important;
    &::after {
      position: absolute;
      content: ' ';
      border: 10px solid transparent;
      border-top-color: ${() => getGlobalTheme().colors.primary.principal};
      left: calc(50% - 10px);
      bottom: -20px;
    }
  }
`;

export const MainElement = styled.main<ViewProps>`
  flex: 1;
  transition: all ${() => getGlobalTheme().transitions.fast};
  height: 100%;
  background-color: ${() => getGlobalTheme().colors.primary.contrast};
  overflow: auto;

  @media screen and (min-width: ${() => CLOSE_MIN_WIDTH}px) {
    max-width: ${({ view }) =>
    view.sidebar.enabled && view.sidebar.opened
      ? 'calc(100% - 250px)'
      : '100%'};
    margin-left: ${({ view }) =>
    view.sidebar.enabled && view.sidebar.opened ? '250px' : '0px'};
  }

  @media screen and (max-width: ${() => CLOSE_MIN_WIDTH}px) {
    min-width: 100vw;
    margin-left: ${({ view }) => (view.section ? '-100vw' : '0vw')};
  }

  .__search-container {
    max-width: 1024px;
    flex: 0;
    min-height: 66px;
    .ui-input-container-outline {
      flex: 1;
      margin-right: 15px;
    }
  }
`;

export const SectionElement = styled.section<ViewProps>`
  flex: 1;
  background-color: ${() => getGlobalTheme().colors.primary.contrast};
  height: 100%;
  position: relative;
  z-index: 10;

  display: ${({ view }) => (view.section ? 'block' : 'none')};
  filter: drop-shadow(
    0px 0px 5px ${() => getGlobalTheme().colors.primary.principal}72
  );

  @media screen and (max-width: ${() => CLOSE_MIN_WIDTH}px) {
    min-width: 100vw;
  }
`;
