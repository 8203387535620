import { getGlobalTheme, Tab } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const ProductDetailsElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const ProductDetailsHeader = styled.header`
  width: 100%;
  background-color: ${() => getGlobalTheme().colors.primary.principal};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 5% 0 5%;

  .__picture-edit-container {
    position: relative;
    button {
      position: absolute;
      right: -5px;
      bottom: -5px;
      box-shadow: ${() => getGlobalTheme().boxShadow.normal};
      background-color: ${() => getGlobalTheme().colors.primary.contrast}64;
    }
  }
`;

export const TabsContainer = styled.div`
  flex: 1;
  width: 100%;

  .ui-tabs {
    height: 100%;
    .ui-tabs-tab-body {
      width: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const ProductDataTabElement = styled(Tab)`
  label {
    color: ${() => getGlobalTheme().colors.primary.principal}64;
    margin-bottom: -10px;
  }
  padding: 15px 0;
`;

export const ProductSizesTabElement = styled(Tab)`
  padding: 30px 0 15px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: show;

  .__product-size-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid
      ${() => getGlobalTheme().colors.primary.principal}32;

    .__product-size-input {
      flex: 1;
      text-align: center;
    }

    .__product-size-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 46px;
    }

    .ui-input-container-downline {
      flex: 1;
      min-width: unset;
      width: unset;
      input {
        width: 100%;
        min-width: unset;
        text-align: center;
      }
    }

    &.__header {
      background-color: ${() => getGlobalTheme().colors.primary.principal}C0;
      color: ${() => getGlobalTheme().colors.primary.contrast};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 30px;
      font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.4);
    }
  }

  .__btn-enable-add {
    margin: 15px 0;
    flex-shrink: 0;
    background-color: ${() => getGlobalTheme().colors.primary.contrast};
    box-shadow: ${() => getGlobalTheme().boxShadow.normal};
    border-radius: 50%;

    &:active {
      box-shadow: ${() => getGlobalTheme().boxShadow.active};
    }
  }
`;
