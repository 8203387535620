import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const ConfirmModalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-width: 40vw;

  h2 {
    font-size: ${() => getGlobalTheme().font.h2.fontSize};
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    padding: 5vh 5vw;
    min-width: 100%;
    max-width: 100%;
    margin: 0;

    @media screen and (max-width: 600px) {
      font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.8);
    }
  }

  footer {
    margin: 30px 0;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    button {
      width: 130px;
      margin: 5px 30px;
    }
  }
`;
