import styled from 'styled-components';

export const ListPageSearchbarElement = styled.div`
  max-width: 1024px;
  flex: 0;
  min-height: 66px;
  .ui-input-container-outline {
    flex: 1;
    margin-right: 15px;
  }
`;
