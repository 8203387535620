import {
  Button,
  Checkbox,
  Input,
  ScrollableContainer,
  Spinners,
  TextArea,
  useToast,
} from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/page-title';
import useRest from '../../hooks/use-rest';
import { CustomerSheet } from '../../domain/models';
import { compareSet } from '../../utils';
import { EndPoints } from '../../utils/constants';
import { SheetPageElement } from './style';

const { useGet, usePut } = useRest();

const Strings = {
  Buttons: {
    AddRole: 'Adicionar Função',
    Save: 'Salvar Modificações',
  },
  Title: 'Ficha de EPI',
  SubTitles: {
    General: 'Geral',
    EmployeeInfo: 'Informações do Funcionários',
    ReportColumns: 'Colunas de Relatório',
  },
  Inputs: {
    TextColor: 'Cor do Texto',
    TableColor: 'Cor da Tabela',
    Declaration: 'Declaração',
  },
  Messages: {
    GetDataError: 'Não foi possível carregar a ficha de EPI atual.',
    Loading: 'Carregando...',
    PutError:
      'Houve um erro para salvar as modificações. Por favor, verifique sua conexão.',
    InvalidColors: 'As cores são inválidas!',
  },
  Labels: {
    Sector: 'Setor',
    Role: 'Função',
    Admission: 'Data de Admissão',
    UseValidity: 'Validade',
    Certificate: 'Certificado',
    Local: 'Local',
  },
};

const EmployeeInfo = [
  { key: 'sector', text: Strings.Labels.Sector },
  { key: 'role', text: Strings.Labels.Role },
  { key: 'admissionDate', text: Strings.Labels.Admission },
];

const ReportColumns = [
  { key: 'useValidity', text: Strings.Labels.UseValidity },
  { key: 'certificate', text: Strings.Labels.Certificate },
  { key: 'local', text: Strings.Labels.Local },
];

export default function SheetPage(): JSX.Element {
  // Hooks
  const [sheet, setSheet] = useState<CustomerSheet.Data>();
  const [getData, get] = useGet({ loading: true });
  const [putData, put] = usePut({ loading: false });
  const [textColor, setTextColor] = useState<string>('#000000');
  const [tableColor, setTableColor] = useState<string>('#000000');
  const [declaration, setDeclaration] = useState<string>('');
  const [signature, setSignature] = useState<boolean>(false);
  const [employeeInfoSet, setEmployeeInfoSet] = useState<Set<string>>(
    new Set()
  );
  const [reportColumnsSet, setReportColumnsSet] = useState<Set<string>>(
    new Set()
  );
  const [enableSaveButton, setEnableSaveButton] = useState<boolean>(false);

  const loadingCoverController = useAnimation();
  const saveButtonController = useAnimation();
  const errorToast = useToast(<span />, { color: 'danger' });

  // Handlers
  const employeeInfoCheckboxHandler = (key: string): void => {
    if (employeeInfoSet.has(key)) {
      employeeInfoSet.delete(key);
      setEmployeeInfoSet(new Set(employeeInfoSet));
    } else {
      employeeInfoSet.add(key);
      setEmployeeInfoSet(new Set(employeeInfoSet));
    }
  };

  const reportColumnsCheckboxHandler = (key: string): void => {
    if (reportColumnsSet.has(key)) {
      reportColumnsSet.delete(key);
      setReportColumnsSet(new Set(reportColumnsSet));
    } else {
      reportColumnsSet.add(key);
      setReportColumnsSet(new Set(reportColumnsSet));
    }
  };

  const validateColors = (): boolean => {
    const regex = /#{1}(\d|[A-F]|[a-f]){6}$/;
    return !!textColor.match(regex) && !!tableColor.match(regex);
  };

  const saveChangesHandler = (): void => {
    if (!validateColors()) {
      errorToast.setContent(Strings.Messages.InvalidColors);
      setTimeout(() => errorToast.open());
      return;
    }

    if (!sheet) throw new Error('not valid sheet');
    const payload: Partial<CustomerSheet.Data> = {};

    payload.signature = signature;

    if (textColor.toLowerCase() !== sheet.textColor?.toLowerCase()) {
      payload.textColor = textColor;
    }
    if (tableColor.toLowerCase() !== sheet.tableColor?.toLowerCase()) {
      payload.tableColor = tableColor;
    }
    if (declaration.toLowerCase() !== sheet.declaration?.toLowerCase()) {
      payload.declaration = declaration;
    }
    if (!compareSet(new Set(sheet.employeeInfo), employeeInfoSet)) {
      payload.employeeInfo = Array.from(
        employeeInfoSet
      ) as CustomerSheet.EmployeeInfo[];
    }
    if (!compareSet(new Set(sheet.reportColumns), reportColumnsSet)) {
      payload.reportColumns = Array.from(
        reportColumnsSet
      ) as CustomerSheet.ReportColumn[];
    }

    put(EndPoints.Sheet(sheet._id), payload);
  };

  // Component Elements
  const setLoadingState = (enable: boolean): void => {
    if (enable) {
      loadingCoverController.set({ display: 'flex ' });
      loadingCoverController.start({ opacity: 1 });
    } else {
      loadingCoverController
        .start({ opacity: 0 })
        .then(() => loadingCoverController.set({ display: 'none' }));
    }
  };

  // Effects
  useEffect(() => {
    get(EndPoints.Sheet());
  }, []);

  useEffect(() => {
    setLoadingState(getData.loading);
    if (getData.payload) {
      setTextColor(getData.payload.data.textColor);
      setTableColor(getData.payload.data.tableColor);
      setDeclaration(getData.payload.data.declaration);
      setSignature(getData.payload.data.signature);
      setEmployeeInfoSet(new Set(getData.payload.data.employeeInfo));
      setReportColumnsSet(new Set(getData.payload.data.reportColumns));
      setEnableSaveButton(false);
      setSheet(getData.payload.data);
    } else {
      setSheet(undefined);
    }
  }, [getData]);

  useEffect(() => {
    setLoadingState(putData.loading);
    if (putData.payload) {
      setTextColor(putData.payload.data.textColor);
      setTableColor(putData.payload.data.tableColor);
      setDeclaration(putData.payload.data.declaration);
      setSignature(putData.payload.data.signature);
      setEmployeeInfoSet(new Set(putData.payload.data.employeeInfo));
      setReportColumnsSet(new Set(putData.payload.data.reportColumns));
      setEnableSaveButton(false);
      setSheet(putData.payload.data);
    } else if (putData.error) {
      errorToast.setContent(Strings.Messages.PutError);
      setTimeout(() => errorToast.open());
    }
  }, [putData]);

  useEffect(() => {
    saveButtonController.setDefaultTransition({
      duration: 0.5,
      ease: 'easeInOut',
    });
    if (enableSaveButton) {
      saveButtonController.set({ display: 'flex' });
      saveButtonController.start({
        bottom: 10,
      });
    } else {
      saveButtonController
        .start({
          bottom: -150,
        })
        .then(() =>
          saveButtonController.set({
            display: 'none',
          })
        );
    }
  }, [enableSaveButton]);

  useEffect(() => {
    if (
      sheet &&
      (textColor.toLowerCase() !== sheet.textColor?.toLowerCase() ||
        tableColor.toLowerCase() !== sheet.tableColor?.toLowerCase() ||
        declaration.toLowerCase() !== sheet.declaration?.toLowerCase() ||
        !compareSet(new Set(sheet.employeeInfo), employeeInfoSet) ||
        !compareSet(new Set(sheet.reportColumns), reportColumnsSet) ||
        signature !== sheet.signature)
    ) {
      setEnableSaveButton(true);
    } else {
      setEnableSaveButton(false);
    }
  }, [
    textColor,
    tableColor,
    declaration,
    employeeInfoSet,
    reportColumnsSet,
    signature,
  ]);

  return (
    <SheetPageElement>
      <PageTitle>{Strings.Title}</PageTitle>
      <div className="__scroll-container">
        <ScrollableContainer
          flexDirection="column"
          style={{ alignItems: 'center' }}
        >
          <div className="__scroll-content">
            {/* General */}
            <h2>{Strings.SubTitles.General}</h2>
            <div className="__input-row">
              <Input
                placeholder={Strings.Inputs.TextColor}
                value={textColor}
                onChange={(event) => setTextColor(event.currentTarget.value)}
              />
              <input
                className="__input-color"
                type="color"
                onChange={(event) => setTextColor(event.currentTarget.value)}
                value={textColor}
              />
            </div>
            <div className="__input-row">
              <Input
                placeholder={Strings.Inputs.TableColor}
                value={tableColor}
                onChange={(event) => setTableColor(event.currentTarget.value)}
              />
              <input
                className="__input-color"
                type="color"
                onChange={(event) => setTableColor(event.currentTarget.value)}
                value={tableColor}
              />
            </div>
            <div className="__input-row">
              <label className="__declaration-label">
                {Strings.Inputs.Declaration}
              </label>
              <TextArea
                placeholder={Strings.Inputs.Declaration}
                icon="pencil"
                value={declaration}
                onChange={(event) => setDeclaration(event.currentTarget.value)}
              />
            </div>

            {/* Employee Info */}
            <h2>{Strings.SubTitles.EmployeeInfo}</h2>
            <div className="__checkbox-column">
              {EmployeeInfo.map(({ key, text }) => (
                <div
                  key={key}
                  className="__checkbox-row"
                  onClick={() => employeeInfoCheckboxHandler(key)}
                >
                  <Checkbox value={employeeInfoSet.has(key)} size="25px" />
                  <label>{text}</label>
                </div>
              ))}
              <div
                className="__checkbox-row"
                onClick={() => setSignature(!signature)}
              >
                <Checkbox value={signature} size="25px" />
                <label>Assinatura</label>
              </div>
            </div>

            {/* Report Columns */}
            <h2>{Strings.SubTitles.ReportColumns}</h2>
            <div className="__checkbox-column">
              {ReportColumns.map(({ key, text }) => (
                <div
                  key={key}
                  className="__checkbox-row"
                  onClick={() => reportColumnsCheckboxHandler(key)}
                >
                  <Checkbox value={reportColumnsSet.has(key)} size="25px" />
                  <label>{text}</label>
                </div>
              ))}
            </div>
          </div>
        </ScrollableContainer>
      </div>

      <motion.div className="__loading-cover" animate={loadingCoverController}>
        <Spinners.circles width="30vh" height="30vh" />
        <PageTitle>{Strings.Messages.Loading}</PageTitle>
      </motion.div>

      <Button
        text={Strings.Buttons.Save}
        color="success"
        icon="check"
        className="__save-btn"
        animate={saveButtonController}
        onClick={saveChangesHandler}
      />
    </SheetPageElement>
  );
}
