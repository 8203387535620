import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const LicenseInfoCellElement = styled.div`
  max-width: 100%;
  color: ${() => getGlobalTheme().colors.primary.principal};
  h2 {
    font-size: ${() => getGlobalTheme().font.h2.fontSize};
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    margin: 0;
  }
  span {
    font-weight: 100;
    overflow: hidden;
    color: ${() => getGlobalTheme().colors.primary.principal}88;
  }
`;

export const LicenseStatusCellElement = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bolder;
  }

  &.__online {
    color: ${() => getGlobalTheme().colors.primary.principal};
  }

  &.__connecting {
    color: ${() => getGlobalTheme().colors.primary.principal};
  }

  &.__offline {
    opacity: 0.3;
    color: ${() => getGlobalTheme().colors.primary.principal};
  }
`;

export const LicenseRowOverflowElement = styled.div`
  display: flex;
`;
