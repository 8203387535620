import './app.css';
import { BodyElement, MainElement, SectionElement } from './app-style';
import { ItemNotFound, PageNotFound } from './pages/not-found';
import { ReduxStore } from './redux';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CustomerDetails from './pages/customer/details';
import CustomerPage from './pages/customer';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import PrivateRoute from './components/private-route/index';
import React, { useEffect } from 'react';
import Sidebar from './components/sidebar';
import ViewActions from './redux/actions/view-actions';
import SectorsPage from './pages/sector';
import SectorDetails from './pages/sector/details/index';
import CostCenterPage from './pages/cost-center';
import CostCenterDetails from './pages/cost-center/details/index';
import EmployeesPage from './pages/employee';
import EmployeeDetails from './pages/employee/details/index';
import { AccessType, View } from './domain/models';
import RolesPage from './pages/roles';
import GroupsPage from './pages/groups';
import RoleDetails from './pages/roles/details';
import GroupDetails from './pages/groups/details/index';
import MachinePage from './pages/machine';
import LicensePage from './pages/license';
import ProductPage from './pages/product/index';
import ProductDetails from './pages/product/details';
import { RouterPaths } from './utils/constants';
import ReportsPage from './pages/report';
import SheetPage from './pages/sheet';
import ApprovalPage from './pages/approval';
import TriggerPage from './pages/trigger';
import TriggerDetails from './pages/trigger/details';
import { getPrimaryUserRoute } from './utils';

function App(): JSX.Element {
  const view = useSelector<ReduxStore, Partial<View>>((state) => state.view);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.split('/').length > 2) {
      dispatch(ViewActions.section.enable());
    } else {
      dispatch(ViewActions.section.disable());
    }
  }, [pathname]);

  return (
    <BodyElement>
      {view.sidebar?.enabled && <Sidebar />}

      <MainElement view={view as View}>
        <Switch>
          <PrivateRoute
            path="/customers/:id?"
            component={CustomerPage}
            permissions={AccessType.Admin}
          />
          <PrivateRoute
            path={RouterPaths.Sectors}
            component={SectorsPage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.CostCenters}
            component={CostCenterPage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Employees}
            component={EmployeesPage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Roles}
            component={RolesPage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Groups}
            component={GroupsPage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Machines}
            component={MachinePage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Licenses}
            component={LicensePage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Products}
            component={ProductPage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Reports}
            component={ReportsPage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Sheet}
            component={SheetPage}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Approval}
            component={ApprovalPage}
            permissions={AccessType.Supervisor}
          />
          <PrivateRoute
            path={RouterPaths.Triggers}
            component={TriggerPage}
            permissions={AccessType.CustomerAdminDefault}
          />

          {localStorage.getItem('accessToken') ? (
            <Redirect path={RouterPaths.Login} to={getPrimaryUserRoute()} />
          ) : (
            <Route path="/login" component={LoginPage} />
          )}
          <Route path="/" component={HomePage} exact />
          <Route component={PageNotFound} />
        </Switch>
      </MainElement>

      <SectionElement view={view as View}>
        <Switch>
          <PrivateRoute
            path={RouterPaths.Customer(':id')}
            component={CustomerDetails}
            permissions={AccessType.Admin}
          />
          <PrivateRoute
            path={RouterPaths.Sector(':id')}
            component={SectorDetails}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.CostCenter(':id')}
            component={CostCenterDetails}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Employee(':id')}
            component={EmployeeDetails}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Role(':id')}
            component={RoleDetails}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Group(':id')}
            component={GroupDetails}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Product(':id')}
            component={ProductDetails}
            permissions={AccessType.CustomerAdminDefault}
          />
          <PrivateRoute
            path={RouterPaths.Trigger(':id')}
            component={TriggerDetails}
            permissions={AccessType.CustomerAdminDefault}
          />
          <Route component={ItemNotFound} />
        </Switch>
      </SectionElement>
    </BodyElement>
  );
}

export default App;
