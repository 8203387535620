import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const SettingsModalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-width: 40vw;

  footer {
    margin: 30px 0;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    button {
      width: 130px;
      margin: 5px 30px;
    }
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 25px 0;

  .__avatar {
    box-shadow: ${() => getGlobalTheme().boxShadow.normal};
    background-color: ${() => getGlobalTheme().colors.primary.principal};
  }

  .__avatar-edit {
    position: absolute;
    bottom: 0;
    right: 0;

    background-color: ${() => getGlobalTheme().colors.primary.principal}60;
    box-shadow: ${() => getGlobalTheme().boxShadow.normal};
  }
`;

export const CustomerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 15px 0;
  width: 100%;

  label {
    margin-top: 15px;
    text-align: center;
    font-size: calc(1.5rem * 0.45);
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
  }

  input {
    width: 90%;
    text-align: center;
  }

  h2 {
    font-size: ${() => getGlobalTheme().font.h2.fontSize};
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    min-width: 100%;
    max-width: 100%;
    margin: 0;

    @media screen and (max-width: 600px) {
      font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.8);
    }
  }
`;
