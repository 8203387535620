import {
  Button,
  ImageAvatar,
  ModalController,
  Spinners,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useRef, useState } from 'react';
import useRest from '../../hooks/use-rest';
import { EndPoints } from '../../utils/constants';
import { UploadFileModalElement } from './style';

const Strings = {
  Title: 'Visualizar Mídia',
  Buttons: {
    Cancel: 'Cancelar',
    Confirm: 'Salvar Arquivo',
    FindFile: 'Adicionar Arquivo',
    ReplaceFile: 'Trocar Arquivo',
    DownloadFile: 'Baixar Arquivo',
  },
  Messages: {
    InvalidFile: 'O arquivo selecionado é inválido',
    UploadingFile: 'Enviando arquivo...',
    UploadFailed: 'Houve um erro para enviar o arquivo.',
    PreviewFailed: 'Não foi possível exibir a pré visualização do arquivo.',
    EmptyFile: 'Nenhum arquivo encontrado.',
  },
};

type FileType =
  | '18' // Picture
  | '19' // Manual
  | '20'; // Video
const fileExtension = (fileType: FileType): string => {
  switch (fileType) {
    case '18':
      return 'image/png, image/jpeg';
    case '19':
      return '.pdf';
    case '20':
      return 'video/mp4';
  }
};

interface UploadFileModalProps {
  controller?: ModalController;
  id: string;
  fileType: FileType;
  currentFile?: string;
}

const { usePost } = useRest();

export default function MediaFileModal({
  controller,
  id,
  fileType,
  currentFile,
}: UploadFileModalProps): JSX.Element {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileURL, setSelectedFileURL] = useState<string | undefined>(
    currentFile
  );
  const [postData, post] = usePost(
    { loading: false },
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
  const errorToast = useToast(<span />, { color: 'danger' });
  const inputRef = useRef<HTMLInputElement>(null);

  const fileSelectHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setSelectedFileURL(URL.createObjectURL(event.target.files[0]));
    } else {
      setSelectedFile(undefined);
      setSelectedFileURL(undefined);
      errorToast.setContent(Strings.Messages.InvalidFile);
      setTimeout(() => errorToast.open());
    }
  };

  const confirmHandler = (): void => {
    if (!selectedFile) {
      errorToast.setContent(Strings.Messages.InvalidFile);
      setTimeout(() => errorToast.open());
      return;
    }

    const formData = new FormData();
    formData.append('type', fileType);
    formData.append('file', selectedFile);
    post(EndPoints.ProductFile(id), formData);
  };

  useEffect(() => {
    if (postData.payload) {
      if (controller) controller.close(postData.payload.data);
    } else if (postData.error) {
      errorToast.setContent(Strings.Messages.UploadFailed);
      setTimeout(() => errorToast.open());
    }
  }, [postData]);

  useEffect(() => {
    setSelectedFileURL(currentFile);
  }, [currentFile]);

  return (
    <UploadFileModalElement>
      {!postData.loading && (
        <div className="__form-container">
          {selectedFileURL && fileType === '18' && (
            <ImageAvatar src={selectedFileURL} size="20vh" />
          )}

          {selectedFileURL && fileType === '19' && (
            <object data={selectedFileURL} type="application/pdf">
              {Strings.Messages.PreviewFailed}
            </object>
          )}

          {selectedFileURL && fileType === '20' && (
            <video controls src={selectedFileURL}>
              {Strings.Messages.PreviewFailed}
            </video>
          )}

          {selectedFile && <span>{selectedFile.name}</span>}
          {!selectedFileURL && <span>{Strings.Messages.EmptyFile}</span>}
        </div>
      )}
      {postData.loading && (
        <div className="__form-container">
          <Spinners.circles width="300px" height="30vh" />
          <span>{Strings.Messages.UploadingFile}</span>
        </div>
      )}
      {!postData.loading && (
        <footer>
          <input
            ref={inputRef}
            type="file"
            onChange={fileSelectHandler}
            accept={fileExtension(fileType)}
            style={{ display: 'none' }}
          />
          {!selectedFileURL && (
            <Button
              buttonType="icon"
              icon="fileSearch"
              onClick={() => inputRef.current?.click()}
              text={Strings.Buttons.FindFile}
            />
          )}
          {selectedFileURL && (
            <Button
              buttonType="icon"
              icon="download"
              onClick={() => window.open(selectedFileURL, '__blank')}
              text={Strings.Buttons.DownloadFile}
            />
          )}

          {selectedFileURL && (
            <Button
              buttonType="icon"
              icon="fileReplace"
              onClick={() => inputRef.current?.click()}
              text={Strings.Buttons.ReplaceFile}
            />
          )}
          {selectedFile && (
            <Button
              buttonType="icon"
              icon="check"
              onClick={confirmHandler}
              text={Strings.Buttons.Confirm}
            />
          )}
        </footer>
      )}
    </UploadFileModalElement>
  );
}
