import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const ColumnSelectionModalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 50vw;
  max-width: 600px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .__checkbox-row {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
      font-size: ${() => getGlobalTheme().font.h2.fontSize};
      color: ${() => getGlobalTheme().colors.primary.principal};
      margin: 0 15px;
    }

    padding: 3px 15px;

    &:hover {
      cursor: pointer;
    }
  }

  .__checkbox-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
    flex-wrap: wrap;
    width: 100%;
    margin: 15px 0;

    @media screen and (min-width: 900px) {
      max-height: 200px;
    }
  }
`;
