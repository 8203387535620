import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const EmployeeDetailsElement = styled(motion.div)`
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .__title-header {
    background-color: ${() => getGlobalTheme().colors.primary.principal};
  }

  nav {
    position: relative;
    padding: 5px 15px 50px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${() => getGlobalTheme().colors.primary.principal}C0;

    button {
      margin: 0 20px;
      text-transform: uppercase;
      span {
        font-weight: bold;
      }
    }

    .__search-bar {
      position: absolute;
      bottom: -10px;
      left: 10%;
      width: 80%;
      box-shadow: ${() => getGlobalTheme().boxShadow.active};
    }

    z-index: 10;
  }

  h2 {
    font-size: ${() => getGlobalTheme().font.h2.fontSize};
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .__employee-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;

    input {
      text-align: center;
    }
  }

  .__scrollable {
    padding: 15px 0;
  }
`;
