import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const GroupDetailsElement = styled(motion.div)`
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .__title-header {
    background-color: ${() => getGlobalTheme().colors.primary.principal};
  }

  nav {
    position: relative;
    padding: 5px 15px 50px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${() => getGlobalTheme().colors.primary.principal}C0;

    button {
      margin: 0 20px;
      text-transform: uppercase;
      span {
        font-weight: bold;
      }
    }

    .__search-bar {
      position: absolute;
      bottom: -15px;
      left: 10%;
      width: 80%;
      box-shadow: ${() => getGlobalTheme().boxShadow.active};
    }

    z-index: 10;
  }

  .ui-tabs-header {
    box-shadow: unset;
    filter: drop-shadow(${() => getGlobalTheme().boxShadow.active});
  }

  .ui-tabs-tab-body {
    display: flex;
    flex-direction: column;
  }

  .__content-container {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
