import { Button, ModalController } from '@keymax-dev/smartepi-ui';
import React from 'react';
import PageTitle from '../page-title';
import { ConfirmModalElement } from './style';

const Strings = {
  Buttons: {
    Confirm: 'Confirmar',
    Cancel: 'Cancelar',
  },
};

interface ConfirmModalProps extends React.ComponentProps<'div'> {
  controller?: ModalController;
  title?: string;
  message?: string;
}

export default function ConfirmModal({
  controller,
  title,
  message,
  ...divProps
}: ConfirmModalProps): JSX.Element {
  return (
    <ConfirmModalElement>
      {title && <PageTitle>{title}</PageTitle>}
      {message && <h2>{message}</h2>}
      {divProps.children}
      <footer>
        <Button
          text={Strings.Buttons.Cancel}
          onClick={() => controller && controller.close('canceled')}
        />
        <Button
          text={Strings.Buttons.Confirm}
          color="success"
          onClick={() => controller && controller.close('confirmed')}
        />
      </footer>
    </ConfirmModalElement>
  );
}
