import {
  Checkbox,
  Table,
  TableColumn,
  useModal,
} from '@keymax-dev/smartepi-ui';
import React from 'react';
import ListPageSearchBar from '../../components/list-page-search-bar';
import PageActions from '../../components/page-actions';
import PageTitle from '../../components/page-title';
import useListPage from '../../hooks/use-list-page';
import { EndPoints, RouterPaths } from '../../utils/constants';
import AddTriggerModal from './add-trigger';
import { TriggersTableRowElement, TriggersPageElement } from './style';
import { Trigger } from '../../domain/models';
import { findTriggerStatus, findTriggerType } from '../../utils/triggers';

const Strings = {
  Buttons: {
    AddTrigger: 'Adicionar Alerta',
  },
  Inputs: {
    Search: 'Buscar alerta...',
  },
  Title: 'Alertas',
  Table: {
    ColumnTitles: {
      Selection: 'Seleção',
      Trigger: 'Alerta',
      Type: 'Tipo',
      Status: 'Status',
    },
    TriggerCall: {
      CNPJ: 'CNPJ',
      Licenses: 'Licenças',
    },
  },
  Messages: {
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar os alertas selecionados?',
  },
};

export default function CustomerPage(): JSX.Element {
  // Hooks
  const listPage = useListPage<Trigger.Data>(
    EndPoints.Triggers,
    RouterPaths.Trigger
  );
  const addCustomerModal = useModal(<AddTriggerModal />);

  // Handlers
  const addTriggerHandler = (): void => {
    addCustomerModal.onClose(listPage.addItemModalCloseHandler);
    addCustomerModal.open();
  };

  // Component Elements
  const pageActions = [
    {
      text: Strings.Buttons.AddTrigger,
      icon: 'plus',
      onClick: addTriggerHandler,
    },
  ];
  const tableConfig = {
    rowEvents: listPage.rowEvents,
    rowProps: listPage.rowProps,
    onScroll: listPage.tableScrollHandler,
  };

  return (
    <TriggersPageElement>
      <PageTitle>{Strings.Title}</PageTitle>
      <PageActions actions={pageActions} />
      <ListPageSearchBar
        listPage={listPage}
        strings={{
          placeholder: Strings.Inputs.Search,
          deleteTitle: Strings.Messages.Caution,
          deleteMessage: Strings.Messages.DeleteConfirmation,
        }}
        searchKey="name"
      />{' '}
      <Table
        data={listPage.restList.list}
        config={tableConfig}
        loading={listPage.restList.loading}
      >
        {listPage.selectable && (
          <TableColumn
            name={Strings.Table.ColumnTitles.Trigger}
            key="selected"
            flex={0}
            minwidth="120px"
          >
            {(item: Trigger.Data): JSX.Element => (
              <div style={{ position: 'absolute' }}>
                <Checkbox
                  size="25px"
                  value={listPage.selectableList.keys.has(`${item._id}`)}
                />
              </div>
            )}
          </TableColumn>
        )}
        <TableColumn
          name={Strings.Table.ColumnTitles.Trigger}
          key="trigger"
          maxwidth="220px"
          minwidth="220px"
        >
          {(item: Trigger.Data): JSX.Element => (
            <TriggersTableRowElement
              active={listPage.activeDetails.item?._id === item._id}
            >
              {item.name}
            </TriggersTableRowElement>
          )}
        </TableColumn>
        <TableColumn
          name={Strings.Table.ColumnTitles.Type}
          key="type"
          flex={1}
          minwidth="220px"
        >
          {(item: Trigger.Data): JSX.Element => (
            <TriggersTableRowElement
              active={listPage.activeDetails.item?._id === item._id}
            >
              {findTriggerType(item?.type)?.name}
            </TriggersTableRowElement>
          )}
        </TableColumn>
        <TableColumn
          name={Strings.Table.ColumnTitles.Status}
          key="status"
          flex={1}
          minwidth="220px"
        >
          {(item: Trigger.Data): JSX.Element => (
            <TriggersTableRowElement
              active={listPage.activeDetails.item?._id === item._id}
              className="items-center flex justify-center"
            >
              <span
                className="rounded-full"
                style={{
                  backgroundColor: findTriggerStatus(item?.status)?.color,
                  width: 6,
                  height: 6,
                  maxWidth: 6,
                  maxHeight: 6,
                }}
              />
              {findTriggerStatus(item?.status)?.name}
            </TriggersTableRowElement>
          )}
        </TableColumn>
      </Table>
    </TriggersPageElement>
  );
}
