import {
  Checkbox,
  Table,
  TableColumn,
  useModal,
} from '@keymax-dev/smartepi-ui';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ListPageSearchBar from '../../components/list-page-search-bar';
import PageActions from '../../components/page-actions';
import PageTitle from '../../components/page-title';
import useListPage from '../../hooks/use-list-page';
import ActiveDetailsActions from '../../redux/actions/active-details-actions';
import { BASE_API_URL, EndPoints, RouterPaths } from '../../utils/constants';
import AddGroupModal from './add-group';
import { GroupsPageElement, GroupTableRowElement } from './style';
import { Group } from '../../domain/models';
import { findToken, createConfig } from '../../hooks/use-rest';

const Strings = {
  Buttons: {
    AddGroup: 'Adicionar Grupo',
    ExportCSV: 'Exportar CSV',
  },
  Inputs: {
    Search: 'Buscar grupo...',
  },
  Title: 'Grupos',
  Table: {
    ColumnTitles: {
      Selection: 'Seleção',
      Group: 'Grupo',
    },
  },
  Messages: {
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar os grupos selecionados?',
  },
};

export default function GroupsPage(): JSX.Element {
  const [isDownloadingCSV, setIsDownloadingCSV] = React.useState(false);

  // Hooks
  const listPage = useListPage<Group.Data>(EndPoints.Groups, RouterPaths.Group);
  const history = useHistory();
  const addGroupModal = useModal(<AddGroupModal />);
  const dispatch = useDispatch();

  // Handlers
  const addGroupHandler = (): void => {
    addGroupModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const newGroup = reason as Group.Data;
        listPage.restList.updateItems([newGroup]);
        dispatch(ActiveDetailsActions.setActiveDetails(newGroup, dispatch));
        history.push(RouterPaths.Group(newGroup._id));
      }
    });
    addGroupModal.open();
  };

  // Component Elements
  const pageActions = [
    {
      text: Strings.Buttons.AddGroup,
      icon: 'plus',
      onClick: addGroupHandler,
    },
    {
      text: Strings.Buttons.ExportCSV,
      icon: 'download',
      onClick: downloadCSV,
      disabled: isDownloadingCSV,
      style: isDownloadingCSV
        ? {
            cursor: 'not-allowed',
            animationName: 'spin',
            animationDuration: '1000ms',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
          }
        : undefined,
    },
  ];
  const tableConfig = {
    rowEvents: listPage.rowEvents,
    rowProps: listPage.rowProps,
    onScroll: listPage.tableScrollHandler,
  };

  function downloadCSV(): void {
    const url = `${BASE_API_URL}/${EndPoints.Groups}/export-csv`;
    setIsDownloadingCSV(true);
    findToken()
      .then((token) =>
        axios.get(url, createConfig(token)).then((res) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([res.data]));
          link.setAttribute('download', 'grupos.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      )
      .finally(() => setIsDownloadingCSV(false));
  }

  return (
    <GroupsPageElement>
      <PageTitle>{Strings.Title}</PageTitle>
      <PageActions actions={pageActions} />
      <ListPageSearchBar
        listPage={listPage}
        strings={{
          placeholder: Strings.Inputs.Search,
          deleteTitle: Strings.Messages.Caution,
          deleteMessage: Strings.Messages.DeleteConfirmation,
        }}
      />
      <Table
        data={listPage.restList.list}
        config={tableConfig}
        loading={listPage.restList.loading}
      >
        {listPage.selectable && (
          <TableColumn
            name={Strings.Table.ColumnTitles.Selection}
            key="selected"
            flex={0}
            minwidth="120px"
          >
            {(item: Group.Data): JSX.Element => (
              <div style={{ position: 'absolute' }}>
                <Checkbox
                  size="25px"
                  value={listPage.selectableList.keys.has(`${item._id}`)}
                />
              </div>
            )}
          </TableColumn>
        )}
        <TableColumn
          name={Strings.Table.ColumnTitles.Group}
          key="group"
          flex={1}
          minwidth="320px"
        >
          {(item: Group.Data): JSX.Element => (
            <GroupTableRowElement
              active={listPage.activeDetails.item?._id === item._id}
            >
              {item.name}
            </GroupTableRowElement>
          )}
        </TableColumn>
      </Table>
    </GroupsPageElement>
  );
}
