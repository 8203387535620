import {
  Animations,
  Button,
  Spinners,
  useForm,
  useToast,
  Validators,
} from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageTitle from '../../components/page-title';
import { ErrorResponse } from '../../domain/models';
import useRest from '../../hooks/use-rest';
import { getPrimaryUserRoute } from '../../utils';
import { EndPoints } from '../../utils/constants';
import { getErrorMessage } from '../../utils/error-messages';
import { LoginPageElement } from './styles';

const Strings = {
  Buttons: {
    Submit: 'Entrar',
    RecoverAccout: 'Recuperar Conta',
  },
  Inputs: {
    Email: 'Email',
    Password: 'Senha',
  },
  Messages: {
    InvalidForm: 'Os dados fornecidos são inválidos!',
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
  },
  Title: 'Login',
};

enum Content {
  Form,
  Loading,
}

const { usePost } = useRest();

export default function LoginPage(): JSX.Element {
  const [data, post] = usePost();
  const [form, getErrors, getValues] = useForm(
    [
      {
        key: 'email',
        validators: [new Validators.Required(), new Validators.Email()],
      },
      {
        key: 'password',
        validators: [new Validators.Required(), new Validators.MinLength(6)],
      },
    ],
    'login'
  );

  const contentAnimation = useAnimation();
  const [content, _setContent] = useState<Content>(Content.Form);
  const setContent = (value: Content): void => {
    contentAnimation
      .start(Animations.FadeOut)
      .then(() => Promise.resolve(_setContent(value)))
      .then(() => contentAnimation.start(Animations.FadeIn));
  };

  const history = useHistory();
  const errorToast = useToast(<span />, { color: 'danger' });

  const requestLogin = (): void => {
    if (!getErrors()) {
      post(EndPoints.Login, getValues());
    } else {
      errorToast.setContent(Strings.Messages.InvalidForm);
      errorToast.open();
    }
  };

  const loginSuccessHandler = (data: any): void => {
    localStorage.setItem('accessToken', data.accessToken);

    try {
      history.replace(getPrimaryUserRoute());
    } catch (e) {
      console.error('Auth token parsing error', e);
    }
  };

  const loginErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  useEffect(() => {
    if (!data.loading) {
      if (data.payload) loginSuccessHandler(data.payload.data);
      else if (data.error) loginErrorHandler(data.error);
      setContent(Content.Form);
    } else {
      setContent(Content.Loading);
    }
  }, [data]);

  useEffect(() => {
    contentAnimation.start(Animations.FadeIn);
  }, [content]);

  return (
    <LoginPageElement>
      <PageTitle>{Strings.Title}</PageTitle>
      {content === Content.Form && (
        <motion.div
          className="__login-form"
          animate={contentAnimation}
          initial={{ opacity: 0 }}
        >
          <form.email placeholder={Strings.Inputs.Email} iconRight="account" />
          <form.password
            placeholder={Strings.Inputs.Password}
            iconRight="key"
            type="password"
          />
          <Button text={Strings.Buttons.Submit} onClick={requestLogin} />
        </motion.div>
      )}
      {content === Content.Loading && (
        <motion.div
          className="__login-form"
          animate={contentAnimation}
          initial={{ opacity: 0 }}
        >
          <Spinners.circles width="300px" height="300px" />
        </motion.div>
      )}

      {/* <motion.footer>
                {content === Content.Form && (
                    <Button
                        buttonType="outline"
                        text={Strings.Buttons.RecoverAccout}
                        animate={contentAnimation}
                        initial={{ opacity: 0 }}
                    />
                )}
            </motion.footer> */}
    </LoginPageElement>
  );
}
