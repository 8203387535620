import {
  Badge,
  Select,
  Table,
  TableColumn,
  TableRowEvents,
  TableRowProps,
  useModal,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/page-title';
import useRest from '../../hooks/use-rest';
import { ReduxStore } from '../../redux';
import { Auth, User, Withdrawal } from '../../domain/models';
import { mergeArrayById } from '../../utils';
import { EndPoints } from '../../utils/constants';
import ApprovalDetailsModal from './approval-details';
import {
  ApprovalDateTimeCell,
  ApprovalPageElement,
  ApprovalProductCell,
  ApprovalQuantityCell,
  ApprovalUserCell,
} from './style';

const Strings = {
  PageTitle: 'Aprovações',
  Table: {
    ColumnTitles: {
      Employee: 'Funcionário',
      Product: 'Produto',
      Quantity: 'Qtd.',
      Date: 'Data/Hora',
    },
  },
  Inputs: {
    SelectEmployee: 'Selecionar usuário...',
  },
};

const { useGet } = useRest();

export default function ApprovalPage(): JSX.Element {
  // Hooks
  const [getData, get] = useGet({ loading: true });
  const [getUsersData, getUsers] = useGet({ loading: true });
  const [approvalList, setApprovalList] = useState<Withdrawal.Data[]>([]);
  const [filteringUser, setFilteringUser] = useState<User.Data>();
  const { id: userId } = useSelector<ReduxStore, Auth>(
    (state) => state.auth as Auth
  );
  const detailsModal = useModal(<ApprovalDetailsModal userId={userId} />, {
    containerProps: { style: { padding: 0 } },
  });

  // Handlers
  const rowClickHandler = (event: any, request: Withdrawal.Data): void => {
    detailsModal.injectProps({ request, userId });
    detailsModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const newReport = reason as Withdrawal.Data;
        setApprovalList(mergeArrayById(approvalList, [newReport]));
      }
    });
    detailsModal.open();
  };
  const tableScrollHandler = (
    event: React.UIEvent<HTMLTableSectionElement>
  ): void => {
    if (
      getData.loading ||
      !getData.payload?.data.docs ||
      !getData.payload?.data.docs.length
    )
      return;
    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight - 50
    ) {
      get(EndPoints.Approvals, {
        bookmark: getData.payload?.data.bookmark,
      });
    }
  };

  // Page Elements
  const rowEvents: TableRowEvents = {
    onClick: rowClickHandler,
  };
  const rowProps: TableRowProps = {
    style: {
      cursor: 'pointer',
    },
  };

  // Effects
  useEffect(() => {
    get(EndPoints.Approvals);
  }, []);

  useEffect(() => {
    if (getData.payload) {
      setApprovalList(mergeArrayById(approvalList, getData.payload.data.docs));
    }
  }, [getData]);

  useEffect(() => {
    setApprovalList([]);
    if (filteringUser?._id) {
      get(EndPoints.Approvals, {
        filter: 'user',
        filterValue: filteringUser._id,
      });
    } else {
      get(EndPoints.Approvals);
    }
  }, [filteringUser]);

  return (
    <ApprovalPageElement>
      <PageTitle>{Strings.PageTitle}</PageTitle>
      <div className="ui-grid-horizontal __search-container">
        <Select<User.Data>
          containerType="outline"
          data={getUsersData.payload?.data.docs || []}
          dataKey="name"
          loading={getUsersData.loading}
          onSearch={(value) =>
            getUsers(EndPoints.Users, {
              search: 'name',
              searchValue: value,
            })
          }
          onSelect={(event, selected) =>
            setFilteringUser(selected as User.Data)
          }
          placeholder={Strings.Inputs.SelectEmployee}
          value={filteringUser}
        />
      </div>

      <Table
        data={approvalList}
        config={{ rowEvents, rowProps, onScroll: tableScrollHandler }}
        loading={getData.loading}
      >
        <TableColumn
          key="dateTime"
          name={Strings.Table.ColumnTitles.Date}
          minwidth="130px"
          maxwidth="130px"
        >
          {({ dateTime, approval }: Withdrawal.Data) => (
            <ApprovalDateTimeCell className="ui-grid-vertical">
              <span>
                {dateTime.request
                  ? new Date(dateTime.request).toLocaleString('pt-br')
                  : '-'}
              </span>
              {approval?.status === Withdrawal.ApprovalStatus.APPROVED && (
                <Badge text="Aprovado" color="success" className="__badge" />
              )}
              {approval?.status === Withdrawal.ApprovalStatus.NOT_APPROVED && (
                <Badge text="Reprovado" color="danger" className="__badge" />
              )}
            </ApprovalDateTimeCell>
          )}
        </TableColumn>
        <TableColumn
          key="employee"
          name={Strings.Table.ColumnTitles.Employee}
          flex={1}
          minwidth="350px"
          maxwidth="350px"
        >
          {({ userReceive }: Withdrawal.Data) => (
            <ApprovalUserCell className="ui-grid-vertical">
              <h2>{userReceive.user?.name}</h2>
              <span>{userReceive.user?.identification}</span>
            </ApprovalUserCell>
          )}
        </TableColumn>
        <TableColumn
          key="quantity"
          name={Strings.Table.ColumnTitles.Quantity}
          minwidth="100px"
          maxwidth="100px"
        >
          {({ quantity }: Withdrawal.Data) => (
            <ApprovalQuantityCell className="ui-grid-vertical">
              <h2>{quantity}</h2>
            </ApprovalQuantityCell>
          )}
        </TableColumn>
        <TableColumn
          key="product"
          name={Strings.Table.ColumnTitles.Product}
          flex={1}
          minwidth="350px"
          maxwidth="350px"
        >
          {({ product }: Withdrawal.Data) => (
            <ApprovalProductCell className="ui-grid-vertical">
              <h2>{product?.name}</h2>
              <span>{product?.size?.size}</span>
            </ApprovalProductCell>
          )}
        </TableColumn>
      </Table>
    </ApprovalPageElement>
  );
}
