import { Input, Tabs, useToast } from '@keymax-dev/smartepi-ui';
/* eslint-disable-next-line max-len */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  WithActiveDetails,
  WithActiveDetailsContentProps,
} from '../../../components/details-section';
import { Addon, Customer } from '../../../domain/models';
import { EndPoints, RouterPaths } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import LicenseTab from './license-tab';
import MachineTab from './machine-tab';
import SettingsTab from './settings-tab';
import { CustomerDetailsElement } from './style';

function CustomerDetails(
  props: WithActiveDetailsContentProps<
    Customer.Data & { addons: Addon.Addon[] }
  >
): JSX.Element {
  // Hooks
  const {
    currentState: [customer, setCustomer, updateCustomerValue],
    loading: updateLoading,
    requestError,
  } = props.restUpdate;

  const errorToast = useToast(<span />, { color: 'danger' });

  // Effects
  useEffect(() => {
    if (requestError) {
      errorToast.setContent(getErrorMessage(requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [requestError]);

  return (
    <CustomerDetailsElement>
      <header className="__title-header">
        <Input
          disabled={updateLoading}
          iconRight="pencil"
          invert
          onBlur={(event) =>
            updateCustomerValue({
              fancyName: event.currentTarget.value,
            })
          }
          onChange={(event) =>
            setCustomer({
              ...customer,
              fancyName: event.currentTarget.value,
            })
          }
          onKeyDown={(event) =>
            event.key === 'Enter' &&
            updateCustomerValue({
              fancyName: event.currentTarget.value,
            })
          }
          value={customer.fancyName}
        />
        <Input
          disabled={updateLoading}
          iconRight="pencil"
          invert
          type="number"
          onBlur={(event) =>
            updateCustomerValue({
              licenses: Number(event.currentTarget.value),
            })
          }
          onChange={(event) =>
            setCustomer({
              ...customer,
              licenses: Number(event.currentTarget.value),
            })
          }
          onKeyDown={(event) =>
            event.key === 'Enter' &&
            updateCustomerValue({
              licenses: Number(event.currentTarget.value),
            })
          }
          value={customer.licenses}
        />
      </header>
      <Tabs>
        <MachineTab title="Máquinas" />
        <LicenseTab title="Licenças" />
        <SettingsTab title="Configurações" restUpdate={props.restUpdate} />
      </Tabs>
    </CustomerDetailsElement>
  );
}

export default (): JSX.Element =>
  WithActiveDetails(CustomerDetails, {
    id: useParams<{ id: string }>().id,
    endpoint: EndPoints.Customer,
    cleanRouterPath: RouterPaths.Customer(),
  });
