import { Button, Icon } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { NotFoundElement } from './style';

export default function ItemNotFound(): JSX.Element {
  const history = useHistory();

  const backButtonClickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    const newPath = history.location.pathname.split('/').slice(0, 2).join('/');
    history.replace(newPath);
  };

  return (
    <NotFoundElement>
      <h1>Os detalhes do item não foram encontrados.</h1>
      <Icon name="fileSearch" />
      <div className="__divider" />
      <Button
        icon="chevronLeft"
        buttonType="outline"
        text="Voltar"
        onClick={backButtonClickHandler}
      />
    </NotFoundElement>
  );
}
