import { Icon, Spinners } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { MachineStatusCellElement } from './style';

interface MachineStatusCellProps extends React.ComponentProps<'div'> {
  status: number;
}

const Strings = {
  StatusName: ['Online', 'Conectando', 'Offline'],
};

export default function MachineStatusCell({
  status,
}: MachineStatusCellProps): JSX.Element {
  switch (status) {
    case 0:
      return (
        <MachineStatusCellElement className="__online">
          <Icon name="cloud" height="30px" />
          <span>{Strings.StatusName[0]}</span>
        </MachineStatusCellElement>
      );
    case 1:
      return (
        <MachineStatusCellElement className="__connecting">
          <Spinners.circles height="30px" />
          <span>{Strings.StatusName[1]}</span>
        </MachineStatusCellElement>
      );
    case 2:
    default:
      return (
        <MachineStatusCellElement className="__offline">
          <Icon name="cloudAlert" height="30px" />
          <span>{Strings.StatusName[2]}</span>
        </MachineStatusCellElement>
      );
  }
}
