import { DatabaseDocument } from '../../domain/models';
import { Button, Input } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { ListPage } from '../../hooks/use-list-page';
import { ListPageSearchbarElement } from './style';

type Strings = {
  placeholder: string;
  deleteTitle: string;
  deleteMessage: string;
};
interface ListPageSearchbarProps<T extends DatabaseDocument.Data> {
  listPage: ListPage<T>;
  strings: Strings;
  searchKey?: string;
}

export default function ListPageSearchBar<T extends DatabaseDocument.Data>({
  listPage,
  strings,
  searchKey,
}: ListPageSearchbarProps<T>): JSX.Element {
  return (
    <ListPageSearchbarElement className="ui-grid-horizontal">
      {!listPage.selectable && (
        <Button
          buttonType="icon"
          icon="selection"
          onClick={(): void => listPage.setSelectable(true)}
        />
      )}
      {listPage.selectable && (
        <Button
          buttonType="icon"
          icon="trash"
          onClick={() =>
            listPage.deleteSelectedHandler(
              strings.deleteTitle,
              strings.deleteMessage
            )
          }
        />
      )}
      {listPage.selectable && (
        <Button
          buttonType="icon"
          icon="close"
          onClick={(): void => listPage.setSelectable(false)}
        />
      )}
      <Input
        containerType="outline"
        iconRight="magnify"
        placeholder={strings.placeholder}
        onBlur={(event) => listPage.restList.search(event.currentTarget.value)}
        onKeyPress={(event) =>
          event.key === 'Enter'
            ? listPage.restList.search(event.currentTarget.value, searchKey)
            : undefined
        }
      />
    </ListPageSearchbarElement>
  );
}
