import {
  Input,
  ScrollableContainer,
  Spinners,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/page-title';
import useRest from '../../hooks/use-rest';
import { Machine } from '../../domain/models';
import { mergeArrayById } from '../../utils';
import { EndPoints } from '../../utils/constants';
import MachineCard from './machine-card';
import MachineDetailsModal from './machine-details';
import { MachinePageElement, MachinesMessageContainerElement } from './style';

const { useGet } = useRest();

const Strings = {
  Title: 'Máquinas',
  Inputs: {
    Search: 'Buscar máquina...',
  },
  Messages: {
    GetDataError: 'Não foi possível carregar a lista de máquinas.',
    LoadingData: 'Carregando máquinas...',
    EmptyData: 'Nenhuma máquina para ser exibida.',
  },
};

export default function MachinePage(): JSX.Element {
  const [getData, get] = useGet({ loading: true });
  const [searchingValue, setSearchingValue] = useState<string>('');
  const [machinesList, setMachinesList] = useState<Machine.Data[]>([]);
  const errorToast = useToast(<span />, { color: 'danger' });
  const detailsModal = useModal(<MachineDetailsModal />, {
    rootElement: '#machine-page',
    containerProps: { style: { padding: 0 } },
  });

  const machineClickHandler = (event: any, machine: Machine.Data): void => {
    detailsModal.injectProps({ machine });
    detailsModal.onClose(() => {
      setMachinesList(() => [...machinesList]);
    });
    detailsModal.open();
  };

  const searchHandler = (value: string): void => {
    if (value !== searchingValue) {
      if (value !== '') {
        setMachinesList([]);
        get(EndPoints.Machines, {
          search: 'name',
          searchValue: value,
        });
      } else {
        get(EndPoints.Machines);
      }
      setSearchingValue(value);
    }
  };
  const scrollHandler = (
    event: React.UIEvent<HTMLTableSectionElement>
  ): void => {
    if (
      getData.loading ||
      !getData.payload?.data.docs ||
      !getData.payload?.data.docs.length
    )
      return;
    if (
      event.currentTarget.offsetHeight + event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight - 50
    ) {
      get(EndPoints.Machines, {
        bookmark: getData.payload?.data.bookmark,
      });
    }
  };

  useEffect(() => {
    if (getData.payload) {
      setMachinesList(mergeArrayById(machinesList, getData.payload.data.docs));
    } else if (getData.error) {
      console.error(getData.error);
      errorToast.setContent(Strings.Messages.GetDataError);
      setTimeout(() => errorToast.open());
    }
  }, [getData]);

  useEffect(() => {
    get(EndPoints.Machines);
    const interval = setInterval(() => {
      get(EndPoints.Machines);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <MachinePageElement id="machine-page">
      <PageTitle>{Strings.Title}</PageTitle>
      <div className="ui-grid-horizontal __search-container">
        <Input
          containerType="outline"
          iconRight="magnify"
          placeholder={Strings.Inputs.Search}
          onBlur={(event) => searchHandler(event.target.value)}
          onKeyPress={(event) =>
            event.key === 'Enter'
              ? searchHandler((event.target as HTMLInputElement).value)
              : undefined
          }
        />
      </div>
      {machinesList.length > 0 && (
        <div className="__grid-card-container">
          <ScrollableContainer flexDirection="row" className="__grid-card">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                width: '100%',
                padding: '15px 5%',
              }}
              onScroll={scrollHandler}
            >
              {machinesList.map((machine) => (
                <MachineCard
                  key={machine._id}
                  machine={machine}
                  onClick={(event) => machineClickHandler(event, machine)}
                />
              ))}
            </div>
          </ScrollableContainer>
        </div>
      )}
      {getData.loading && (
        <MachinesMessageContainerElement>
          <Spinners.circles width="200px" height="200px" />
          {Strings.Messages.LoadingData}
        </MachinesMessageContainerElement>
      )}
      {machinesList.length === 0 && !getData.loading && (
        <MachinesMessageContainerElement>
          {Strings.Messages.EmptyData}
        </MachinesMessageContainerElement>
      )}
    </MachinePageElement>
  );
}
