import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ApprovalPageElement = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  .__search-container {
    .ui-select-container-outline {
      flex: 1;
    }
  }
`;

export const ApprovalUserCell = styled.div`
  h2 {
    margin: 0 5px;
    font-size: 1rem;
    font-weight: normal;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    font-size: 0.8rem;
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
  }
`;

export const ApprovalProductCell = styled.div`
  h2 {
    margin: 0 5px;
    font-size: 1rem;
    font-weight: normal;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    font-size: 0.8rem;
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
  }
`;

export const ApprovalQuantityCell = styled.div`
  h2 {
    margin: 0 5px;
    font-size: 1.5rem;
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ApprovalDateTimeCell = styled.div`
  text-align: center;
  padding: 0 10px;
  position: relative;
  .__badge {
    position: absolute;
    margin: 0;
    bottom: -10%;
    transition: ${() => getGlobalTheme().transitions.avarage};

    &:hover {
      transform: translateY(75%);
      cursor: default;
    }
  }
`;
