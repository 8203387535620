import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const LoadingPageElement = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: ${() => getGlobalTheme().colors.primary.principal};
    font-size: ${() => getGlobalTheme().font.h1.fontSize};
    font-weight: ${() => getGlobalTheme().font.h1.fontWeight};
  }
`;
