import {
  Button,
  Icon,
  Input,
  ModalController,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import ConfirmModal from '../../../components/confirm-modal';
import useRest from '../../../hooks/use-rest';
import { License, ErrorResponse } from '../../../domain/models';
import { EndPoints } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import { LicenseDetailsModalElement } from './style';

const Strings = {
  Buttons: {
    Delete: 'Desabilitar licença',
  },
  Inputs: {
    Name: 'Nome',
  },
  Title: 'Detalhes da licença',
  Messages: {
    Caution: 'Atenção!',
    DeleteMessage: (host?: string) =>
      `O acesso ao software já instalado em "${host}" será perdido. Deseja continuar?`,
    DeleteError: 'Não foi possível desabiltiar a licença.',
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    GetDataError: 'Não foi possível carregar o estoque.',
  },
};

interface MachineDetailsModalProps {
  controller?: ModalController;
  license?: License.Data;
}

const { usePut, useDelete } = useRest();

export default function LicenseDetailsModal({
  controller,
  license,
}: MachineDetailsModalProps): JSX.Element {
  const [putData, put] = usePut({ loading: false });
  const [delData, del] = useDelete({ loading: false });
  const [currentLicense, setCurrentLicense] = useState<License.Data>();
  const errorToast = useToast(<span />, { color: 'danger' });
  const confirmModal = useModal(<ConfirmModal />);

  const detailsChangeHandler = (event: any, target: 'name'): void => {
    if (currentLicense && license) {
      if (license[target] !== event.target.value) {
        put(EndPoints.License(currentLicense._id || ''), {
          [target]: event.target.value,
        });
      }
    }
  };
  const detailsUpdateSuccessHandler = (data: any): void => {
    if (license) {
      license.name = data.name;
    }
  };
  const detailsUpdateErrorHandler = (error: ErrorResponse): void => {
    setCurrentLicense(license as License.Data);
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };
  const deleteClickHandler = (): void => {
    confirmModal.injectProps({
      title: Strings.Messages.Caution,
      message: Strings.Messages.DeleteMessage(license?.host),
    });
    confirmModal.onClose((reason) => {
      if (reason === 'confirmed') {
        del(EndPoints.Licenses, { ids: [license?._id] });
      }
    });
    confirmModal.open();
  };
  const deleteSuccessHandler = (data: any): void => {
    if (controller) controller.close('deleted');
  };
  const deleteErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(Strings.Messages.DeleteError);
    setTimeout(() => errorToast.open());
  };

  useEffect(() => {
    if (!putData.loading) {
      if (putData.payload) detailsUpdateSuccessHandler(putData.payload.data);
      else if (putData.error) detailsUpdateErrorHandler(putData.error);
    }
  }, [putData]);

  useEffect(() => {
    if (!delData.loading) {
      if (delData.payload) deleteSuccessHandler(delData.payload.data);
      else if (delData.error) deleteErrorHandler(delData.error);
    }
  }, [delData]);

  useEffect(() => {
    if (license) {
      setCurrentLicense(license);
    }
  }, [license]);

  return (
    <LicenseDetailsModalElement>
      <header>
        <Icon name="desktop" height="10vh" width="10vh" invert />
        <span>{Strings.Inputs.Name}</span>
        <Input
          disabled={putData.loading}
          iconRight="pencil"
          invert
          onBlur={(event) => detailsChangeHandler(event, 'name')}
          onChange={(event) =>
            setCurrentLicense({
              ...(currentLicense as License.Data),
              name: event.target.value,
            })
          }
          onKeyDown={(event) =>
            event.key === 'Enter' && detailsChangeHandler(event, 'name')
          }
          value={currentLicense?.name}
          placeholder={Strings.Inputs.Name}
        />
      </header>

      <div className="ui-grid-vertical">
        <Button
          text={Strings.Buttons.Delete}
          icon="close"
          color="danger"
          onClick={deleteClickHandler}
        />
      </div>
    </LicenseDetailsModalElement>
  );
}
