import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const GroupProductCellElement = styled.div`
  color: ${() => getGlobalTheme().colors.primary.principal};

  h2 {
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.8);
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    margin: 0 5px;
  }

  span {
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.6);
    color: ${() => getGlobalTheme().colors.primary.principal}90;
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
  }

  .ui-grid-horizontal {
    padding: 0 5px;
    .ui-input-container-downline {
      flex: 1;
      min-width: unset;
      width: unset;
      margin: 0 5px;
      input {
        width: 100%;
        min-width: unset;
        text-align: center;
      }
    }
  }
`;
