import { Auth } from '../../domain/models';
import { DispatchAction } from '..';

export type AuthActionTypes = 'SET_AUTH' | 'CLEAR_AUTH';

const setAuth = (auth: Partial<Auth>): DispatchAction => {
  return {
    type: 'SET_AUTH',
    payload: { auth },
  };
};

const clearAuth = (): DispatchAction => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  return {
    type: 'CLEAR_AUTH',
    payload: { auth: undefined },
  };
};

const AuthActions = { setAuth, clearAuth };

export default AuthActions;
