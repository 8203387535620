import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ReportsPageElement = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  h2 {
    font-size: ${() => getGlobalTheme().font.h2.fontSize};
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    text-align: center;
    margin: 15px;
  }

  .__scrollable-container {
    position: relative;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .__table-container {
    position: relative;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    tr {
      max-width: 100%;
    }

    .ui-grid-horizontal {
      margin: 3px 0;
    }
    .ui-grid-vertical {
      margin: 3px 0;
      padding: 0 3px;
      width: 100% !important;
    }
    .ui-table-body {
      padding-bottom: 30px;
      tr {
        padding: 10px 0;

        transition: all ${() => getGlobalTheme().transitions.slow};

        &:nth-child(2n) {
          background-color: ${() =>
            getGlobalTheme().colors.primary.principal}0F;
        }

        &:hover {
          background-color: ${() =>
            getGlobalTheme().colors.primary.principal}24;
        }

        td {
          padding: 0;
          border-right: 1px solid
            ${() => getGlobalTheme().colors.primary.principal}32;
          &:last-child {
            border-color: transparent;
          }
        }
      }
    }

    .__table-footer {
      min-height: 60px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: ${() => getGlobalTheme().boxShadow.normal};

      .ui-input-container-downline {
        min-width: 100px;
        width: 100px;
        input {
          width: 100%;
          text-align: center;
        }
      }
      span {
        font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.6);
        font-weight: normal;
        padding: 3px 0;
      }

      button > span {
        font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.4);
        font-weight: normal;
        padding: 3px 6px;
      }

      .__division-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
      }

      .__pager-container {
        width: 160px;
        border-left: 1px solid
          ${() => getGlobalTheme().colors.primary.principal}32;
        span {
          font-size: ${() => getGlobalTheme().font.h2.fontSize};
          line-height: 1;
          font-weight: bold;
          margin: -2px 0 0 0;
          padding: 0;
        }
      }
    }
  }

  .__report-filter-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .__report-filter-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      margin: 15px;
    }
  }
`;

export const BaseReportTableCell = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-wrap: wrap;

  .__main-text {
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.6);
    color: ${() => getGlobalTheme().colors.primary.principal};
    line-height: 1;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .__sub-text {
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.55);
    color: ${() => getGlobalTheme().colors.primary.principal}64;
  }

  .__bold {
    font-weight: bold;
  }
`;
