import React from 'react';
import { useModal, useToast } from '@keymax-dev/smartepi-ui';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiDataRequest, DatabaseDocument } from '../domain/models';
import { getErrorMessage } from '../utils/error-messages';
import useRest from './use-rest';
import ConfirmModal from '../components/confirm-modal';

const { useDelete } = useRest();

export interface RestItemDeleteProps<T extends DatabaseDocument.Data> {
  endpoint: string;
  routerPath: string;
  item: T;
  title: string;
  message: string;
}

export interface RestItemDelete {
  deleteItem: () => void;
  deleteData: ApiDataRequest;
  deleteClickHandler: () => void;
}

export const useRestItemDelete = <T extends DatabaseDocument.Data>({
  endpoint,
  item,
  routerPath,
  title,
  message,
}: RestItemDeleteProps<T>): RestItemDelete => {
  const [deleteData, del] = useDelete({ loading: false });
  const history = useHistory();
  const errorToast = useToast(<span />, { color: 'danger' });
  const deleteModal = useModal(<ConfirmModal />);

  useEffect(() => {
    if (deleteData.payload) {
      history.replace(routerPath, {
        toRemove: { ...item, _deleted: true },
      });
    } else if (deleteData.error) {
      errorToast.setContent(getErrorMessage(deleteData.error));
      setTimeout(() => errorToast.open());
    }
  }, [deleteData]);

  const deleteItem: RestItemDelete['deleteItem'] = () => {
    del(endpoint, { ids: [item._id] });
  };

  const deleteClickHandler = (): void => {
    deleteModal.injectProps({
      title,
      message,
    });
    deleteModal.onClose((reason) => {
      if (reason === 'confirmed') {
        deleteItem();
      }
    });
    deleteModal.open();
  };

  return { deleteItem, deleteData, deleteClickHandler };
};
