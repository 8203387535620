import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const LicenseDetailsModalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 800px;
  max-width: 100%;

  header {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    background-color: ${() => getGlobalTheme().colors.primary.principal};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 5% 30px 5%;

    span {
      color: ${() => getGlobalTheme().colors.primary.contrast}32;
      margin-bottom: -10px;
    }
  }

  .ui-grid-vertical {
    margin: 30px 10px;
  }
`;
