import { Button, Icon } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { NotFoundElement } from './style';

export default function PageNotFound(): JSX.Element {
  const history = useHistory();

  const backButtonClickHandler = (): void => {
    history.goBack();
  };

  return (
    <NotFoundElement>
      <h1>A página não foi encontrada</h1>
      <Icon name="fileSearch" />
      <div className="__divider" />
      <Button
        icon="chevronLeft"
        buttonType="outline"
        text="Voltar"
        onClick={backButtonClickHandler}
      />
    </NotFoundElement>
  );
}
