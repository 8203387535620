import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const MachinePageElement = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  .__search-container .ui-input-container-outline {
    margin: 0 15px;
  }

  .__grid-card-container {
    flex: 1;
    width: 100%;
    position: relative;
    .__grid-card {
      justify-content: space-around;
      align-items: flex-start;
      flex-wrap: wrap;

      h2 {
        -webkit-line-clamp: 2;
      }

      .__description {
        font-size: calc(${() => getGlobalTheme().font.h2.fontSize} / 2);
        -webkit-line-clamp: 3;
      }

      .__card {
        margin: 20px;
        transition: ${() => getGlobalTheme().transitions.avarage};

        &:active {
          transform: scale(0.95);
        }
      }

      .__card-active {
        border: 3px solid ${() => getGlobalTheme().colors.success.principal};
        filter: drop-shadow(
          0 0 5px ${() => getGlobalTheme().colors.success.principal}
        );
        will-change: filter;

        &:hover {
          filter: none;
        }
      }

      .__card-inactive {
        border: 3px solid ${() => getGlobalTheme().colors.primary.contrast}A0;
        filter: drop-shadow(
          0 0 5px ${() => getGlobalTheme().colors.primary.principal}
        );
        will-change: filter;

        &:hover {
          filter: none;
        }
      }
    }
  }

  .ui-modal-btn-close {
    path {
      fill: ${() => getGlobalTheme().colors.primary.contrast};
    }
  }

  .__badge {
    transition: ${() => getGlobalTheme().transitions.avarage};
    will-change: transform, box-shadow;

    &.__hover {
      font-weight: bold;
      box-shadow: ${() => getGlobalTheme().boxShadow.normal};
    }

    .ui-badge-text {
      min-width: 160px;
      text-align: center;
    }
  }
`;

export const MachinesMessageContainerElement = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;
