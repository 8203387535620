import { Action, createStore, Reducer } from 'redux';
import { ActiveDetails, Auth, DatabaseDocument, View } from '../domain/models';
import { ActiveDetialsActionTypes } from './actions/active-details-actions';
import { AuthActionTypes } from './actions/auth-actions';
import { ViewActionTypes } from './actions/view-actions';

export type ActionTypes =
  | AuthActionTypes
  | ViewActionTypes
  | ActiveDetialsActionTypes;

export interface ReduxStore<
  T extends DatabaseDocument.Data = DatabaseDocument.Data
> {
  auth?: Partial<Auth>;
  view: Partial<View>;
  activeDetails: ActiveDetails<T>;
}

export interface DispatchAction extends Action<ActionTypes> {
  payload: Partial<ReduxStore>;
}

const initialState: ReduxStore = {
  auth: undefined,
  view: {
    main: true,
    section: false,
    sidebar: {
      enabled: false,
      opened: false,
    },
  },
  activeDetails: {},
};

// eslint-disable-next-line
const mergeState = (before: any, after: any): any => {
  if (typeof after !== 'object' || typeof before !== 'object') return after;
  if (Array.isArray(after)) return [...after];

  for (const obj in after) before[obj] = mergeState(before[obj], after[obj]);

  return Array.isArray(before) ? before : { ...before };
};

export const rootReducer: Reducer<ReduxStore, DispatchAction> = (
  state = initialState,
  action: DispatchAction
) => {
  if (!action.payload) {
    return state;
  } else {
    return mergeState(state, action.payload);
  }
};

export const rootStore = createStore<ReduxStore, DispatchAction, null, null>(
  rootReducer
);
