import { DatabaseDocument } from '../../domain/models';
import { Spinners } from '@keymax-dev/smartepi-ui';
import React from 'react';
import useActiveDetailsState, {
  ActiveDetailsStateProps,
} from '../../hooks/use-active-details-state';
import { RestUpdate } from '../../hooks/use-rest-update';
import { UpdatableContent } from './updatable-content';
import { DeletedBadge, DeletedCover, LoadingCover } from './styles';

const Strings = {
  DeletedMessage: 'Excluído',
};

export interface WithActiveDetailsContentProps<
  T extends DatabaseDocument.Data
> {
  restUpdate: RestUpdate<T>;
}

export const WithActiveDetails = <T extends DatabaseDocument.Data>(
  Content: React.FC<WithActiveDetailsContentProps<T>>,
  { cleanRouterPath, endpoint, id }: ActiveDetailsStateProps
): JSX.Element => {
  const [state, setState] = useActiveDetailsState<T>({
    id,
    endpoint,
    cleanRouterPath,
  });

  if (state) {
    return (
      <>
        <UpdatableContent
          Content={Content}
          endpoint={endpoint}
          state={[state, (newState: T) => setState(newState)]}
        />
        {state.docStatus === DatabaseDocument.Status.DELETED && (
          <DeletedCover />
        )}
        {state.docStatus === DatabaseDocument.Status.DELETED && (
          <DeletedBadge
            color="danger"
            text={Strings.DeletedMessage}
            icon="trash"
          />
        )}
      </>
    );
  } else {
    return (
      <LoadingCover>
        <Spinners.circles width="200px" height="200px" />
      </LoadingCover>
    );
  }
};
