import { Badge, CardBase, Icon } from '@keymax-dev/smartepi-ui';
import { HTMLMotionProps } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Machine } from '../../domain/models';

const Strings = {
  Status: {
    Online: 'Online',
    Offline: 'Offline',
    Maintenance: 'Manutenção',
  },
};

interface MachineCardProps extends HTMLMotionProps<'div'> {
  machine: Machine.Data;
}

const CARD_SIZE = '280px';

export default function MachineCard({
  machine,
  ...props
}: MachineCardProps): JSX.Element {
  const [hover, setHover] = useState(false);
  const [badgeText, setBadgeText] = useState('');

  const getStatusByLastKeepAlive = (): boolean => {
    if (!machine.lastKeepAlive) return false;
    const lastKeepAliveDate = new Date(machine?.lastKeepAlive);
    // const lastKeepAliveDate = new Date();
    const dateToCompare = new Date();
    dateToCompare.setMinutes(dateToCompare.getMinutes() - 2);
    return dateToCompare > lastKeepAliveDate ? false : true;
  };

  const hoverStartHandler = (): void => {
    if (!machine.lastKeepAlive) return;
    setHover(true);
    const lastKeepAliveDate = new Date(machine?.lastKeepAlive);
    // const lastKeepAliveDate = new Date();
    setBadgeText(lastKeepAliveDate.toLocaleString());
  };

  const hoverEndHandler = (): void => {
    setHover(false);
    setBadgeText(
      getStatusByLastKeepAlive()
        ? Strings.Status.Online
        : Strings.Status.Offline
    );
  };

  useEffect(() => {
    setBadgeText(
      getStatusByLastKeepAlive()
        ? Strings.Status.Online
        : Strings.Status.Offline
    );
  }, []);

  return (
    <CardBase
      width={CARD_SIZE}
      height={'260px'}
      {...props}
      className={`__card __card-${
        getStatusByLastKeepAlive() ? 'active' : 'inactive'
      } ${props.className || ''}`}
      onHoverStart={hoverStartHandler}
      onHoverEnd={hoverEndHandler}
    >
      <Icon name="desktopTower" height="100px" width="100px" invert />
      <h2>{machine.name}</h2>
      <span className="__description">{machine.observation}</span>
      <footer>
        {getStatusByLastKeepAlive() && (
          <Badge
            className={`__badge ${hover && '__hover'}`}
            text={badgeText}
            color="success"
          />
        )}
        {!getStatusByLastKeepAlive() && (
          <Badge
            className={`__badge ${hover && '__hover'}`}
            text={badgeText}
            color="primary"
          />
        )}
      </footer>
    </CardBase>
  );
}
