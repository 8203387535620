import { Button } from '@keymax-dev/smartepi-ui';
import React from 'react';
import PageTitle from '../../../components/page-title';
import { FilePaths } from '../../../utils/constants';
import { DownloadSoftwareModalElement } from './style';

const Strings = {
  Buttons: {
    Windows: 'Microsoft Windows (.exe)',
    // Linux: 'Distribuições Linux (.deb)'
  },
  Messages: {
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    DownloadMessage:
      'Selecione o sistema operacional para fazer o download do software mais recente.',
  },
  Title: 'Download do Software',
};

export default function DownloadSoftwareModal(): JSX.Element {
  const windowsDownload = (): void => {
    const url = FilePaths.Software('SmartEPI-Setup.exe');
    window.open(url, '__blank');
  };

  return (
    <DownloadSoftwareModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      <h2>{Strings.Messages.DownloadMessage}</h2>

      <div className="ui-grid-vertical">
        <Button
          text={Strings.Buttons.Windows}
          onClick={windowsDownload}
          icon="microsoftWindows"
        />
        {/* <Button text={Strings.Buttons.Linux} onClick={linuxDownload} icon="linux" /> */}
      </div>
    </DownloadSoftwareModalElement>
  );
}
