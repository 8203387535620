import { Icon, Spinners } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { LicenseStatusCellElement } from './style';

interface MachineStatusCellProps extends React.ComponentProps<'div'> {
  status: number;
}

export default function LicenseStatusCell({
  status,
}: MachineStatusCellProps): JSX.Element {
  switch (status) {
    case 0:
      return (
        <LicenseStatusCellElement className="__online">
          <Icon name="cloud" height="30px" />
          <span>Online</span>
        </LicenseStatusCellElement>
      );
    case 1:
      return (
        <LicenseStatusCellElement className="__connecting">
          <Spinners.circles height="30px" />
          <span>Conectando</span>
        </LicenseStatusCellElement>
      );
    case 2:
    default:
      return (
        <LicenseStatusCellElement className="__offline">
          <Icon name="cloudAlert" height="30px" />
          <span>Offline</span>
        </LicenseStatusCellElement>
      );
  }
}
