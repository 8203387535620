import React from 'react';
import { MachineInfoCellElement } from './style';

interface InfoCellProps extends React.ComponentProps<'div'> {
  observation: string;
  name: string;
}

export default function MachineInfoCell({
  observation,
  name,
}: InfoCellProps): JSX.Element {
  return (
    <MachineInfoCellElement>
      <h2>{name}</h2>
      <span>{observation}</span>
    </MachineInfoCellElement>
  );
}
