import {
  Button,
  ImageAvatar,
  Input,
  Tabs,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  WithActiveDetails,
  WithActiveDetailsContentProps,
} from '../../../components/details-section';
import MediaFileModal from '../../../components/media-file-modal';
import PageActions from '../../../components/page-actions';
import { Product } from '../../../domain/models';
import { useRestItemDelete } from '../../../hooks/use-rest-item-delete';
import { EndPoints, FilePaths, RouterPaths } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import ProductDataTab from './product-data-tab';
import ProductSizesTab from './product-sizes-tab';
import {
  ProductDetailsElement,
  ProductDetailsHeader,
  TabsContainer,
} from './style';

const Strings = {
  Buttons: {
    Manual: 'Manual de Uso',
    Tutorial: 'Tutorial de Uso',
    Delete: 'Remover Produto',
  },
  Inputs: {
    Name: 'Nome',
    Observation: 'Observação',
  },
  Title: 'Detalhes da Máquina',
  Table: {
    ColumnTitles: {
      Product: 'Produto',
      Channel: 'Esteira',
      Pic: 'Imagem',
    },
    ProductCell: {
      Quantity: 'Quantidade',
    },
    Messages: {
      Loading: 'Carregando estoque...',
      Empty: 'Nenhum item em estoque.',
    },
  },
  Messages: {
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    GetDataError: 'Não foi possível carregar o estoque.',
    Blocked: 'Bloqueada',
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar o produto?',
  },
  Tabs: {
    Data: 'Detalhes',
    Sizes: 'Tamanhos',
  },
};

const ProductDetails = (
  props: WithActiveDetailsContentProps<Product.Data>
): JSX.Element => {
  // Hooks
  const {
    currentState: [product, setProduct, updateProductValue],
    loading: updateLoading,
    requestError,
  } = props.restUpdate;

  const { deleteClickHandler } = useRestItemDelete({
    endpoint: EndPoints.Products,
    item: product,
    routerPath: RouterPaths.Products,
    title: Strings.Messages.Caution,
    message: Strings.Messages.DeleteConfirmation,
  });

  const errorToast = useToast(<span />, { color: 'danger' });
  const uploadFileModal = useModal(
    <MediaFileModal id="default" fileType="18" />
  );

  // Handlers
  const uploadPictureHandler = (): void => {
    uploadFileModal.injectProps({
      fileType: '18',
      currentFile: product.pathImage
        ? FilePaths.ProductImage(product.pathImage)
        : undefined,
      id: product._id,
    });
    uploadFileModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const pathImage = (reason as { file: string }).file + `?${Date.now()}`;
        setProduct({ ...product, pathImage });
      }
    });
    uploadFileModal.open();
  };

  const uploadVideoHandler = (): void => {
    uploadFileModal.injectProps({
      fileType: '20',
      currentFile: product.pathVideo
        ? FilePaths.ProductVideo(product.pathVideo)
        : undefined,
      id: product._id,
    });
    uploadFileModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const pathVideo = (reason as { file: string }).file + `?${Date.now()}`;
        setProduct({ ...product, pathVideo });
      }
    });
    uploadFileModal.open();
  };

  const uploadManualHandler = (): void => {
    uploadFileModal.injectProps({
      fileType: '19',
      currentFile: product.pathManual
        ? FilePaths.ProductManual(product.pathManual)
        : undefined,
      id: product._id,
    });
    uploadFileModal.onClose((reason) => {
      if (typeof reason === 'object') {
        const pathManual = (reason as { file: string }).file + `?${Date.now()}`;
        setProduct({ ...product, pathManual });
      }
    });
    uploadFileModal.open();
  };

  // Page Elements
  const actions = [
    {
      text: Strings.Buttons.Manual,
      icon: 'file',
      onClick: uploadManualHandler,
    },
    {
      text: Strings.Buttons.Tutorial,
      icon: 'video',
      onClick: uploadVideoHandler,
    },
    {
      text: Strings.Buttons.Delete,
      icon: 'trash',
      onClick: deleteClickHandler,
    },
  ];

  // Effects
  useEffect(() => {
    if (requestError) {
      errorToast.setContent(getErrorMessage(requestError.error));
      setTimeout(() => errorToast.open());
    }
  }, [requestError]);

  return (
    <ProductDetailsElement>
      <ProductDetailsHeader>
        <div className="__picture-edit-container">
          <ImageAvatar
            src={
              product.pathImage
                ? FilePaths.ProductImage(product.pathImage)
                : undefined
            }
            size="100px"
          />
          <Button
            buttonType="icon"
            icon="pencil"
            iconSize="20px"
            onClick={uploadPictureHandler}
          />
        </div>
        <PageActions actions={actions} invert />
        <Input
          disabled={updateLoading}
          iconRight="pencil"
          invert
          onBlur={(event) =>
            updateProductValue({ name: event.currentTarget.value })
          }
          onChange={(event) =>
            setProduct({ ...product, name: event.target.value })
          }
          onKeyDown={(event) =>
            event.key === 'Enter' &&
            updateProductValue({ name: event.currentTarget.value })
          }
          value={product.name}
          placeholder={Strings.Inputs.Name}
        />
      </ProductDetailsHeader>
      <TabsContainer>
        <Tabs>
          <ProductDataTab
            title={Strings.Tabs.Data}
            productRestUpdate={{
              currentState: [product, setProduct, updateProductValue],
              loading: updateLoading,
              requestError,
            }}
          />
          <ProductSizesTab
            title={Strings.Tabs.Sizes}
            productRestUpdate={{
              currentState: [product, setProduct, updateProductValue],
              loading: updateLoading,
              requestError,
            }}
          />
        </Tabs>
      </TabsContainer>
    </ProductDetailsElement>
  );
};

export default (): JSX.Element =>
  WithActiveDetails(ProductDetails, {
    id: useParams<{ id: string }>().id,
    endpoint: EndPoints.Product,
    cleanRouterPath: RouterPaths.Product(),
  });
