import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from './index';

export const SidebarElement = styled(motion.div)`
  width: ${() => SIDEBAR_WIDTH}px;
  color: ${() => getGlobalTheme().colors.primary.contrast};
  background-color: ${() => getGlobalTheme().colors.primary.principal};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99;

  filter: drop-shadow(
    0px 0px 5px ${() => getGlobalTheme().colors.primary.principal}72
  );

  .__sidebar-toggle {
    position: absolute;
    top: 0;
    right: -50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${() => getGlobalTheme().colors.primary.principal};
    background-color: ${() => getGlobalTheme().colors.primary.principal};
  }
`;

export const SidebarContentElement = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .__avatar-container {
    height: fit-content;
    padding: 10px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      color: ${() => getGlobalTheme().colors.primary.contrast};
      text-align: center;
      font-size: ${() => getGlobalTheme().font.h2.fontSize};
      font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
      margin: 5px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    h3 {
      color: ${() => getGlobalTheme().colors.primary.contrast}64;
      text-align: center;
      font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.7);
      font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
      margin: 5px 5px 0px 5px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .__nav-container {
    flex: 1;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ${() => getGlobalTheme().colors.primary.contrast}32;
    overflow: auto;

    direction: rtl;
    float: left;
  }

  .__footer-container {
    min-height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;

    svg {
      fill: ${() => getGlobalTheme().colors.primary.contrast};
    }
  }
`;

export const SidebarLinkElement = styled(NavLink)`
  width: 100%;
  padding: 5px;
  position: relative;
  z-index: 100;

  color: ${() => getGlobalTheme().colors.primary.contrast}32;
  text-align: center;
  font-size: ${() => getGlobalTheme().font.h2.fontSize};
  font-weight: ${() => getGlobalTheme().font.h2.fontWeight};

  &.__current-path {
    color: ${() => getGlobalTheme().colors.primary.contrast};
    font-weight: bolder;
  }
`;
