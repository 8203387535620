import { useEffect, useState } from 'react';
import { DatabaseDocument } from '../domain/models';
import { ErrorResponse, RequestFilter, RequestSearch } from '../domain/models';
import { mergeArrayById } from '../utils';
import useRest from './use-rest';

enum ErrorCodes {
  Get,
  Delete,
}

interface GetParams {
  bookmark?: string;
  newRequestSearch?: RequestSearch;
}

interface RequestError {
  code: ErrorCodes;
  error: ErrorResponse;
}

export interface RestListConfigurations {
  requestFilters?: RequestFilter[];
}
export interface RestList<T> {
  list: T[];
  loading: boolean;
  search: (value: string, searchKey?: string) => void;
  deleteItems: (items: T[]) => void;
  updateItems: (items: T[]) => void;
  nextBookmark: () => void;
  requestError: RequestError | undefined;
}

const { useGet, useDelete } = useRest();

let runningBookmark = false;

function useRestList<T extends DatabaseDocument.Data>(
  endpoint: string,
  { requestFilters = [] }: RestListConfigurations = {}
): RestList<T> {
  const [list, setList] = useState<T[]>([]);
  const [getData, get] = useGet({ loading: true });
  const [delData, del] = useDelete({ loading: false });
  const [requestSearch, setRequestSearch] = useState<RequestSearch>({
    search: 'name',
    searchValue: '',
  });
  const [deletingIds, setDeletingIds] = useState<Set<string>>(new Set());
  const [requestError, setRequestError] = useState<RequestError>();

  const getRequest = ({ newRequestSearch, bookmark }: GetParams = {}): void => {
    let params = {};

    if (newRequestSearch) {
      if (newRequestSearch.searchValue) {
        params = { ...params, ...newRequestSearch };
      }
      setRequestSearch(newRequestSearch);
    } else if (requestSearch && requestSearch.searchValue) {
      params = { ...params, ...requestSearch };
    }

    if (requestFilters.length > 0) {
      requestFilters.forEach((filter) => (params = { ...params, ...filter }));
    }

    if (bookmark) {
      params = { ...params, bookmark };
    }

    get(endpoint, Object.keys(params).length > 0 ? params : undefined);
  };

  const deleteItems = (items: T[]): void => {
    const ids = items.reduce(
      (prev, cur) => (cur._id ? [...prev, cur._id] : prev),
      [] as string[]
    );
    del(endpoint, { ids });
    setDeletingIds(new Set(ids));
  };

  const updateItems = (items: T[]): void => {
    setList(mergeArrayById(list, items));
  };

  const search = (searchValue: string, search = 'name'): void => {
    if (searchValue !== requestSearch.searchValue) {
      setList([]);
      getRequest({ newRequestSearch: { search, searchValue } });
    }
  };

  const nextBookmark = (): void => {
    if (
      runningBookmark ||
      delData.loading ||
      !getData.payload?.data.docs.length
    )
      return;
    runningBookmark = true;
    getRequest({ bookmark: getData.payload?.data.bookmark });
  };

  useEffect(() => {
    if (getData.payload) {
      setList(mergeArrayById(list, getData.payload.data.docs));
      setRequestError(undefined);
    } else if (getData.error) {
      setRequestError({ code: ErrorCodes.Get, error: getData.error });
    }
    runningBookmark = getData.loading;
  }, [getData]);

  useEffect(() => {
    if (delData.payload) {
      setList(list.filter((item) => !(item._id && deletingIds.has(item._id))));
      setRequestError(undefined);
    } else if (delData.error) {
      setRequestError({ code: ErrorCodes.Delete, error: delData.error });
    }
  }, [delData]);

  useEffect(() => {
    setList([]);
    getRequest();
  }, [endpoint, ...requestFilters.map((filter) => filter.filterValue)]);

  return {
    deleteItems,
    list,
    loading: getData.loading || delData.loading,
    nextBookmark,
    requestError,
    search,
    updateItems,
  };
}

export default useRestList;
