import { Input } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { RestUpdate } from '../../../hooks/use-rest-update';
import { Product } from '../../../domain/models';
import { ProductDataTabElement } from './style';

interface ProductDataTabProps {
  title: string;
  productRestUpdate: RestUpdate<Product.Data>;
}

const Strings = {
  Inputs: {
    Certificate: 'Certificado',
    UseValidity: 'Validade (dias)',
  },
  Messages: {
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
  },
};

export default function ProductDataTab({
  title,
  productRestUpdate: {
    currentState: [product, setProduct, updateProductValue],
    loading,
  },
}: ProductDataTabProps): JSX.Element {
  return (
    <ProductDataTabElement title={title} className="__product-data-tab">
      <Input
        disabled={loading}
        iconRight="pencil"
        onBlur={(event) =>
          updateProductValue({
            useValidity: event.currentTarget.valueAsNumber,
          })
        }
        onChange={(event) =>
          setProduct({
            ...product,
            useValidity: event.currentTarget.valueAsNumber,
          })
        }
        onKeyDown={(event) =>
          event.key === 'Enter' &&
          updateProductValue({
            useValidity: event.currentTarget.valueAsNumber,
          })
        }
        value={product.useValidity}
        placeholder={Strings.Inputs.UseValidity}
        type="number"
        min={1}
      />
      <Input
        disabled={loading}
        iconRight="pencil"
        onBlur={(event) =>
          updateProductValue({
            certificate: event.currentTarget.value,
          })
        }
        onChange={(event) =>
          setProduct({
            ...product,
            certificate: event.currentTarget.value,
          })
        }
        onKeyDown={(event) =>
          event.key === 'Enter' &&
          updateProductValue({
            certificate: event.currentTarget.value,
          })
        }
        value={product.certificate}
        placeholder={Strings.Inputs.Certificate}
      />
    </ProductDataTabElement>
  );
}
