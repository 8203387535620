import { BadParams, ErrorResponse } from '../domain/models';

const ErrorCodes = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  Conflict: 409,
};

const Strings = {
  Unauthorized: 'Autorização negada.',
  UnknownError:
    'Ocorreu um erro inesperado. Por favor, verifique sua internet ou entre em contato com o administrador.',
  NotFound: 'A requisição não foi encontrada.',
};

const BadParamsString = (badParams: BadParams): string[] => {
  const errors: string[] = [];
  for (const key in badParams) {
    const param = badParams[key];
    if (typeof param === 'string') {
      const [type, value] = param.split(/=| /g);
      switch (type) {
        case 'minLength':
          errors.push(
            `O valor do parâmetro "${key}" deve ter no mínimo ${value}.`
          );
          break;
        case 'maxLength':
          errors.push(
            `O valor do parâmetro "${key}" deve ter no máximo ${value}.`
          );
          break;
        case 'required':
          errors.push(`O parâmetro "${key}" é obrigatório.`);
          break;
        case 'pattern':
          errors.push(
            `O valor do parâmetro "${key}" não está no padrão correto.`
          );
          break;
        case 'invalidArrayIndex':
          errors.push(`O índice "${value}" do parâmetro "${key}" é inválido.`);
          break;
        case 'invalidId':
          errors.push(`O valor do parâmetro "${key}" não é um id válido.`);
          break;
        case 'type':
          errors.push(`O valor do parâmetro "${key}" não é do tipo correto.`);
          break;
        default:
          errors.push(`O parâmetro "${key}" não está no formato correto.`);
      }
    } else {
      errors.push(...BadParamsString(param));
    }
  }
  return errors;
};

const ForbiddenString = (type?: string, message?: string): string => {
  switch (type) {
    case 'InsertFail':
      if (message && message == 'Trigger type already exists for the customer')
        return 'Já existe um alerta de mesmo tipo cadastrado.';
      return `Houve falha na inserção, por favor tente novamente.`;
    case 'BulkFail':
      return `Houve falha ao executar a operação, por favor tente novamente.`;
    case 'NotFound':
      return `Houve falha para encontrar o documento especificado.`;
    case 'EmailRequired':
      return `O e-mail é obrigatório.`;
    case 'EmailConflict':
      return `O e-mail já está sendo utilizado.`;
    case 'IdentificationConflict':
      return `A identificação ja está sendo utilizada`;
    case 'InvalidCredentials':
      return `As credenciais de acesso estão inválidas.`;
    case 'UnauthorizedLicenseRelease':
      return `Você não tem permissão para executar esta operação.`;
    case 'InvalidDocumentReference':
      return `A referência do documento é inválida.`;
    default:
      return `Houve um erro inesperado. Por favor, verifique sua internet ou entre em contato com o administrador.`;
  }
};

export const getErrorMessage = (errorResponse: ErrorResponse): string => {
  if (!errorResponse.response) return Strings.UnknownError;

  switch (errorResponse.response.status) {
    case ErrorCodes.Unauthorized:
      return Strings.Unauthorized;
    case ErrorCodes.BadRequest:
      const badParams = errorResponse.response.data.badParams;
      if (badParams) {
        return BadParamsString(badParams).join(' ');
      } else {
        return Strings.UnknownError;
      }
    case ErrorCodes.NotFound:
      return Strings.NotFound;
    case ErrorCodes.Forbidden:
      return ForbiddenString(
        errorResponse.response.data.type,
        errorResponse.response.data.message
      );
    case ErrorCodes.Forbidden:
    default:
      return Strings.UnknownError;
  }
};
