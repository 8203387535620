import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SheetPageElement = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  position: relative;

  span {
    flex: 1;
    margin: 0 15px;
  }

  h2 {
    margin: 30px 15px 15px 15px;
    padding: 5px;
    font-size: ${() => getGlobalTheme().font.h2.fontSize};
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    border-bottom: 1px solid ${() => getGlobalTheme().colors.primary.principal};
    width: calc(70% - 20px);
    text-align: center;
  }

  .__input-row {
    margin-top: 10px;
    min-width: 50%;
    max-width: calc(100% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .__input-color {
      background-color: transparent;
      border: none;
      width: 40px;
      height: 40px;
    }

    .ui-input-container-downline {
      width: calc(100% - 40px);
      input {
        text-align: center;
      }
    }
    .ui-textarea-container-downline {
      width: fit-content;
      textarea {
        width: 700px;
        min-width: 300px;
        min-height: 200px;
      }
    }

    .__declaration-label {
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      z-index: 10;
      color: ${() => getGlobalTheme().colors.primary.principal}64;
      font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.5);
    }
  }

  .__scroll-container {
    position: relative;
    flex: 1;
    width: 100%;

    .__scroll-content {
      width: 100%;
      max-width: 1024px;
      margin-bottom: 70px;

      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .__checkbox-column {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    width: 100%;
    margin: 15px 0;
  }

  .__checkbox-row {
    display: flex;
    justify-content: left;
    min-width: 300px;
    max-width: 300px;
    padding: 3px 5%;

    label {
      font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
      font-size: ${() => getGlobalTheme().font.h2.fontSize};
      color: ${() => getGlobalTheme().colors.primary.principal};
      margin: 0 15px;
    }

    &:hover,
    label:hover {
      cursor: pointer;
    }
  }

  .__loading-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    z-index: 99;
    backdrop-filter: blur(1.5px);
    background-color: ${() => getGlobalTheme().colors.primary.contrast}64;
  }

  .__save-btn {
    position: absolute;
    display: none;
  }
`;
