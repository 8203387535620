import styled from 'styled-components';

export const PageActionsElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* padding: 5px; */

  .__page-action-button {
    text-transform: uppercase;
  }
`;
