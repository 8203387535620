import { DispatchAction } from '..';
import { Dispatch } from 'react';
import { DatabaseDocument } from '../../domain/models';

export type ActiveDetialsActionTypes =
  | 'SET_ACTIVE_DETAILS'
  | 'CLEAR_ACTIVE_DETAILS'
  | 'UPDATE_ACTIVE_DETAILS';

const clearActiveDetails = (): DispatchAction => {
  return {
    type: 'CLEAR_ACTIVE_DETAILS',
    payload: { activeDetails: { item: undefined } },
  };
};

const setActiveDetails = <T extends DatabaseDocument.Data>(
  item: T,
  dispatcher: Dispatch<DispatchAction>
): DispatchAction => {
  dispatcher(clearActiveDetails());
  return {
    type: 'SET_ACTIVE_DETAILS',
    payload: { activeDetails: { item } },
  };
};

const ActiveDetailsActions = { setActiveDetails, clearActiveDetails };

export default ActiveDetailsActions;
