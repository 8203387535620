import styled from 'styled-components';

export const NotApproveReasonModalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 50vw;
  max-width: 600px;

  .ui-textarea-container-downline {
    width: 80%;
    margin: 15px 0;

    textarea {
      min-width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    .ui-textarea-container-downline {
      width: calc(100% - 20px);
    }
  }
`;
