import {
  Button,
  ModalController,
  Spinners,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import useRest from '../../hooks/use-rest';
import PageTitle from '../page-title';
import { ExportFileModalElement } from './style';
import TimeCounter from './time-counter';

const Strings = {
  Title: 'Exportar Arquivo',
  Buttons: {
    DownloadFile: 'Baixar Arquivo',
    GenerateFile: 'Gerar Arquivo',
  },
  Messages: {
    FileReady: 'Seu arquivo está pronto para baixar',
    InvalidPath: 'O caminho do arquivo é inválido',
    RefreshFile: 'Não foi possível gerar o arquivo, por favor tente novamente',
    RequestFail: 'Houve falha para gerar o arquivo',
  },
};

enum Content {
  Loading,
  Download,
  Error,
}

interface ExportFileModalProps {
  controller?: ModalController;
  params?: any;
  endPoint?: string;
  filePath?: (id: string) => string;
}

const { useGet } = useRest();

export default function ExportFileModal({
  endPoint,
  params,
  filePath,
}: ExportFileModalProps): JSX.Element {
  const [data, get] = useGet({ loading: true });
  const errorToast = useToast(<span />, { color: 'danger' });

  const [content, setContent] = useState<Content>(Content.Loading);

  const downloadHandler = (): void => {
    if (data.payload) {
      const fileName = data.payload.data.file;

      if (!filePath) throw new Error('Invalid file path');
      window.open(filePath(fileName), '__blank');
    } else {
      errorToast.setContent(Strings.Messages.InvalidPath);
      setTimeout(() => errorToast.open());
    }
  };

  const generateFileHandler = (): void => {
    if (!endPoint) throw new Error('Invalid endpoint');
    get(endPoint, params);
  };

  useEffect(() => {
    if (data.loading) {
      setContent(Content.Loading);
    } else {
      if (data.payload) {
        setContent(Content.Download);
      } else if (data.error) {
        setContent(Content.Error);
        errorToast.setContent(Strings.Messages.RequestFail);
        setTimeout(() => errorToast.open());
      }
    }
  }, [data]);

  useEffect(() => {
    if (!endPoint) throw new Error('Invalid endpoint');
    get(endPoint, params);
  }, []);

  return (
    <ExportFileModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      {content === Content.Loading && (
        <div className="__form-container">
          <Spinners.circles width="300px" height="300px" />
          <TimeCounter />
        </div>
      )}

      {content === Content.Download && (
        <div className="__form-container">
          <h2>{Strings.Messages.FileReady}</h2>
          <Button
            text={Strings.Buttons.DownloadFile}
            onClick={downloadHandler}
            icon="download"
          />
        </div>
      )}

      {content === Content.Error && (
        <div className="__form-container">
          <h2>{Strings.Messages.RefreshFile}</h2>
          <Button
            text={Strings.Buttons.GenerateFile}
            onClick={generateFileHandler}
            icon="refresh"
          />
        </div>
      )}
    </ExportFileModalElement>
  );
}
