import { Button } from '@keymax-dev/smartepi-ui';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ViewActions from '../../redux/actions/view-actions';
import { RouterPaths } from '../../utils/constants';
import { HomePageElement } from './styles';

export default function HomePage(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const buttonClickHandler = (): void => {
    history.replace(RouterPaths.Login);
  };

  useEffect(() => {
    dispatch(ViewActions.sidebar.disable());
  }, []);

  return (
    <HomePageElement>
      <h1>Smart Epi</h1>
      <div className="__divider" />
      <Button text="Entrar" onClick={buttonClickHandler} />
    </HomePageElement>
  );
}
