import {
  Animations,
  Button,
  ModalController,
  ScrollableContainer,
  Select,
  Spinners,
  useForm,
  useToast,
  Validators,
} from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import PageTitle from '../../../components/page-title';
import useRest from '../../../hooks/use-rest';
import { ErrorResponse } from '../../../domain/models/api';
import {
  CustomerAccessType,
  CustomerAccessTypes,
} from '../../../domain/models';
import { CostCenter, Group, Role, Sector, User } from '../../../domain/models';
import { parseLocaleDate } from '../../../utils';
import { EndPoints } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import { AddEmployeeModalElement } from './style';

enum Content {
  Form,
  Loading,
}

interface AddEmployeeModal {
  controller?: ModalController;
}

const { usePost, useGet } = useRest();

const Strings = {
  Buttons: {
    Add: 'Adicionar',
  },
  Inputs: {
    Name: 'Nome',
    Email: 'E-mail',
    Identification: 'Identificação',
    Password: 'Senha',
    AdmissionDate: 'Data de Admissão',
    Group: 'Grupo',
    Sector: 'Setor',
    CostCenter1: 'Centro de Custo 1',
    CostCenter2: 'Centro de Custo 2',
    AccessType: 'Tipo de Acesso',
    Role: 'Função',
  },
  Messages: {
    RegisterLoading: 'Adicionando funcionário...',
    RegisterSuccess: 'Funcionário adicionado com successo!',
    UnknownError:
      'Ocorreu um erro inesperado. Por favor, verifique sua conexão com a internet.',
    InvalidForm: 'Os dados fornecidos são inválidos!',
    InvalidAccessType: 'Selecione um tipo de acesso!',
  },
  Title: 'Adicionar Funcionário',
};

const verifyEmailRequirement = (permission: User.Permission[]): boolean => {
  return (
    permission.includes(User.Permission.WEB_ADMIN) ||
    permission.includes(User.Permission.ADMIN) ||
    permission.includes(User.Permission.WEB_SUPPLY)
  );
};

export default function AddEmployeeModal({
  controller,
}: AddEmployeeModal): JSX.Element {
  const [postData, post] = usePost({ loading: false });
  const [getRoleData, getRole] = useGet({ loading: false });
  const [getGroupData, getGroup] = useGet({ loading: false });
  const [getSectorData, getSector] = useGet({ loading: false });
  const [getCostCenterData, getCostCenter] = useGet({ loading: false });
  const errorToast = useToast(<span />, { color: 'danger' });
  const successToast = useToast(
    <span>{Strings.Messages.RegisterSuccess}</span>,
    { color: 'success' }
  );
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [role, setRole] = useState<Role.Data>();
  const [group, setGroup] = useState<Group.Data>();
  const [sector, setSector] = useState<Sector.Data>();
  const [costCenter, setCostCenter] = useState<
    [CostCenter.Data | undefined, CostCenter.Data | undefined]
  >([undefined, undefined]);
  const [accessType, setAccessType] = useState<CustomerAccessType>();
  const [form, getErrors, getValues] = useForm(
    [
      {
        key: 'name',
        validators: [new Validators.Required()],
      },
      {
        key: 'identification',
        validators: [new Validators.Required()],
      },
      {
        key: 'email',
        validators: [new Validators.Required(), new Validators.Email()],
      },
      {
        key: 'password',
        validators: [new Validators.Required()],
      },
      {
        key: 'admissionDate',
        validators: [new Validators.Required()],
      },
    ],
    'addEmployee'
  );

  const contentAnimation = useAnimation();
  const [content, _setContent] = useState<Content>(Content.Form);
  const setContent = (value: Content): void => {
    contentAnimation
      .start(Animations.FadeOut)
      .then(() => Promise.resolve(_setContent(value)))
      .then(() => contentAnimation.start(Animations.FadeIn));
  };

  const requestSuccessHandler = (data: User.Data): void => {
    setTimeout(() => successToast.open());
    if (controller) controller.close(data);
  };

  const requestErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  const confirmHandler = (): void => {
    const errors = getErrors();
    if (!errors) {
      if (!accessType) {
        errorToast.setContent(Strings.Messages.InvalidAccessType);
        setTimeout(() => errorToast.open());
        return;
      }

      const postPayload = {
        ...getValues(),
        admissionDate: parseLocaleDate(
          `${getValues().admissionDate}`
        ).getTime(),
        roleId: role?._id,
        groupId: group?._id,
        sectorId: sector?._id,
        costCenterId: costCenter[1]
          ? costCenter[0]
            ? [costCenter[0]._id, costCenter[1]._id]
            : [costCenter[1]._id]
          : costCenter[0]
          ? [costCenter[0]._id]
          : [],
        permission: accessType?.permissions,
      };
      post(EndPoints.User(), postPayload);
    } else {
      errorToast.setContent(Strings.Messages.InvalidForm);
      setTimeout(() => errorToast.open());
    }
  };

  useEffect(() => {
    if (postData.loading) {
      setContent(Content.Loading);
    } else {
      setContent(Content.Form);
      if (postData.payload) requestSuccessHandler(postData.payload.data);
      else if (postData.error) requestErrorHandler(postData.error);
    }
  }, [postData]);

  return (
    <AddEmployeeModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      {content === Content.Form && (
        <div
          style={{
            height: '70vh',
            width: '100%',
            position: 'relative',
          }}
        >
          <ScrollableContainer
            flexDirection="column"
            ref={scrollableRef}
            style={{ paddingBottom: '60px', paddingTop: '30px' }}
          >
            <form.name placeholder={Strings.Inputs.Name} />
            <Select<CustomerAccessType>
              data={CustomerAccessTypes}
              dataKey="name"
              onSelect={(event, value): void => setAccessType(value)}
              placeholder={Strings.Inputs.AccessType}
              value={accessType}
            />
            {verifyEmailRequirement(accessType?.permissions || []) && (
              <form.email
                placeholder={Strings.Inputs.Email}
                iconRight="email"
              />
            )}
            <form.identification placeholder={Strings.Inputs.Identification} />
            <form.password
              placeholder={Strings.Inputs.Password}
              iconRight="key"
            />
            <form.admissionDate
              placeholder={Strings.Inputs.AdmissionDate}
              enableDatepicker
            />
            <Select<Role.Data>
              data={getRoleData.payload?.data.docs || []}
              dataKey="name"
              loading={getRoleData.loading}
              onSearch={(value): void =>
                getRole(EndPoints.Roles, {
                  search: 'name',
                  searchValue: value,
                })
              }
              onSelect={(event, value): void => setRole(value)}
              placeholder={Strings.Inputs.Role}
              value={role}
            />
            <Select<Group.Data>
              data={getGroupData.payload?.data.docs || []}
              dataKey="name"
              loading={getGroupData.loading}
              onSearch={(value): void =>
                getGroup(EndPoints.Groups, {
                  serach: 'name',
                  searchValue: value,
                })
              }
              onSelect={(event, value): void => setGroup(value)}
              placeholder={Strings.Inputs.Group}
              value={group}
            />
            <Select<Sector.Data>
              data={getSectorData.payload?.data.docs || []}
              dataKey="name"
              loading={getSectorData.loading}
              onSearch={(value): void =>
                getSector(EndPoints.Sectors, {
                  serach: 'name',
                  searchValue: value,
                })
              }
              onSelect={(event, value): void => setSector(value)}
              placeholder={Strings.Inputs.Sector}
              value={sector}
            />
            <Select<CostCenter.Data>
              data={getCostCenterData.payload?.data.docs || []}
              dataKey="name"
              loading={getCostCenterData.loading}
              onSearch={(value): void =>
                getCostCenter(EndPoints.CostCenters, {
                  serach: 'name',
                  searchValue: value,
                  filter: 'costCenter',
                  filterValue: costCenter[0] ? `${costCenter[0]._id}` : '',
                  contain: false,
                })
              }
              onSelect={(event, value): void =>
                setCostCenter([value, costCenter[1]])
              }
              placeholder={Strings.Inputs.CostCenter1}
              value={costCenter[0]}
            />
            <Select<CostCenter.Data>
              data={getCostCenterData.payload?.data.docs || []}
              dataKey="name"
              loading={getCostCenterData.loading}
              onSearch={(value): void =>
                getCostCenter(EndPoints.CostCenters, {
                  serach: 'name',
                  searchValue: value,
                  filter: 'costCenter',
                  filterValue: costCenter[1] ? `${costCenter[1]._id}` : '',
                  contain: false,
                })
              }
              onSelect={(event, value): void =>
                setCostCenter([costCenter[0], value])
              }
              placeholder={Strings.Inputs.CostCenter2}
              value={costCenter[1]}
            />
            <Button
              text={Strings.Buttons.Add}
              color="success"
              onClick={confirmHandler}
            />
          </ScrollableContainer>
        </div>
      )}
      {content === Content.Loading && (
        <motion.div
          className="__form-container"
          animate={contentAnimation}
          initial={{ opacity: 0 }}
        >
          <Spinners.circles width="300px" height="300px" />
          <span>{Strings.Messages.RegisterLoading}</span>
        </motion.div>
      )}
    </AddEmployeeModalElement>
  );
}
