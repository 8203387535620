import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const MachineDetailsModalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 800px;
  max-width: 100%;

  header {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    background-color: ${() => getGlobalTheme().colors.primary.principal};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 5% 0 5%;

    span {
      color: ${() => getGlobalTheme().colors.primary.contrast}32;
      margin-bottom: -10px;
    }
  }
`;

export const WarehouseContainerElement = styled.div`
  width: 100%;
  height: 40vh;

  table {
    height: 100%;
  }
`;

export const WarehouseTableProductCell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;

  h2 {
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.8);
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    margin: 0;
    max-width: 100%;
    max-height: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  span {
    font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.6);
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    color: ${() => getGlobalTheme().colors.primary.principal}64;
  }
`;

export const WarehouseTableChannelCell = styled.div`
  position: relative;
  .__blocked-badge {
    position: absolute;
    left: -30px;
    bottom: -30px;
    max-width: fit-content;
  }
`;
