import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReduxStore } from '../redux';
import ActiveDetailsActions from '../redux/actions/active-details-actions';
import { ActiveDetails, DatabaseDocument } from '../domain/models';
import useRest from './use-rest';

export interface ActiveDetailsStateProps {
  id: string;
  endpoint: (id: string) => string;
  cleanRouterPath: string;
}

export type ActiveDetailsState<T extends DatabaseDocument.Data> = [
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>
];

const { useGet } = useRest();

export function useActiveDetailsState<T extends DatabaseDocument.Data>({
  id,
  endpoint,
  cleanRouterPath,
}: ActiveDetailsStateProps): ActiveDetailsState<T> {
  const dispatch = useDispatch();
  const activeDetails = useSelector<ReduxStore<T>, ActiveDetails<T>>(
    (state) => state.activeDetails
  );
  const [getData, get] = useGet({ loading: false });
  const history = useHistory();
  const [state, setState] = useState<T | undefined>(activeDetails.item);

  useEffect(() => {
    if (getData.payload) {
      if (
        !activeDetails.item ||
        getData.payload.data._id === activeDetails.item._id
      ) {
        setState(getData.payload.data);
      }
    } else if (getData.error) {
      history.replace(cleanRouterPath);
    }
  }, [getData]);

  useEffect(() => {
    if (!activeDetails.item) {
      get(endpoint(id));
    } else {
      setState(activeDetails.item);
    }
  }, [activeDetails]);

  useEffect(() => {
    if (state?._id) {
      dispatch(ActiveDetailsActions.setActiveDetails(state, dispatch));
    }
  }, [state]);

  useEffect(() => {
    return () => {
      dispatch(ActiveDetailsActions.clearActiveDetails());
    };
  }, []);

  return [state, setState];
}

export default useActiveDetailsState;
