import {
  Button,
  ModalController,
  TextArea,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useState } from 'react';
import PageTitle from '../../../../components/page-title';
import { NotApproveReasonModalElement } from './style';

interface NotApproveReasonModalProps {
  controller?: ModalController;
}

const Strings = {
  Buttons: {
    Confirm: 'Confirmar',
  },
  Inputs: {
    DescribeReason: 'Descreva o motivo da recusa',
  },
  Messages: {
    RegisterSuccess: 'Setor cadastrado com sucesso!',
    EmptyReason: 'Por favor, preencha o motivo da recusa.',
  },
  Title: 'Motivo',
};

export default function NotApproveReasonModal({
  controller,
}: NotApproveReasonModalProps): JSX.Element {
  const [reason, setReason] = useState<string>('');
  const errorToast = useToast(<span>{Strings.Messages.EmptyReason}</span>, {
    color: 'danger',
  });

  const confirmClickHandler = (): void => {
    if (reason.length === 0) return errorToast.open();

    if (controller) controller.close({ reason });
  };

  return (
    <NotApproveReasonModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      <TextArea
        placeholder={Strings.Inputs.DescribeReason}
        icon="pencil"
        value={reason}
        onChange={(event) => setReason(event.currentTarget.value)}
      />

      <Button
        text={Strings.Buttons.Confirm}
        color="success"
        onClick={confirmClickHandler}
      />
    </NotApproveReasonModalElement>
  );
}
