import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const LicensePageElement = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  .__search-container {
    .ui-input-container-outline {
      margin: 8px 15px 0 15px;
    }
    min-height: 58px;
  }

  .__grid-card-container {
    flex: 1;
    width: 100%;
    position: relative;
    .__grid-card {
      justify-content: space-around;
      align-items: flex-start;
      flex-wrap: wrap;

      h2 {
        -webkit-line-clamp: 2;
      }

      .__description {
        font-size: calc(${() => getGlobalTheme().font.h2.fontSize} / 2);
        -webkit-line-clamp: 3;
      }

      .__card {
        margin: 20px;

        &:active {
          transform: scale(0.95);
        }
      }
    }
  }

  .ui-modal-btn-close {
    path {
      fill: ${() => getGlobalTheme().colors.primary.contrast};
    }
  }
`;

export const LicensesMessageContainerElement = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;
