/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Animations,
  Button,
  Spinners,
  useForm,
  useToast,
  Validators,
} from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../components/page-title';
import useRest from '../../../hooks/use-rest';
import { Customer, ErrorResponse } from '../../../domain/models';
import { EndPoints } from '../../../utils/constants';
import { getErrorMessage } from '../../../utils/error-messages';
import { AddCustomerModalElement } from './style';

const Strings = {
  Buttons: {
    Add: 'Adicionar',
  },
  Inputs: {
    Name: 'Nome da Empresa',
    CNPJ: 'CNPJ',
    LicensesNumber: 'Número de Licensas',
    UserName: 'Usuário',
    UserPassword: 'Senha',
    UserEmail: 'E-mail',
    UserIdentification: 'Identificação',
  },
  Messages: {
    RegisterLoading: 'Adicionando cliente...',
    RegisterSuccess: 'Cliente adicionado com successo!',
    InvalidForm: 'Os dados fornecidos são inválidos!',
  },
  Title: 'Adicionar Cliente',
};

enum Content {
  Form,
  Loading,
}

const { usePost } = useRest();

interface AddCustomerModalProps {
  controller?: any;
}

type FormPrototype = {
  fancyName: string;
  cnpj: string;
  licenses: number;
  userName: string;
  userEmail: string;
  userPassword: string;
  userIdentification: string;
};

export default function AddCustomerModal({
  controller,
}: AddCustomerModalProps): JSX.Element {
  const [data, post] = usePost({ loading: false });
  const errorToast = useToast(<span />, { color: 'danger' });
  const successToast = useToast(
    <span>{Strings.Messages.RegisterSuccess}</span>,
    { color: 'success' }
  );

  const [form, getErrors, getValues] = useForm<FormPrototype>(
    [
      {
        key: 'fancyName',
        validators: [new Validators.MinLength(4)],
      },
      {
        key: 'cnpj',
        validators: [
          new Validators.MinLength(14),
          new Validators.MaxLength(14),
        ],
      },
      {
        key: 'licenses',
        validators: [new Validators.Required()],
      },
      {
        key: 'userName',
        validators: [new Validators.Required()],
      },
      {
        key: 'userIdentification',
        validators: [new Validators.Required()],
      },
      {
        key: 'userEmail',
        validators: [new Validators.Email()],
      },
      {
        key: 'userPassword',
        validators: [new Validators.MinLength(6)],
      },
    ],
    'addCustomer'
  );

  const contentAnimation = useAnimation();
  const [content, _setContent] = useState<Content>(Content.Form);
  const setContent = (value: Content): void => {
    contentAnimation
      .start(Animations.FadeOut)
      .then(() => Promise.resolve(_setContent(value)))
      .then(() => contentAnimation.start(Animations.FadeIn));
  };

  const requestSuccessHandler = (data: Customer.Data): void => {
    setTimeout(() => successToast.open());
    if (controller) controller.close(data);
  };

  const requestErrorHandler = (error: ErrorResponse): void => {
    errorToast.setContent(getErrorMessage(error));
    setTimeout(() => errorToast.open());
  };

  const confirmHandler = (): void => {
    if (!getErrors()) {
      const values = getValues();
      post(EndPoints.Customer(), {
        ...values,
        user: {
          name: values.userName,
          identification: values.userIdentification,
          email: values.userEmail,
          password: values.userPassword,
        },
        licenses: parseInt(`${values.licenses}`),
      });
    } else {
      errorToast.setContent(Strings.Messages.InvalidForm);
      setTimeout(() => errorToast.open());
    }
  };

  useEffect(() => {
    if (data.loading) {
      setContent(Content.Loading);
    } else {
      setContent(Content.Form);
      if (data.payload) requestSuccessHandler(data.payload.data);
      else if (data.error) requestErrorHandler(data.error);
    }
  }, [data]);

  return (
    <AddCustomerModalElement>
      <PageTitle>{Strings.Title}</PageTitle>

      {content === Content.Form && (
        <motion.div className="__form-container">
          <div className="__form-container">
            <form.fancyName placeholder={Strings.Inputs.Name} />
            <form.cnpj placeholder={Strings.Inputs.CNPJ} />
            <form.licenses
              placeholder={Strings.Inputs.LicensesNumber}
              type="number"
            />
            <form.userName placeholder={Strings.Inputs.UserName} />
            <form.userIdentification
              placeholder={Strings.Inputs.UserIdentification}
            />
            <form.userEmail placeholder={Strings.Inputs.UserEmail} />
            <form.userPassword placeholder={Strings.Inputs.UserPassword} />
          </div>
          <Button
            text={Strings.Buttons.Add}
            color="success"
            onClick={confirmHandler}
          />
        </motion.div>
      )}
      {content === Content.Loading && (
        <motion.div
          className="__form-container"
          animate={contentAnimation}
          initial={{ opacity: 0 }}
        >
          <Spinners.circles width="300px" height="300px" />
          <span>{Strings.Messages.RegisterLoading}</span>
        </motion.div>
      )}
    </AddCustomerModalElement>
  );
}
