import {
  Button,
  Icon,
  Input,
  ScrollableContainer,
  Select,
  Table,
  TableColumn,
  useModal,
  useToast,
} from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import ExportFileModal from '../../components/export-file-modal';
import PageTitle from '../../components/page-title';
import ReportCard, { ReportFilterProps } from '../../components/report-card';
import useRest, { RequestParamType } from '../../hooks/use-rest';
import { Withdrawal } from '../../domain/models';
import { parseLocaleDate } from '../../utils';
import { EndPoints, FilePaths } from '../../utils/constants';
import ColumnSelectionModal from './column-selection';
import { BaseReportTableCell, ReportsPageElement } from './style';

enum ReportGenerationStep {
  Type,
  Approval,
  Date,
  AddFilters,
  Generate,
  Table,
  Status,
}

const DAY_TIME = 86399000;
const MAX_DOCUMENTS_PAGE = 25;

const Strings = {
  PageTitle: 'Relatórios',
  Messages: {
    SelectReportType: 'Selecione o tipo de relatório',
    SelectReportApproval: 'Selecione o tipo de liberação',
    SelectReportDate: 'Selecione as datas de início e fim',
    GenerateReport:
      'Agora você pode gerar o relatório ou adicionar alguns filtros',
    SelectFilter: 'Selecione o tipo de filtro que deseja aplicar',
    ReportGenerateFail:
      'Não foi possível gerar o relatório, por favor tente novamente.',
    SelectState: 'Selecione o estado do processo',
    PageElementsCounter: (index: number, total: number) =>
      `Exibindo itens de ${
        index * MAX_DOCUMENTS_PAGE + 1 > total
          ? total
          : index * MAX_DOCUMENTS_PAGE + 1
      } até ${
        (index + 1) * MAX_DOCUMENTS_PAGE > total
          ? total
          : (index + 1) * MAX_DOCUMENTS_PAGE
      } de um total de ${total} itens.`,
  },
  Inputs: {
    StartDate: 'Date de Início',
    EndDate: 'Data de Fim',
  },
  Buttons: {
    ConfirmDate: 'Confirmar Data',
    GenerateReport: 'Gerar Relatório',
    AddFilter: 'Adicionar Filtro',
    Export: 'Exportar',
    NewReport: 'Novo',
    ShowColumns: 'Exibir',
  },
  FilterTypeName: {
    Employee: 'Funcionário',
    Sector: 'Setor',
    Product: 'Produto',
    CostCenter: 'Centro de Custo',
    Machine: 'Máquina',
    License: 'Licença',
    AllTypes: 'Todos Tipos',
    AllApproval: 'Todas Liberações',
    Approval: 'Aprovados Supervisor',
    Awaiting: 'Aguardando',
    Processing: 'Processando',
    Done: 'Concluído',
    Cancel: 'Cancelado',
    Fail: 'Falha',
    AllStatus: 'Todos Estados',
  },
  Table: {
    ColumnTitles: {
      DateTime: 'Data/Hora',
      Quantity: ['Qtd.', 'Quantidade'],
      Product: 'Produto',
      UserReceive: 'Recebedor',
      UserRelease: 'Entregador',
      License: 'Licença',
      Machine: 'Máquina',
      OutStatus: 'Status de Saída',
      Approval: 'Aprovação',
      Access: 'Tipo de Acesso',
    },
    Columns: {
      DateTime: {
        Request: 'Pedido',
        Delivery: 'Entrega',
      },
      User: {
        Sector: 'Setor',
        Role: 'Função',
        CostCenter1: 'Centro de Custo 1',
        CostCenter2: 'Centro de Custo 2',
        NotReceive: 'Não recebido',
        NotRelease: 'Sem entregador',
      },
      License: {
        NotLicense: 'Sem licença',
        Name: 'Nome',
      },
      Machine: {
        Name: 'Nome',
        NotMachine: 'Sem máquina',
        Channel: 'Canal',
      },
      OutInfo: {
        NotOutInfo: 'Sem informações de saída',
        Mode: 'Modo',
        Status: 'Estado',
        Reason: 'Motivo',
        WithdrawalMode: ['Delivery', 'Retirada'],
        WithdrawalStatus: [
          'Aguardando',
          'Processando',
          'Entregue',
          'Cancelado',
          'Falha',
        ],
      },
      Approval: {
        NotApproval: 'Sem necessidade de aprovação',
        ApprovalStatus: ['Pendente', 'Aprovado', 'Não Aprovado'],
        Status: 'Estado',
        Reason: 'Motivo',
        ApprovalUser: 'Aprovado por',
      },
      Product: {
        Size: 'Tamanho',
        Code: 'Código',
        Product: 'Produto',
      },
      Access: {
        NotAccess: 'Sem dados de acesso',
        Initial: 'Inicial',
        Confirmation: 'Confirmação',
        Delivery: 'Ao Receber',
        AccessType: ['Senha', 'Biometria'],
      },
    },
  },
};

export const ReportColumnsDefault = {
  dateTime: {
    name: Strings.Table.ColumnTitles.DateTime,
    value: true,
  },
  quantity: {
    name: Strings.Table.ColumnTitles.Quantity[1],
    value: true,
  },
  product: {
    name: Strings.Table.ColumnTitles.Product,
    value: true,
  },
  outInfo: {
    name: Strings.Table.ColumnTitles.OutStatus,
    value: true,
  },
  userReceive: {
    name: Strings.Table.ColumnTitles.UserReceive,
    value: true,
  },
  userRelease: {
    name: Strings.Table.ColumnTitles.UserRelease,
    value: true,
  },
  machine: {
    name: Strings.Table.ColumnTitles.Machine,
    value: true,
  },
  license: {
    name: Strings.Table.ColumnTitles.License,
    value: true,
  },
  approval: {
    name: Strings.Table.ColumnTitles.Approval,
    value: true,
  },
  access: {
    name: Strings.Table.ColumnTitles.Access,
    value: true,
  },
};

const getReportColumns = (): typeof ReportColumnsDefault => {
  const fromStorage = localStorage.getItem('reportColumns');
  if (fromStorage) {
    const parsed = JSON.parse(fromStorage);
    for (const key in ReportColumnsDefault) {
      if (!parsed[key]) return ReportColumnsDefault;
    }
    return parsed as typeof ReportColumnsDefault;
  } else {
    localStorage.setItem('reportColumns', JSON.stringify(ReportColumnsDefault));
    return ReportColumnsDefault;
  }
};

type ReportType = ReportFilterProps<'0' | '1' | '2'>;
type ReportApproval = ReportFilterProps<boolean>;
type ReportStatus = ReportFilterProps<'0' | '1' | '2' | '3' | '4' | '5'>;
type ReportDate = ReportFilterProps<[string, string]>;
type ReportFilter = ReportFilterProps<string>;

type FilterTypeNames = 'user' | 'machine' | 'license';
type FilterTypeObject = {
  endpoint: string;
  reportFilter: ReportFilter;
};

type ReportBuild = {
  type: ReportType;
  approval: ReportApproval;
  date: ReportDate;
  status: ReportStatus;
};

const filterTypes: { [key in FilterTypeNames]: FilterTypeObject } = {
  // costCenter: {
  //     endpoint: EndPoints.CostCenters,
  //     reportFilter: { text: [Strings.FilterTypeName.CostCenter], icon: 'cashUsd' }
  // },
  // product: {
  //     endpoint: EndPoints.Products,
  //     reportFilter: { text: [Strings.FilterTypeName.Product], icon: 'hammerWrench' }
  // },
  // sector: {
  //     endpoint: EndPoints.Sectors,
  //     reportFilter: { text: [Strings.FilterTypeName.Sector], icon: 'accountGroup' }
  // },
  user: {
    endpoint: EndPoints.Users,
    reportFilter: {
      text: [Strings.FilterTypeName.Employee],
      icon: 'worker',
    },
  },
  machine: {
    endpoint: EndPoints.Machines,
    reportFilter: {
      text: [Strings.FilterTypeName.Machine],
      icon: 'desktopTower',
    },
  },
  license: {
    endpoint: EndPoints.Licenses,
    reportFilter: {
      text: [Strings.FilterTypeName.License],
      icon: 'desktop',
    },
  },
};

const getAvailableFilters = (
  type: '0' | '1' | '2'
): { [key: string]: FilterTypeObject } => {
  switch (type) {
    case '1':
      return {
        user: filterTypes.user,
        machine: filterTypes.machine,
      };
    case '2':
      return {
        user: filterTypes.user,
        license: filterTypes.license,
      };
    default:
      return {
        user: filterTypes.user,
      };
  }
};

const reportTypes: ReportType[] = [
  {
    type: 'type',
    value: '1',
    text: [Strings.FilterTypeName.Machine],
    icon: 'desktopTower',
  },
  {
    type: 'type',
    value: '2',
    text: [Strings.FilterTypeName.License],
    icon: 'desktop',
  },
  { text: [Strings.FilterTypeName.AllTypes], icon: 'listCheck' },
];

const reportStates: ReportStatus[] = [
  {
    type: 'status',
    value: '1',
    text: [Strings.Table.Columns.OutInfo.WithdrawalStatus[0]],
    icon: 'eyeOff',
  },
  {
    type: 'status',
    value: '2',
    text: [Strings.Table.Columns.OutInfo.WithdrawalStatus[1]],
    icon: 'clock',
  },
  {
    type: 'status',
    value: '3',
    text: [Strings.Table.Columns.OutInfo.WithdrawalStatus[2]],
    icon: 'check',
  },
  {
    type: 'status',
    value: '4',
    text: [Strings.Table.Columns.OutInfo.WithdrawalStatus[3]],
    icon: 'cancel',
  },
  {
    type: 'status',
    value: '5',
    text: [Strings.Table.Columns.OutInfo.WithdrawalStatus[4]],
    icon: 'alert',
  },
  { text: [Strings.FilterTypeName.AllStatus], icon: 'listCheck' },
];

const getAvailableStatus = (type: '0' | '1' | '2'): ReportStatus[] => {
  switch (type) {
    case '1':
      return [reportStates[2], reportStates[4], reportStates[5]];
    case '2':
      return [
        reportStates[0],
        reportStates[1],
        reportStates[2],
        reportStates[3],
        reportStates[5],
      ];
    default:
      return [reportStates[5]];
  }
};

const reportApproval: ReportApproval[] = [
  {
    type: 'approval',
    value: true,
    text: [Strings.FilterTypeName.Approval],
    icon: 'accountTie',
  },
  {
    type: 'approval',
    value: false,
    text: [Strings.FilterTypeName.AllApproval],
    icon: 'listCheck',
  },
];

const { useGet } = useRest();

export default function ReportsPage(): JSX.Element {
  // Hooks
  const [report, setReport] = useState<Partial<ReportBuild>>({});
  const [filters, setFilters] = useState<ReportFilter[]>([]);
  const [message, setMessage] = useState<string>(
    Strings.Messages.SelectReportType
  );
  const [step, setStep] = useState<ReportGenerationStep>(
    ReportGenerationStep.Date
  );
  const [addingFilterType, setAddingFilterType] = useState<FilterTypeNames>();
  const [startDate, setStartDate] = useState<string>(
    new Date().toLocaleDateString('pt-br')
  );
  const [endDate, setEndDate] = useState<string>(
    new Date().toLocaleDateString('pt-br')
  );
  const [getData, get] = useGet({ loading: false });
  const errorToast = useToast(<span />, { color: 'danger' });
  const [reportColumns, setReportColumns] = useState<
    typeof ReportColumnsDefault
  >(getReportColumns());
  const columnSelectionModal = useModal(
    <ColumnSelectionModal columns={reportColumns} />
  );
  const [bookmark, setBookmark] = useState<string[]>(['']);
  const [bookmarkIndex, setBookmarkIndex] = useState<number>(0);
  const exportModal = useModal(<ExportFileModal />);

  const [selectedItem, setSelectedItem] = useState<{
    _id: string;
    name: string;
  }>();
  const [getSelectData, getSelect] = useGet({ loading: false });

  // Functions
  const createRequestParams = (): { [key: string]: RequestParamType } => {
    const requestParams: { [key: string]: RequestParamType } = {};

    if (report.approval && report.approval.value) {
      requestParams.approval = report.approval.value;
    }

    if (report.date && report.date.value) {
      requestParams.startDate = parseLocaleDate(report.date.value[1]).getTime();
      requestParams.endDate =
        parseLocaleDate(report.date.value[0]).getTime() + DAY_TIME;
    }

    if (report.status && report.status.value) {
      requestParams.status = report.status.value;
    }

    if (report.type && report.type.value) {
      requestParams.type = report.type.value;
    }

    if (filters.length > 0) {
      requestParams.filter = filters.map((filter) => ({
        filter: filter.type || '',
        filterValue: filter.value || '',
      }));
    }

    return requestParams;
  };

  // Handlers
  const selectTypeHandler = (type: ReportType): void => {
    setReport({ ...report, type });
    setStep(ReportGenerationStep.Status);
  };

  const selectApprovalHandler = (approval: ReportApproval): void => {
    setReport({ ...report, approval });
    setStep(ReportGenerationStep.Generate);
  };

  const selectDateHandler = (): void => {
    setReport({
      ...report,
      date: {
        type: 'date',
        text: [endDate, startDate],
        icon: 'calendar',
        value: [endDate, startDate],
      },
    });
    setStep(ReportGenerationStep.Type);
  };

  const selectStatusHandler = (state: ReportStatus): void => {
    setReport({ ...report, status: state });
    setStep(ReportGenerationStep.Approval);
  };

  const createFilterHandler = (): void => {
    if (!addingFilterType || !selectedItem) return;
    filters.push({
      type: addingFilterType,
      value: selectedItem._id,
      ...filterTypes[addingFilterType].reportFilter,
      text: [selectedItem.name],
    });
    setFilters([...filters]);
    setStep(ReportGenerationStep.Generate);
    setAddingFilterType(undefined);
  };

  const deleteFilterHandler = (filter: ReportFilter): void => {
    setFilters(filters.filter((ele) => ele !== filter));
  };

  const generateReportHandler = (): void => {
    setBookmark(['']);
    setBookmarkIndex(0);

    get(EndPoints.Reports, createRequestParams());
    setStep(ReportGenerationStep.Table);
  };

  const exportHandler = (): void => {
    exportModal.injectProps({
      endPoint: EndPoints.ReportsExport,
      filePath: FilePaths.Report,
      params: createRequestParams(),
    });
    exportModal.open();
  };

  const changeBookmarkHandler = (pd: -1 | 1): void => {
    const newBookmarkIndex = bookmarkIndex + pd;
    if (newBookmarkIndex === 0) return generateReportHandler();
    setBookmarkIndex(newBookmarkIndex);

    const requestParams = createRequestParams();
    requestParams.bookmark = bookmark[newBookmarkIndex];
    get(EndPoints.Reports, requestParams);
  };

  const columnSelectionHandler = (): void => {
    columnSelectionModal.onClose((reason) => {
      if (typeof reason === 'object') {
        setReportColumns({ ...reason } as typeof ReportColumnsDefault);
      }
    });
    columnSelectionModal.injectProps({ columns: reportColumns });
    columnSelectionModal.open();
  };

  const restartReport = (): void => {
    setStep(ReportGenerationStep.Generate);
  };

  // Page Elements

  // Effects
  useEffect(() => {
    switch (step) {
      case ReportGenerationStep.Type:
        setMessage(Strings.Messages.SelectReportType);
        break;
      case ReportGenerationStep.Approval:
        setMessage(Strings.Messages.SelectReportApproval);
        break;
      case ReportGenerationStep.Date:
        setMessage(Strings.Messages.SelectReportDate);
        break;
      case ReportGenerationStep.Generate:
        setMessage(Strings.Messages.GenerateReport);
        break;
      case ReportGenerationStep.AddFilters:
        setMessage(Strings.Messages.SelectFilter);
        break;
      case ReportGenerationStep.Status:
        setMessage(Strings.Messages.SelectState);
        break;
    }
  }, [step]);

  useEffect(() => {
    if (addingFilterType) {
      getSelect(filterTypes[addingFilterType].endpoint);
    } else {
      setSelectedItem(undefined);
    }
  }, [addingFilterType]);

  useEffect(() => {
    if (getData.error) {
      console.error(getData.error);
      errorToast.setContent(Strings.Messages.ReportGenerateFail);
      setTimeout(() => errorToast.open());
      restartReport();
    } else if (getData.payload) {
      if (bookmarkIndex + 1 >= bookmark.length) {
        setBookmark((prev) => [...prev, getData.payload?.data.bookmark || '']);
      }
    }
  }, [getData]);

  return (
    <ReportsPageElement>
      <PageTitle>{Strings.PageTitle}</PageTitle>
      {step !== ReportGenerationStep.Table && (
        <div className="__scrollable-container">
          <ScrollableContainer flexDirection="column">
            <div className="__report-filter-row">
              {report.date && (
                <ReportCard
                  {...report.date}
                  onSelect={() => setStep(ReportGenerationStep.Date)}
                />
              )}
              {report.type && (
                <ReportCard
                  {...report.type}
                  onSelect={() => setStep(ReportGenerationStep.Type)}
                />
              )}
              {report.status && (
                <ReportCard
                  {...report.status}
                  onSelect={() => setStep(ReportGenerationStep.Status)}
                />
              )}
              {report.approval && (
                <ReportCard
                  {...report.approval}
                  onSelect={() => setStep(ReportGenerationStep.Approval)}
                />
              )}
              {filters.map((filter, index) => (
                <ReportCard
                  key={index}
                  {...filter}
                  enableDelete
                  onDelete={() => deleteFilterHandler(filter)}
                />
              ))}
            </div>
            <h2>{message}</h2>
            {step === ReportGenerationStep.Type && (
              <div className="__report-filter-row">
                {reportTypes.map((type, index) => (
                  <ReportCard
                    key={index}
                    {...type}
                    active
                    onSelect={() => selectTypeHandler(type)}
                  />
                ))}
              </div>
            )}
            {step === ReportGenerationStep.Status && (
              <div className="__report-filter-row">
                {getAvailableStatus(report.type?.value || '0').map(
                  (state, index) => (
                    <ReportCard
                      key={index}
                      {...state}
                      active
                      onSelect={() => selectStatusHandler(state)}
                    />
                  )
                )}
              </div>
            )}
            {step === ReportGenerationStep.Approval && (
              <div className="__report-filter-row">
                {reportApproval.map((approval, index) => (
                  <ReportCard
                    key={index}
                    {...approval}
                    active
                    onSelect={() => selectApprovalHandler(approval)}
                  />
                ))}
              </div>
            )}
            {step === ReportGenerationStep.Date && (
              <div className="__report-filter-column">
                <Input
                  placeholder={Strings.Inputs.StartDate}
                  enableDatepicker
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                />
                <Input
                  placeholder={Strings.Inputs.EndDate}
                  enableDatepicker
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                />

                {startDate && endDate && (
                  <Button
                    text={Strings.Buttons.ConfirmDate}
                    onClick={selectDateHandler}
                  />
                )}
              </div>
            )}
            {step === ReportGenerationStep.Generate && (
              <div className="__report-filter-column">
                {filters.length < 1 && (
                  <ReportCard
                    text={[Strings.Buttons.AddFilter]}
                    icon="filterPlus"
                    active
                    onSelect={() => setStep(ReportGenerationStep.AddFilters)}
                  />
                )}
                <Button
                  text={Strings.Buttons.GenerateReport}
                  color="success"
                  icon="check"
                  onClick={generateReportHandler}
                />
              </div>
            )}
            {step === ReportGenerationStep.AddFilters && !addingFilterType && (
              <div className="__report-filter-column">
                <ReportCard
                  text={[Strings.Buttons.AddFilter]}
                  icon="filterPlus"
                />
                <Icon name="chevronDown" />
                <div className="__report-filter-row">
                  {Object.entries(
                    getAvailableFilters(report.type?.value || '0')
                  ).map(([filterName, filterObject]) => (
                    <ReportCard
                      key={filterName}
                      {...filterObject.reportFilter}
                      active
                      value={filterName}
                      onSelect={() =>
                        setAddingFilterType(filterName as FilterTypeNames)
                      }
                    />
                  ))}
                </div>
              </div>
            )}
            {step === ReportGenerationStep.AddFilters && addingFilterType && (
              <div className="__report-filter-column">
                <ReportCard
                  text={[Strings.Buttons.AddFilter]}
                  icon="filterPlus"
                  onSelect={() => setAddingFilterType(undefined)}
                />
                <Icon name="chevronDown" />
                <ReportCard {...filterTypes[addingFilterType].reportFilter} />
                <Icon name="chevronDown" />
                <Select<{ _id: string; name: string }>
                  data={getSelectData.payload?.data.docs || []}
                  dataKey="name"
                  loading={getSelectData.loading}
                  onSearch={(value) =>
                    getSelect(filterTypes[addingFilterType].endpoint, {
                      search: 'name',
                      searchValue: value,
                    })
                  }
                  onSelect={(event, value) => setSelectedItem(value)}
                  placeholder={
                    filterTypes[addingFilterType].reportFilter.text[0]
                  }
                  value={selectedItem}
                />
                {selectedItem && (
                  <Button
                    text={Strings.Buttons.AddFilter}
                    color="success"
                    icon="check"
                    onClick={createFilterHandler}
                  />
                )}
              </div>
            )}
          </ScrollableContainer>
        </div>
      )}
      {step === ReportGenerationStep.Table && (
        <div className="__table-container">
          <div className="__report-filter-row">
            {report.date && <ReportCard {...report.date} />}
            {report.type && <ReportCard {...report.type} />}
            {report.status && <ReportCard {...report.status} />}
            {report.approval && <ReportCard {...report.approval} />}
            {filters.map((filter, index) => (
              <ReportCard key={index} {...filter} />
            ))}
          </div>
          <Table
            data={getData.payload?.data.docs || []}
            loading={getData.loading}
          >
            {/* Date Time */}
            {reportColumns.dateTime.value && (
              <TableColumn
                key="dateTime"
                name={Strings.Table.ColumnTitles.DateTime}
                maxwidth="200px"
                minwidth="200px"
              >
                {({ dateTime }: Withdrawal.Data) => (
                  <BaseReportTableCell>
                    <div className="ui-grid-vertical" style={{ width: '100%' }}>
                      {dateTime.request && (
                        <div className="ui-grid-vertical">
                          <span className="__main-text __bold">
                            {new Date(dateTime.request).toLocaleString('pt-br')}
                          </span>
                          <span className="__sub-text">
                            {Strings.Table.Columns.DateTime.Request}
                          </span>
                        </div>
                      )}
                      <div className="ui-grid-vertical">
                        <span className="__main-text __bold">
                          {dateTime.delivery
                            ? new Date(dateTime.delivery).toLocaleString(
                                'pt-br'
                              )
                            : '-'}
                        </span>
                        <span className="__sub-text">
                          {Strings.Table.Columns.DateTime.Delivery}
                        </span>
                      </div>
                    </div>
                  </BaseReportTableCell>
                )}
              </TableColumn>
            )}

            {/* Product Data */}
            {reportColumns.quantity.value && (
              <TableColumn
                key="quantity"
                name={Strings.Table.ColumnTitles.Quantity[0]}
                maxwidth="100px"
                minwidth="100px"
              >
                {({ quantity }: Withdrawal.Data) => (
                  <BaseReportTableCell>{quantity}</BaseReportTableCell>
                )}
              </TableColumn>
            )}
            {reportColumns.product.value && (
              <TableColumn
                key="product"
                name={Strings.Table.ColumnTitles.Product}
                maxwidth="300px"
                minwidth="300px"
              >
                {({ product }: Withdrawal.Data) => (
                  <BaseReportTableCell>
                    <div className="ui-grid-vertical" style={{ width: '100%' }}>
                      <div className="ui-grid-vertical">
                        <span className="__main-text __bold">
                          {product?.name || '-'}
                        </span>
                        <span className="__sub-text">
                          {Strings.Table.Columns.Product.Product}
                        </span>
                      </div>
                      <div className="ui-grid-horizontal">
                        <div className="ui-grid-vertical">
                          <span className="__main-text">
                            {product?.size?.size || '-'}
                          </span>
                          <span className="__sub-text">
                            {Strings.Table.Columns.Product.Size}
                          </span>
                        </div>
                        <div className="ui-grid-vertical">
                          <span className="__main-text">
                            {product?.size?.code || '-'}
                          </span>
                          <span className="__sub-text">
                            {Strings.Table.Columns.Product.Code}
                          </span>
                        </div>
                      </div>
                    </div>
                  </BaseReportTableCell>
                )}
              </TableColumn>
            )}

            {/* Out Status */}
            {reportColumns.outInfo.value && (
              <TableColumn
                key="outStatus"
                name={Strings.Table.ColumnTitles.OutStatus}
                maxwidth="200px"
                minwidth="200px"
              >
                {({ outInfo }: Withdrawal.Data) => (
                  <BaseReportTableCell>
                    {outInfo && (
                      <div
                        className="ui-grid-vertical"
                        style={{ width: '100%' }}
                      >
                        <div className="ui-grid-horizontal">
                          <div className="ui-grid-vertical">
                            <span className="__main-text __bold">
                              {
                                Strings.Table.Columns.OutInfo.WithdrawalStatus[
                                  outInfo.status - 1
                                ]
                              }
                            </span>
                            <span className="__sub-text">
                              {Strings.Table.Columns.OutInfo.Status}
                            </span>
                          </div>
                          <div className="ui-grid-vertical">
                            <span className="__main-text __bold">
                              {
                                Strings.Table.Columns.OutInfo.WithdrawalMode[
                                  outInfo.mode - 1
                                ]
                              }
                            </span>
                            <span className="__sub-text">
                              {Strings.Table.Columns.OutInfo.Mode}
                            </span>
                          </div>
                        </div>
                        {outInfo.reason && (
                          <div className="ui-grid-vertical">
                            <span className="__main-text">
                              {outInfo.reason}
                            </span>
                            <span className="__sub-text">
                              {Strings.Table.Columns.OutInfo.Reason}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {!outInfo && (
                      <span className="__sub-text">
                        {Strings.Table.Columns.Machine.NotMachine}
                      </span>
                    )}
                  </BaseReportTableCell>
                )}
              </TableColumn>
            )}

            {/* Receiver Data */}
            {reportColumns.userReceive.value && (
              <TableColumn
                key="userReceive"
                name={Strings.Table.ColumnTitles.UserReceive}
                maxwidth="375px"
                minwidth="375px"
              >
                {({ userReceive }: Withdrawal.Data) => (
                  <BaseReportTableCell>
                    {userReceive && (
                      <div
                        className="ui-grid-vertical"
                        style={{ width: '100%' }}
                      >
                        <div className="ui-grid-vertical">
                          <span className="__main-text __bold">
                            {userReceive.user?.name || '-'}
                          </span>
                          <span className="__sub-text">
                            {userReceive.user?.identification}
                          </span>
                        </div>
                        <div className="ui-grid-horizontal">
                          {userReceive.sector && (
                            <div className="ui-grid-vertical">
                              <span className="__main-text">
                                {userReceive.sector.name}
                              </span>
                              <span className="__sub-text">
                                {Strings.Table.Columns.User.Sector}
                              </span>
                            </div>
                          )}
                          {userReceive.role && (
                            <div className="ui-grid-vertical">
                              <span className="__main-text">
                                {userReceive.role.name}
                              </span>
                              <span className="__sub-text">
                                {Strings.Table.Columns.User.Role}
                              </span>
                            </div>
                          )}
                          {userReceive.costCenter &&
                            userReceive.costCenter.length > 0 &&
                            userReceive.costCenter[0] && (
                              <div className="ui-grid-vertical">
                                <span className="__main-text">
                                  {userReceive.costCenter[0].name || '-'}
                                </span>
                                <span className="__sub-text">
                                  {Strings.Table.Columns.User.CostCenter1}
                                </span>
                              </div>
                            )}
                          {userReceive.costCenter &&
                            userReceive.costCenter.length > 1 &&
                            userReceive.costCenter[1] && (
                              <div className="ui-grid-vertical">
                                <span className="__main-text">
                                  {userReceive.costCenter[1].name || '-'}
                                </span>
                                <span className="__sub-text">
                                  {Strings.Table.Columns.User.CostCenter2}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                    {!userReceive && (
                      <span className="__sub-text">
                        {Strings.Table.Columns.User.NotReceive}
                      </span>
                    )}
                  </BaseReportTableCell>
                )}
              </TableColumn>
            )}

            {/* Releaser Data */}
            {reportColumns.userRelease.value && (
              <TableColumn
                key="userRelease"
                name={Strings.Table.ColumnTitles.UserRelease}
                maxwidth="300px"
                minwidth="300px"
              >
                {({ userRelease }: Withdrawal.Data) => (
                  <BaseReportTableCell>
                    {userRelease && (
                      <div
                        className="ui-grid-vertical"
                        style={{ width: '100%' }}
                      >
                        <div className="ui-grid-vertical">
                          <span className="__main-text __bold">
                            {userRelease?.name || '-'}
                          </span>
                          <span className="__sub-text">
                            {userRelease?.identification}
                          </span>
                        </div>
                      </div>
                    )}
                    {!userRelease && (
                      <span className="__sub-text">
                        {Strings.Table.Columns.User.NotRelease}
                      </span>
                    )}
                  </BaseReportTableCell>
                )}
              </TableColumn>
            )}

            {/* Machine */}
            {reportColumns.machine.value && (
              <TableColumn
                key="machine"
                name={Strings.Table.ColumnTitles.Machine}
                maxwidth="200px"
                minwidth="200px"
              >
                {({ machine, outInfo: { channel } }: Withdrawal.Data) => (
                  <BaseReportTableCell>
                    {machine && (
                      <div
                        className="ui-grid-vertical"
                        style={{ width: '100%' }}
                      >
                        <div className="ui-grid-vertical">
                          <span className="__main-text __bold">
                            {machine.name}
                          </span>
                          <span className="__sub-text">
                            {Strings.Table.Columns.Machine.Name}
                          </span>
                        </div>
                        <div className="ui-grid-vertical">
                          <span className="__main-text">{channel || '-'}</span>
                          <span className="__sub-text">
                            {Strings.Table.Columns.Machine.Channel}
                          </span>
                        </div>
                      </div>
                    )}
                    {!machine && (
                      <span className="__sub-text">
                        {Strings.Table.Columns.Machine.NotMachine}
                      </span>
                    )}
                  </BaseReportTableCell>
                )}
              </TableColumn>
            )}

            {/* Licence */}
            {reportColumns.license.value && (
              <TableColumn
                key="license"
                name={Strings.Table.ColumnTitles.License}
                maxwidth="200px"
                minwidth="200px"
              >
                {({ license }: Withdrawal.Data) => (
                  <BaseReportTableCell>
                    {license && (
                      <div
                        className="ui-grid-vertical"
                        style={{ width: '100%' }}
                      >
                        <span className="__main-text __bold">
                          {license.name}
                        </span>
                        <span className="__sub-text">
                          {Strings.Table.Columns.License.Name}
                        </span>
                      </div>
                    )}
                    {!license && (
                      <span className="__sub-text">
                        {Strings.Table.Columns.License.NotLicense}
                      </span>
                    )}
                  </BaseReportTableCell>
                )}
              </TableColumn>
            )}

            {/* Approval */}
            {reportColumns.approval.value && (
              <TableColumn
                key="approval"
                name={Strings.Table.ColumnTitles.Approval}
                maxwidth="320px"
                minwidth="320px"
              >
                {({ approval }: Withdrawal.Data) => (
                  <BaseReportTableCell>
                    {approval && (
                      <div
                        className="ui-grid-vertical"
                        style={{ width: '100%' }}
                      >
                        <div className="ui-grid-vertical">
                          <span className="__main-text __bold">
                            {approval.user?.name || '-'}
                          </span>
                          <span className="__sub-text">
                            {Strings.Table.Columns.Approval.ApprovalUser}
                          </span>
                        </div>
                        <div className="ui-grid-horizontal">
                          <div className="ui-grid-vertical">
                            <span className="__main-text">
                              {approval.reason || '-'}
                            </span>
                            <span className="__sub-text">
                              {Strings.Table.Columns.Approval.Reason}
                            </span>
                          </div>
                          <div className="ui-grid-vertical">
                            <span className="__main-text">
                              {
                                Strings.Table.Columns.Approval.ApprovalStatus[
                                  approval.status - 1
                                ]
                              }
                            </span>
                            <span className="__sub-text">
                              {Strings.Table.Columns.Approval.Status}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {!approval && (
                      <span className="__sub-text">
                        {Strings.Table.Columns.Approval.NotApproval}
                      </span>
                    )}
                  </BaseReportTableCell>
                )}
              </TableColumn>
            )}

            {/* Access */}
            {reportColumns.access.value && (
              <TableColumn
                key="access"
                name={Strings.Table.ColumnTitles.Access}
                maxwidth="200px"
                minwidth="200px"
              >
                {({ access }: Withdrawal.Data) => (
                  <BaseReportTableCell>
                    {access && (
                      <div
                        className="ui-grid-vertical"
                        style={{ width: '100%' }}
                      >
                        <div className="ui-grid-vertical">
                          <span className="__main-text __bold">
                            {
                              Strings.Table.Columns.Access.AccessType[
                                access.initial - 1
                              ]
                            }
                          </span>
                          <span className="__sub-text">
                            {Strings.Table.Columns.Access.Initial}
                          </span>
                        </div>
                        <div className="ui-grid-vertical">
                          <span className="__main-text __bold">
                            {
                              Strings.Table.Columns.Access.AccessType[
                                access.confirmation - 1
                              ]
                            }
                          </span>
                          <span className="__sub-text">
                            {Strings.Table.Columns.Access.Confirmation}
                          </span>
                        </div>
                        {access.delivery && (
                          <div className="ui-grid-vertical">
                            <span className="__main-text __bold">
                              {
                                Strings.Table.Columns.Access.AccessType[
                                  access.delivery - 1
                                ]
                              }
                            </span>
                            <span className="__sub-text">
                              {Strings.Table.Columns.Access.Delivery}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {!access && (
                      <span className="__sub-text">
                        {Strings.Table.Columns.Access.NotAccess}
                      </span>
                    )}
                  </BaseReportTableCell>
                )}
              </TableColumn>
            )}
          </Table>
          {!getData.loading && (
            <footer className="__table-footer">
              <div className="ui-grid-vertical">
                <div className="ui-grid-horizontal">
                  <div className="__division-container">
                    {getData.payload?.data.total > 0 && (
                      <Button
                        text={Strings.Buttons.Export}
                        buttonType="icon"
                        icon="download"
                        onClick={exportHandler}
                        iconSize="30px"
                      />
                    )}
                    <Button
                      text={Strings.Buttons.NewReport}
                      buttonType="icon"
                      icon="file"
                      iconSize="30px"
                      onClick={restartReport}
                    />
                    {/* <Button buttonType="icon" icon="printer" iconSize="30px" /> */}
                    <Button
                      text={Strings.Buttons.ShowColumns}
                      buttonType="icon"
                      icon="listSelect"
                      onClick={columnSelectionHandler}
                      iconSize="30px"
                    />
                  </div>
                  <div className="__division-container __pager-container">
                    {bookmarkIndex > 0 && (
                      <Button
                        buttonType="icon"
                        icon="chevronLeft"
                        iconSize="30px"
                        onClick={() => changeBookmarkHandler(-1)}
                      />
                    )}
                    <span>{bookmarkIndex + 1}</span>
                    {getData.payload?.data.docs.length >=
                      MAX_DOCUMENTS_PAGE && (
                      <Button
                        buttonType="icon"
                        icon="chevronRight"
                        iconSize="30px"
                        onClick={() => changeBookmarkHandler(1)}
                      />
                    )}
                  </div>
                </div>
                <span className="__bold">
                  {Strings.Messages.PageElementsCounter(
                    bookmarkIndex,
                    getData.payload?.data.total
                  )}
                </span>
              </div>
            </footer>
          )}
        </div>
      )}
    </ReportsPageElement>
  );
}
