import { Spinners } from '@keymax-dev/smartepi-ui';
import React from 'react';
import { LoadingPageElement } from './style';

export default function LoadingPage(): JSX.Element {
  return (
    <LoadingPageElement>
      <Spinners.circles width="300px" height="300px" />
      <h1>Carregando...</h1>
    </LoadingPageElement>
  );
}
