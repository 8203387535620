import { Icon } from '@keymax-dev/smartepi-ui';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStore } from '../../redux';
import ViewActions from '../../redux/actions/view-actions';
import { View } from '../../domain/models';
import SidebarContent from './sidebar-content';
import { SidebarElement } from './style';

export const SIDEBAR_WIDTH = 250;
export const CLOSE_MIN_WIDTH = 1000;

let SidebarResizeListener: () => void;

export default function Sidebar(): JSX.Element {
  const containerController = useAnimation();
  const toggleController = useAnimation();
  const toggleContainerController = useAnimation();
  const containerRef = useRef<HTMLDivElement>(null);

  const view = useSelector<ReduxStore, Partial<View>>((state) => state.view);
  const dispatch = useDispatch();

  const clickListener = (event: MouseEvent): void => {
    if (containerRef.current && view.sidebar?.opened) {
      if (
        !containerRef.current.contains(event.target as HTMLElement) ||
        containerRef.current === event.target
      ) {
        if (view.sidebar?.opened && window.innerWidth < CLOSE_MIN_WIDTH)
          dispatch(ViewActions.sidebar.close());
      }
    }
  };

  const removeListeners = (): void => {
    window.removeEventListener('click', clickListener);
    window.removeEventListener('resize', SidebarResizeListener);
  };

  const addListeners = (): void => {
    removeListeners();
    window.addEventListener('click', clickListener);
    window.addEventListener('resize', SidebarResizeListener);
  };

  const open = (): void => {
    containerController.start({
      left: 0,
      transition: { duration: 0.2, ease: 'easeInOut' },
    });
    toggleController.start({
      rotate: 180,
    });
  };

  const close = (): void => {
    containerController.start({
      left: -SIDEBAR_WIDTH,
      transition: { duration: 0.2, ease: 'easeInOut' },
    });
    toggleController.start({
      rotate: 0,
    });
  };

  const toggleHandler = (): void => {
    if (view.sidebar?.opened) {
      dispatch(ViewActions.sidebar.close());
    } else {
      dispatch(ViewActions.sidebar.open());
    }
  };

  useEffect(() => {
    if (view.sidebar?.enabled) {
      if (view.sidebar?.opened) {
        open();
      } else {
        close();
      }
    }
  }, [view.sidebar?.opened]);

  useEffect(() => {
    if (view.sidebar?.enabled) {
      SidebarResizeListener = (): void => {
        if (window.innerWidth > CLOSE_MIN_WIDTH) {
          if (!view.sidebar?.opened) dispatch(ViewActions.sidebar.open());

          toggleContainerController.start({
            opacity: 0,
            transition: { duration: 0.1, ease: 'easeInOut' },
          });
        } else {
          toggleContainerController.start({
            opacity: 1,
            transition: { duration: 0.1, ease: 'easeInOut' },
          });
        }
      };
      addListeners();
      SidebarResizeListener();
    }
    return () => removeListeners();
  }, [view.sidebar?.enabled]);

  return (
    <SidebarElement
      animate={containerController}
      initial={{ left: -SIDEBAR_WIDTH }}
      ref={containerRef}
    >
      <SidebarContent />
      <motion.div
        className="__sidebar-toggle"
        onClick={toggleHandler}
        animate={toggleContainerController}
      >
        <Icon name="chevronRight" invert animate={toggleController} />
      </motion.div>
    </SidebarElement>
  );
}
