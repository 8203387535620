import { parseToken } from '.';
import { User } from '../domain/models';
import { RouterPaths } from './constants';

export const getPrimaryUserRoute = (): string => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) return RouterPaths.Login;

  const token = parseToken(accessToken);

  if (token.exp * 1000 < Date.now()) {
    localStorage.removeItem('accessToken');
    return RouterPaths.Login;
  }

  if (token.permission.includes(User.Permission.ADMIN)) {
    return RouterPaths.Customers;
  } else if (token.permission.includes(User.Permission.WEB_ADMIN)) {
    return RouterPaths.Products;
  } else if (token.permission.includes(User.Permission.WEB_SUPPLY)) {
    return RouterPaths.Approval;
  }

  return RouterPaths.Login;
};
