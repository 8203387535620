import { getGlobalTheme } from '@keymax-dev/smartepi-ui';
import styled from 'styled-components';

export const DownloadSoftwareModalElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  width: 50vw;
  max-width: 600px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .ui-grid-vertical {
    margin: 30px 10px;
  }

  h2 {
    font-size: ${() => getGlobalTheme().font.h2.fontSize};
    font-weight: ${() => getGlobalTheme().font.h2.fontWeight};
    padding: 5vh 5vw;
    min-width: 100%;
    max-width: 100%;
    margin: 0;

    @media screen and (max-width: 600px) {
      font-size: calc(${() => getGlobalTheme().font.h2.fontSize} * 0.8);
    }
  }
`;
