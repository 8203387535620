import {
  Checkbox,
  Table,
  TableColumn,
  useModal,
} from '@keymax-dev/smartepi-ui';
import React from 'react';
import ListPageSearchBar from '../../components/list-page-search-bar';
import PageActions from '../../components/page-actions';
import PageTitle from '../../components/page-title';
import useListPage from '../../hooks/use-list-page';
import { EndPoints, RouterPaths } from '../../utils/constants';
import AddCustomerModal from './add-customer';
import { CustomerDataCellElement, CustomerPageElement } from './style';
import { Addon, Customer } from '../../domain/models';

const Strings = {
  Buttons: {
    AddCustomer: 'Adicionar Cliente',
  },
  Inputs: {
    Search: 'Buscar cliente...',
  },
  Title: 'Clientes',
  Table: {
    ColumnTitles: {
      Customer: 'Cliente',
    },
    CustomerCall: {
      CNPJ: 'CNPJ',
      Licenses: 'Licenças',
    },
  },
  Messages: {
    Caution: 'Atenção!',
    DeleteConfirmation: 'Você deseja mesmo deletar os clientes selecionados?',
  },
};

export default function CustomerPage(): JSX.Element {
  // Hooks
  const listPage = useListPage<Customer.Data & { addons: Addon.Addon[] }>(
    EndPoints.Customers,
    RouterPaths.Customer
  );
  const addCustomerModal = useModal(<AddCustomerModal />);

  // Handlers
  const addCustomerHandler = (): void => {
    addCustomerModal.onClose(listPage.addItemModalCloseHandler);
    addCustomerModal.open();
  };

  // Component Elements
  const pageActions = [
    {
      text: Strings.Buttons.AddCustomer,
      icon: 'accountPlus',
      onClick: addCustomerHandler,
    },
  ];
  const tableConfig = {
    rowEvents: listPage.rowEvents,
    rowProps: listPage.rowProps,
    onScroll: listPage.tableScrollHandler,
  };

  return (
    <CustomerPageElement>
      <PageTitle>{Strings.Title}</PageTitle>
      <PageActions actions={pageActions} />
      <ListPageSearchBar
        listPage={listPage}
        strings={{
          placeholder: Strings.Inputs.Search,
          deleteTitle: Strings.Messages.Caution,
          deleteMessage: Strings.Messages.DeleteConfirmation,
        }}
        searchKey="fancyName"
      />
      <Table
        data={listPage.restList.list}
        config={tableConfig}
        loading={listPage.restList.loading}
      >
        {listPage.selectable && (
          <TableColumn name="Seleção" key="selected" flex={0} minwidth="120px">
            {(item: Customer.Data): JSX.Element => (
              <Checkbox
                value={listPage.selectableList.keys.has(`${item._id}`)}
              />
            )}
          </TableColumn>
        )}
        <TableColumn
          name="Cliente"
          key="customerData"
          flex={1}
          minwidth="320px"
        >
          {(item: Customer.Data): JSX.Element => (
            <CustomerDataCellElement className="ui-grid-horizontal">
              {/* <ImageAvatar /> */}
              <div className="ui-grid-vertical">
                <span>
                  {listPage.activeDetails.item?._id === item._id ? (
                    <b>{item.fancyName}</b>
                  ) : (
                    item.fancyName
                  )}
                </span>
                <div className="ui-grid-horizontal  __name-subdata">
                  <span>
                    <b>{Strings.Table.CustomerCall.CNPJ}</b> {item.cnpj}
                  </span>
                  <span>
                    <b>{Strings.Table.CustomerCall.Licenses}</b> {item.licenses}
                  </span>
                </div>
              </div>
            </CustomerDataCellElement>
          )}
        </TableColumn>
      </Table>
    </CustomerPageElement>
  );
}
