import { Button, Checkbox, ModalController } from '@keymax-dev/smartepi-ui';
import React, { useEffect, useState } from 'react';
import { ReportColumnsDefault } from '../';
import PageTitle from '../../../components/page-title';
import { ColumnSelectionModalElement } from './style';

interface ColumnSelectionModalProps {
  controller?: ModalController;
  columns: typeof ReportColumnsDefault;
}

const Strings = {
  PageTitle: 'Exibir Colunas',
  Buttons: {
    Save: 'Salvar',
  },
};

export default function ColumnSelectionModal({
  controller,
  columns,
}: ColumnSelectionModalProps): JSX.Element {
  const [currentColumns, setCurrentColumns] = useState<
    typeof ReportColumnsDefault
  >(columns);

  const rowClickHandler = (key: keyof typeof columns): void => {
    setCurrentColumns({
      ...currentColumns,
      [key]: {
        ...currentColumns[key],
        value: !currentColumns[key].value,
      },
    });
  };

  const confirmClickHandler = (): void => {
    localStorage.setItem('reportColumns', JSON.stringify(currentColumns));
    if (controller) controller.close(currentColumns);
  };

  useEffect(() => {
    setCurrentColumns(columns);
  }, [columns]);

  return (
    <ColumnSelectionModalElement>
      <PageTitle>{Strings.PageTitle}</PageTitle>
      <div className="__checkbox-column">
        {Object.entries(currentColumns).map(([key, obj]) => (
          <div
            key={key}
            className="__checkbox-row"
            onClick={() => rowClickHandler(key as keyof typeof columns)}
          >
            <Checkbox value={obj.value} size="25px" />
            <h2>{obj.name}</h2>
          </div>
        ))}
      </div>
      <Button
        text={Strings.Buttons.Save}
        color="success"
        onClick={confirmClickHandler}
      />
    </ColumnSelectionModalElement>
  );
}
